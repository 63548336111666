.container {
  margin-top: 56px;
  padding-bottom: 100px;
  background-color: #292F36;
  color: #fff;
}

.UserArea {
  z-index: 999 !important;
}

.primaryButton {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef00 !important;
  background-color: #c7ef00 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.primaryButton:hover, .primaryButton:active, .primaryButton.active {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.secondaryButton {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.secondaryButton:hover, .secondaryButton:active, .secondaryButton.active {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.primaryLink {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid transparent !important;
  background-color: transparent !important; 
  color: #c7ef00 !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.primaryLink:hover, .primaryLink:active, .primaryLink.active {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.bgNone {
  background-color: transparent !important;
  border: 0 !important;
}

.brightness05 {
  filter: brightness(0.45);
  -webkit-box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
  border-radius: 25px !important;
}

.dFlex {
  display: flex !important;
}

.toRight {
  text-align: right;
}

.toBottomCenter {
  display: inherit !important;
  max-width: 101px;
  bottom: 0;
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: center;
}

.maxminHeight215 {
  border-radius: 25px !important;
  min-height: 215px;
  max-height: 215px;
}

::-moz-selection { 
  background: #c7ef00; 
  color: #292F36;
  opacity: 1;
}

::selection { 
  background: #c7ef00; 
  color: #292F36;
  opacity: 1;
}

.progressImage {
  display: flex;
  margin: 60px auto;
  text-align: center;
}

.containerRegister {
  text-align: center;
}

.formField {
  text-align: left;
}

.formInput {
  background-color: transparent !important;
  color: white !important;
  border: 1px solid rgba(255, 255, 255, 0.4) !important;
  transition: 500ms all;
}

.formInput:focus {
  -webkit-box-shadow: 0 0 1px 2px #c7ef0080 !important;
  box-shadow: 0 0 1px 2px #c7ef0080 !important;
}

.card {
  padding: 3rem 2rem;
  margin-bottom: 30px;
  background-color: transparent !important;
  color: white !important;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  border-radius: 25px;
}

.activeButton, .activeButton:focus {
  padding: 10px !important;
  margin: 5px;
  min-width: 100%;
  border: 1px solid #c7ef00 !important;
  -webkit-box-shadow: 0 0 5px 0px #c7ef0080 !important;
  box-shadow: 0 0 5px 0px #c7ef0080 !important;
  background-color: rgba(255, 255, 255, 0.2) !important;
  color: white !important;
  text-decoration: none !important;
}

.notActiveButton {
  color: white !important;
  margin: 5px;
  padding: 10px !important;
  min-width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.notActiveButton:focus {
  -webkit-box-shadow: 0 0 5px 0px #c7ef0020 !important;
  box-shadow: 0 0 5px 0px #c7ef0020 !important; 
}

.notActiveButton:hover {
  text-decoration: none !important;
  background-color: rgba(255, 255, 255, 0.2);
}

.secondaryLink {
  cursor: pointer;
  color: white !important;
  text-decoration: underline !important;
  text-decoration-color: #c7ef00 !important;
  font-weight: bold !important;
}

.secondaryLink:hover, .secondaryLink:active, .secondaryLink.active {
  color: #c7ef00 !important;
  text-decoration: underline;
  text-decoration-color: #c7ef00 !important;
}