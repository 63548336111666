@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;700;800;900&display=swap);
/* Style the list */
ul.Breadcrumb_customBreadcrumb__1twEU {
  padding: 10px 16px;
  list-style: none !important;
  width: 100%;
  border-radius: 10px;
  background-color: rgba(255,255,255,0.1);
  display: inline-flex;
}

/* Display list items side by side */
ul.Breadcrumb_customBreadcrumb__1twEU li {
  display: inline;
  font-size: small;
}

/* Add a slash symbol (/) before/behind each list item */
ul.Breadcrumb_customBreadcrumb__1twEU li+li:before {
  padding: 8px;
  color: white;
  content: "→\00a0";
}

/* Add a color to all links inside the list */
ul.Breadcrumb_customBreadcrumb__1twEU li a {
  color: #b2d600;
  text-decoration: none;
}

/* Add a color on mouse-over */
ul.Breadcrumb_customBreadcrumb__1twEU li a:hover {
  color: #b2d600;
  text-decoration: underline;
}
.Exams_container__2EUTP {
  margin-top: 56px;
  padding-bottom: 100px;
  background-color: #292F36;
  color: #fff;
}

.Exams_UserArea__3Otdn {
  z-index: 999 !important;
}

.Exams_primaryButton__1en6p {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef00 !important;
  background-color: #c7ef00 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.Exams_primaryButton__1en6p:hover, .Exams_primaryButton__1en6p:active, .Exams_primaryButton__1en6p.Exams_active__3RzjK {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Exams_secondaryButton__2SIRu {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.Exams_secondaryButton__2SIRu:hover, .Exams_secondaryButton__2SIRu:active, .Exams_secondaryButton__2SIRu.Exams_active__3RzjK {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Exams_primaryLink__2g0Qb {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid transparent !important;
  background-color: transparent !important; 
  color: #c7ef00 !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.Exams_primaryLink__2g0Qb:hover, .Exams_primaryLink__2g0Qb:active, .Exams_primaryLink__2g0Qb.Exams_active__3RzjK {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Exams_bgNone__354rU {
  background-color: transparent !important;
  border: 0 !important;
}

.Exams_brightness05__1afDS {
  -webkit-filter: brightness(0.45);
          filter: brightness(0.45);
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
  border-radius: 25px !important;
}

.Exams_dFlex__1DUmr {
  display: flex !important;
}

.Exams_toRight__3zeCv {
  text-align: right;
}

.Exams_toBottomCenter__3lxBY {
  display: inherit !important;
  max-width: 101px;
  bottom: 0;
  /* display: flex !important; */
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: center;
  /* max-width: 110px; */
}

.Exams_maxminHeight215__NW9BX {
  border-radius: 25px !important;
  min-height: 215px;
  max-height: 215px;
}

@media (min-width: 768px) {
  .Exams_spaceUp__2W9-4 {
    padding-top: 200px;
  }

  .Exams_spaceUpTwo__2RBoY {
    padding-top: 30px;
  }

  .Exams_spaceThree__1VQ-i {
    padding: 100px 0;
  }

  .Exams_pb50__2l4KT {
    padding-bottom: 50px;
  }
}

@media (max-width: 768px) {
  .Exams_spaceUp__2W9-4 {
    padding-top: 50px;
    margin: 0 25px;
  }

  .Exams_spaceUpTwo__2RBoY {
    padding-top: 0;
  }
}

/**
 * Special
 */

::selection { 
  background: #c7ef00; 
  color: #292F36;
  opacity: 1;
}

.Exams_examCard__32NJQ {
  transition: all 200ms ease-in-out;
  border-radius: 1.5em;
}

.Exams_examCard__32NJQ img {
  -webkit-filter: brightness(0.5);
          filter: brightness(0.5);
}

.Exams_examCard__32NJQ:hover {
  box-shadow: 0 0 10px black;
  border-radius: 1.5em;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.Exams_examCard__32NJQ .Exams_buttonIcon__2rVVH {
  transition: all 200ms ease-in-out;
}

.Exams_examCard__32NJQ:hover .Exams_buttonIcon__2rVVH {
  color: #c7ef00;
}
.Exam_container__3JeSp {
  margin-top: 56px;
  padding-bottom: 100px;
  background-color: #292F36;
  color: #fff;
}

.Exam_UserArea__182u5 {
  z-index: 999 !important;
}

.Exam_primaryButton__3IShd {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef00 !important;
  background-color: #c7ef00 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.Exam_primaryButton__3IShd:hover, .Exam_primaryButton__3IShd:active, .Exam_primaryButton__3IShd.Exam_active__2_80A {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Exam_secondaryButton__2cqHA {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.Exam_secondaryButton__2cqHA:hover, .Exam_secondaryButton__2cqHA:active, .Exam_secondaryButton__2cqHA.Exam_active__2_80A {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}


.Exam_primaryLink__3ydER {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid transparent !important;
  background-color: transparent !important; 
  color: #c7ef00 !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.Exam_primaryLink__3ydER:hover, .Exam_primaryLink__3ydER:active, .Exam_primaryLink__3ydER.Exam_active__2_80A {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Exam_roundButton__QaTYn {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50% !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  padding: 10px 15px !important;
  flex: 1 1;
  margin-left: auto;
  margin-right: 0;
  max-width: 46px;
}

.Exam_roundButton__QaTYn:hover, .Exam_roundButton__QaTYn:active, .Exam_roundButton__QaTYn.Exam_active__2_80A {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  color: #292F36 !important;
}

.Exam_secondaryLink__3BNBE {
  cursor: pointer;
  color: #292F36;
  text-decoration: underline;
  -webkit-text-decoration-color: #c7ef00;
          text-decoration-color: #c7ef00;
}

.Exam_secondaryLink__3BNBE:hover, .Exam_secondaryLink__3BNBE:active, .Exam_secondaryLink__3BNBE.Exam_active__2_80A {
  color: #c7ef00;
  text-decoration: underline;
  -webkit-text-decoration-color: #c7ef00;
          text-decoration-color: #c7ef00;
}

.Exam_bgWhite___bOSL {
  background-color: white;
  border: 0 !important;
  color: #292F36;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
}

.Exam_borderRadius25__3qd3W {
  border-radius: 25px !important;
}

.Exam_toBottomCenter__1WwrQ {
  margin: 0 auto !important;
}

.Exam_toCenter__MSBWj {
  text-align: center;
}

.Exam_toRight__TSACl {
  text-align: right;
}

.Exam_questionWidget__3paLj {
  line-height: 45px;
  display: flex;
  background-color: white;
  padding: 15px 25px;
  margin: 10px 0;
  border-radius: 25px !important;
  border: 0 !important;
  color: #292F36;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
}

.Exam_infoCard__30zmm a:not(.Exam_textDecorationNone__1Vev7)  {
  transition: all 200ms;
  font-weight: bold;
  color: #292F36 !important;
  text-decoration: underline !important;
  -webkit-text-decoration-color: #c7ef00 !important;
          text-decoration-color: #c7ef00 !important;
}


.Exam_infoCard__30zmm a:hover:not(.Exam_textDecorationNone__1Vev7) {
  color: #c7ef00 !important;
  text-decoration: underline !important;
  -webkit-text-decoration-color: #c7ef00 !important;
          text-decoration-color: #c7ef00 !important;
}

.AreaWidget_container__3BDlq {
  margin-top: 56px;
  padding-bottom: 100px;
  background-color: #292F36;
  color: #fff;
}

.AreaWidget_UserArea__3AFpX {
  z-index: 999 !important;
}

.AreaWidget_primaryButton__1B4OD {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef00 !important;
  background-color: #c7ef00 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.AreaWidget_primaryButton__1B4OD:hover, .AreaWidget_primaryButton__1B4OD:active, .AreaWidget_primaryButton__1B4OD.AreaWidget_active__3H3DG {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.AreaWidget_primaryButtonBlocked__3cLZn {
  transition: 0.2s;
  cursor: not-allowed !important;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef0000 !important;
  background-color: #c7ef0060 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.AreaWidget_primaryButtonBlocked__3cLZn:hover, .AreaWidget_primaryButtonBlocked__3cLZn:active, .AreaWidget_primaryButtonBlocked__3cLZn.AreaWidget_active__3H3DG {
  background-color: #c7ef0060 !important;
  border: 1px solid #c7ef0000 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.AreaWidget_secondaryButton__3kGrX {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.AreaWidget_secondaryButton__3kGrX:hover, .AreaWidget_secondaryButton__3kGrX:active, .AreaWidget_secondaryButton__3kGrX.AreaWidget_active__3H3DG {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}


.AreaWidget_primaryLink__QeRTV {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid transparent !important;
  background-color: transparent !important; 
  color: #c7ef00 !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.AreaWidget_primaryLink__QeRTV:hover, .AreaWidget_primaryLink__QeRTV:active, .AreaWidget_primaryLink__QeRTV.AreaWidget_active__3H3DG {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.AreaWidget_toRight__2zTUp {
  margin-left: auto;
  margin-right: 0;
  display: flex !important;
  text-align: right;
}

*:hover a.AreaWidget_clickableCard__1hdXF {
  color: #000 !important;
  color: initial !important;
  text-decoration: none !important;
}

.AreaWidget_clickableCard__1hdXF {
  transition: all 200ms ease-in-out;
}

.AreaWidget_clickableCard__1hdXF:hover {
  box-shadow: 0 0 10px black;
  border-radius: 1.5em;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.AreaWidget_clickableCard__1hdXF .AreaWidget_buttonIcon__2aowR {
  transition: all 200ms ease-in-out;
}

.AreaWidget_clickableCard__1hdXF:hover .AreaWidget_buttonIcon__2aowR {
  color: #c7ef00;
}

.AreaWidget_toRight__2zTUp button {
  display: flex;
  margin: 0 0 0 auto;
}

.AreaWidget_roundButton__20_z- {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 30px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  padding: 15px 30px !important;
  flex: 1 1;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 15px;
  text-decoration: underline !important;
}

.AreaWidget_roundButton__20_z-:hover, .AreaWidget_roundButton__20_z-:active, .AreaWidget_roundButton__20_z-.AreaWidget_active__3H3DG {
  background-color: #39424d !important;
  border: 1px solid #39424d !important;
}

.AreaWidget_outlineSecondaryButton__3nUK2 {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 30px !important;
  border: 1px solid #292F36 !important;
  background-color: transparent !important;
  color: #292F36 !important;
  padding: 15px 30px !important;
  text-decoration: underline !important;
  flex: 1 1;
  margin-left: auto;
  margin-right: 0;
}

.AreaWidget_outlineSecondaryButton__3nUK2:hover, .AreaWidget_outlineSecondaryButton__3nUK2:active, .AreaWidget_outlineSecondaryButton__3nUK2.AreaWidget_active__3H3DG {
  background-color: #292F36 !important;
  border: 1px solid #292F36 !important;
  color: white !important;
}

.AreaWidget_secondaryLink__3jQ4s {
  cursor: pointer;
  color: #292F36 !important;
  background: none;
  border: none;
  text-decoration: underline;
  padding: 0 !important;
  -webkit-text-decoration-color: #c7ef00;
          text-decoration-color: #c7ef00;
  transition: all 300ms;
}

.AreaWidget_secondaryLink__3jQ4s:hover, .AreaWidget_secondaryLink__3jQ4s:active, .AreaWidget_secondaryLink__3jQ4s.AreaWidget_active__3H3DG {
  color: #c7ef00 !important;
  text-decoration: underline !important;
  -webkit-text-decoration-color: #c7ef00 !important;
          text-decoration-color: #c7ef00 !important;
}

.AreaWidget_bgWhite__eXFiX {
  background-color: white;
  color: #292F36;
}

.AreaWidget_borderRadius25__Cz3Cl {
  border-radius: 25px !important;
}

.AreaWidget_toBottomCenter__1US3q {
  margin: 0 auto !important;
}

.AreaWidget_toCenter__XkaKF {
  text-align: center;
}

.AreaWidget_questionWidget__2OjpO {
  line-height: 45px;
  display: flex;
  background-color: white;
  padding: 15px 25px;
  margin: 10px 0;
  border-radius: 25px !important;
  border: 0 !important;
  color: #292F36;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
}

.AreaWidget_option__18FsO {
  background-color: white;
  border: solid 2px transparent !important;
  color: #292F36;
  box-shadow: 0 0 5px 1px transparent;
  border-radius: 25px !important;
}

.AreaWidget_optionActive__3cdlA {
  transition: 500ms;
  background-color: white;
  border: solid 2px #c7ef00 !important;
  color: #292F36;
  box-shadow: 0 0 5px 1px #c7ef007e;
  border-radius: 25px !important;
}

.AreaWidget_optionContent__27Bh1 {
  padding-left: 0 !important;
}

.AreaWidget_optionContent__27Bh1 p {
  margin-bottom: 0;
}

.AreaWidget_optionPosition__z8p-g {
  height: 25px;
  width: 25px;
  padding: 0 6px;
  border: solid 1px #292f366b;
  border-radius: 50%;
}

.AreaWidget_optionPositionActive__CKa1e {
  transition: 500ms;
  height: 25px;
  width: 25px;
  padding: 0 6px;
  border: solid 1px #292f36;
  border-radius: 50%;
  color: white !important;
  background: #292f36 !important;
}

.AreaWidget_minWidth__2z7wa {
  max-width: -webkit-min-content !important;
  max-width: min-content !important;
  flex-grow: inherit !important;
}

.AreaWidget_centered__sTfcj {
  margin-left: auto;
  margin-right: auto;
  display: flex !important;
}

.AreaWidget_modalRigthAnswer__3Rgwb .AreaWidget_modalContent__3yNNG {
  background-color: #c7ef00 !important;
}


.AreaWidget_areaCard__3QCvG {
  transition: all 200ms ease-in-out;
  border-radius: 1.5em;
  color: white;
  background: #3CA55C;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #B5AC49, #3CA55C); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.AreaWidget_areaCard__3QCvG:hover {
  text-decoration: none !important;
  color: white;
  box-shadow: 0 0 10px black;
  border-radius: 1.5em;
  -webkit-transform: scale(1.05) !important;
          transform: scale(1.05) !important;
}
.Question_container__1Y3QS {
  margin-top: 56px;
  padding-bottom: 100px;
  background-color: #292F36;
  color: #fff;
}

.Question_UserArea__3n9wP {
  z-index: 999 !important;
}

.Question_primaryButton__374du {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef00 !important;
  background-color: #c7ef00 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
  box-shadow: 0 0 1px 2px #c7ef0080 !important;
}

.Question_primaryButton__374du:focus {
  box-shadow: 0 0 1px 2px #c7ef0080 !important;
}

.Question_primaryButton__374du:hover, .Question_primaryButton__374du:active, .Question_primaryButton__374du.Question_active__2X-6x {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Question_primaryButtonBlocked__3Fvcw {
  transition: 0.2s;
  cursor: not-allowed !important;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef0000 !important;
  background-color: #c7ef0060 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.Question_primaryButtonBlocked__3Fvcw:hover, .Question_primaryButtonBlocked__3Fvcw:active, .Question_primaryButtonBlocked__3Fvcw.Question_active__2X-6x {
  background-color: #c7ef0060 !important;
  border: 1px solid #c7ef0000 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Question_primaryButtonBlocked__3Fvcw:focus {
  box-shadow: 0 0 1px 2px #c7ef0080 !important;
}

.Question_secondaryButton__34Hg9 {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.Question_secondaryButton__34Hg9:focus {
  box-shadow: 0 0 1px 2px #c7ef0080 !important;
}

.Question_secondaryButton__34Hg9:hover, .Question_secondaryButton__34Hg9:active, .Question_secondaryButton__34Hg9.Question_active__2X-6x {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
  box-shadow: 0 0 1px 2px #c7ef0080 !important;
}


.Question_primaryLink__udE45 {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid transparent !important;
  background-color: transparent !important; 
  color: #c7ef00 !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.Question_primaryLink__udE45:hover, .Question_primaryLink__udE45:active, .Question_primaryLink__udE45.Question_active__2X-6x {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
  box-shadow: 0 0 1px 2px #c7ef0080 !important;
}

.Question_primaryLink__udE45:focus {
  box-shadow: 0 0 1px 2px #c7ef0080 !important;
}

.Question_toRight__3nPMZ {
  margin-left: auto;
  margin-right: 0;
  display: flex !important;
  text-align: right;
}

.Question_roundButton__3qclh {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 30px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  padding: 15px 30px !important;
  flex: 1 1;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 15px;
  text-decoration: underline !important;
}

.Question_roundButton__3qclh:hover, .Question_roundButton__3qclh:active, .Question_roundButton__3qclh.Question_active__2X-6x {
  background-color: #39424d !important;
  border: 1px solid #39424d !important;
}

.Question_outlineSecondaryButton___jHvF {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 30px !important;
  border: 1px solid #292F36 !important;
  background-color: transparent !important;
  color: #292F36 !important;
  padding: 15px 30px !important;
  text-decoration: underline !important;
  flex: 1 1;
  margin-left: auto;
  margin-right: 0;
}

.Question_outlineSecondaryButton___jHvF:hover, .Question_outlineSecondaryButton___jHvF:active, .Question_outlineSecondaryButton___jHvF.Question_active__2X-6x {
  background-color: #292F36 !important;
  border: 1px solid #292F36 !important;
  color: white !important;
}

.Question_secondaryLink__1oHo3 {
  cursor: pointer;
  color: #292F36;
  text-decoration: underline;
  -webkit-text-decoration-color: #c7ef00;
          text-decoration-color: #c7ef00;
}

.Question_secondaryLink__1oHo3:hover, .Question_secondaryLink__1oHo3:active, .Question_secondaryLink__1oHo3.Question_active__2X-6x {
  color: #c7ef00;
  text-decoration: underline;
  -webkit-text-decoration-color: #c7ef00;
          text-decoration-color: #c7ef00;
}

.Question_bgWhite__1Z8Zb {
  background-color: white;
  color: #292F36;
}

.Question_borderRadius25__3fgnk {
  border-radius: 25px !important;
}

.Question_toBottomCenter__3lLQZ {
  margin: 0 auto !important;
}

.Question_toCenter__1GJXa {
  text-align: center;
}

.Question_questionWidget__HSno1 {
  line-height: 45px;
  display: flex;
  background-color: white;
  padding: 15px 25px;
  margin: 10px 0;
  border-radius: 25px !important;
  border: 0 !important;
  color: #292F36 !important;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
}

p.Question_card-text__1bDR8 {
  color: #292F36 !important;
}

.Question_option__udFzA {
  background-color: white;
  border: solid 2px transparent !important;
  color: #292F36 !important;
  box-shadow: 0 0 5px 1px transparent;
  border-radius: 25px !important;
  cursor: pointer;
}

.Question_optionActive__2iqGN {
  transition: 500ms;
  background-color: white;
  border: solid 2px #c7ef00 !important;
  color: #292F36;
  box-shadow: 0 0 5px 1px #c7ef007e;
  border-radius: 25px !important;
  cursor: pointer;
}

.Question_removedOption__1k_qq {
  background-color: rgb(84, 89, 94) !important;
  max-height: 100px !important;
  border-radius: 25px !important;
}

.Question_removedActiveOption__1RWtY {
  opacity: 0.2;
  max-height: 100px !important;
  border-radius: 25px !important;
}

.Question_removedOption__1k_qq .Question_optionContent__n2d2j {
  opacity: 0.5;
  max-height: 50px !important;
  overflow: hidden;
}

.Question_removedOption__1k_qq .Question_optionPosition__2OK-b {
  height: 25px;
  width: 25px;
  padding: 0 6px;
  color: #292F36;
  border: solid 1px #292f366b;
  border-radius: 50%;
}

.Question_optionContent__n2d2j {
  padding-left: 0 !important;
}

.Question_optionContent__n2d2j p {
  margin-bottom: 0;
}

.Question_optionPosition__2OK-b {
  height: 25px;
  width: 25px;
  padding: 0 6px;
  border: solid 1px #292f366b;
  border-radius: 50%;
}

.Question_optionPositionActive__11hNO {
  transition: 500ms;
  height: 25px;
  width: 25px;
  padding: 0 6px;
  border: solid 1px #292f36;
  border-radius: 50%;
  color: white !important;
  background: #292f36 !important;
}

.Question_minWidth__9dK4Q {
  max-width: -webkit-min-content !important;
  max-width: min-content !important;
  flex-grow: inherit !important;
}

.Question_centered__-362Y {
  margin-left: auto;
  margin-right: auto;
  display: flex !important;
}

.Question_modalRigthAnswer__FzOmq .Question_modalContent__3D687 {
  background-color: #c7ef00 !important;
}

.Question_tagBanner__3JiY6 {
  transition: all 200ms ease-in-out;
  border-radius: 1.5em;
  color: white;
  background: #3CA55C;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #B5AC49, #3CA55C); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.Question_tagBanner__3JiY6:hover {
  text-decoration: none !important;
  color: white;
  box-shadow: 0 0 10px black;
  border-radius: 1.5em;
  -webkit-transform: scale(1.05) !important;
          transform: scale(1.05) !important;
}

.Question_examBanner__2bzFF {
  transition: all 200ms ease-in-out;
  border-radius: 1.5em;
  color: white;
  background: #525252; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #525252, #3d72b4); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.Question_examBanner__2bzFF:hover {
  text-decoration: none !important;
  color: white;
  box-shadow: 0 0 10px black;
  border-radius: 1.5em;
  -webkit-transform: scale(1.05) !important;
          transform: scale(1.05) !important;
}

.Question_areaBanner__2j7MB {
  transition: all 200ms ease-in-out;
  border-radius: 1.5em;
  color: white;
  background: #136a8a; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #136a8a, #267871); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.Question_areaBanner__2j7MB:hover {
  text-decoration: none !important;
  color: white;
  box-shadow: 0 0 10px black;
  border-radius: 1.5em;
  -webkit-transform: scale(1.05) !important;
          transform: scale(1.05) !important;
}

img {
  max-width: 100%;
}

.Question_rightAnswer__1SSLl {
  padding: 5px 3px 5px 15px;
  font-weight: bold;
  background-color: #292F36;
  color: white;
  border-radius: 15px;
  margin-bottom: 20px;
}

.Question_rightAnswerOption__1lC_P {
  padding: 2px 7px;
  font-weight: bolder;
  background-color: white;
  color: #292F36;
  border-radius: 50%;
}

.Question_optionTrash__iuxxs {
  transition: all ease 500ms;
  opacity: 0.6;
  -webkit-animation-duration: 3000ms;
          animation-duration: 3000ms;
  z-index: 3;
  padding: 1px 0 0 0 !important;
  border: solid 1px #292F36;
  border-color: #292F36;
  border-radius: 50%;
  background-color: #fff;
  position: absolute !important;
  bottom: 14px;
  right: 10px;
  text-align: center;
  max-width: 30px !important;
  min-height: 30px !important;
  width: 30px !important;
}

.Question_optionTrash__iuxxs:hover {
  opacity: 1;
  transition: all ease 200ms;
  background-color: #cecece;
}

.Question_optionUndoTrash__2_M1w {
  cursor: pointer;
  opacity: 1;
  z-index: 3;
  transition: all ease 500ms;
  -webkit-animation-duration: 3000ms;
          animation-duration: 3000ms;
  border-radius: 50%;
  background-color: transparent;
  padding: 3px 0 0 0 !important;
  border-radius: 50%;
  background-color: transparent !important;
  position: absolute !important;
  align-items: center !important;
  bottom: 14px;
  right: 10px;
  text-align: center;
  max-width: 30px !important;
  min-height: 30px !important;
  width: 30px !important;
}

.Question_optionUndoTrash__2_M1w:hover {
  transition: all ease 200ms;
  background-color: #ccc !important;
}
.Question_container__2iNVB {
  margin-top: 56px;
  padding-bottom: 100px;
  background-color: #292F36;
  color: #fff;
}

.Question_UserArea__2D1Gc {
  z-index: 999 !important;
}

.Question_primaryButton__zX1d1 {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef00 !important;
  background-color: #c7ef00 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.Question_primaryButton__zX1d1:hover, .Question_primaryButton__zX1d1:active, .Question_primaryButton__zX1d1.Question_active__Xi53q {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Question_secondaryButton__3huG8 {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.Question_secondaryButton__3huG8:hover, .Question_secondaryButton__3huG8:active, .Question_secondaryButton__3huG8.Question_active__Xi53q {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}


.Question_primaryLink__1GT9m {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid transparent !important;
  background-color: transparent !important; 
  color: #c7ef00 !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.Question_primaryLink__1GT9m:hover, .Question_primaryLink__1GT9m:active, .Question_primaryLink__1GT9m.Question_active__Xi53q {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Question_roundButton__CziTC {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50% !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  padding: 10px 15px !important;
  flex: 1 1;
  margin-left: auto;
  margin-right: 0;
  max-width: 46px;
}

.Question_roundButton__CziTC:hover, .Question_roundButton__CziTC:active, .Question_roundButton__CziTC.Question_active__Xi53q {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  color: #292F36 !important;
}

.Question_secondaryLink__K45jL {
  cursor: pointer;
  color: #292F36;
  text-decoration: underline;
  -webkit-text-decoration-color: #c7ef00;
          text-decoration-color: #c7ef00;
}

.Question_secondaryLink__K45jL:hover, .Question_secondaryLink__K45jL:active, .Question_secondaryLink__K45jL.Question_active__Xi53q {
  color: #c7ef00;
  text-decoration: underline;
  -webkit-text-decoration-color: #c7ef00;
          text-decoration-color: #c7ef00;
}

.Question_bgWhite__WRObU {
  background-color: white;
  border: 0 !important;
  color: #292F36;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
}

.Question_borderRadius25__2Fqpe {
  border-radius: 25px !important;
}

.Question_toBottomCenter__Lm0I4 {
  margin: 0 auto !important;
}

.Question_toCenter__2cEdO {
  text-align: center;
}

.Question_toRight__15pZu {
  text-align: right;
}

.Question_questionWidget__26PtJ {
  line-height: 45px;
  display: flex;
  background-color: white;
  padding: 15px 25px;
  margin: 10px 0;
  border-radius: 25px !important;
  border: 0 !important;
  color: #292F36;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
}
.QuestionWidget_container__3ZcGB {
  margin-top: 56px;
  padding-bottom: 100px;
  background-color: #292F36;
  color: #fff;
}

.QuestionWidget_UserArea__-DVPI {
  z-index: 999 !important;
}

.QuestionWidget_primaryButton__3L9cY {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef00 !important;
  background-color: #c7ef00 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.QuestionWidget_primaryButton__3L9cY:hover, .QuestionWidget_primaryButton__3L9cY:active, .QuestionWidget_primaryButton__3L9cY.QuestionWidget_active__1-7ZY {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.QuestionWidget_primaryButtonBlocked__OaUXJ {
  transition: 0.2s;
  cursor: not-allowed !important;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef0000 !important;
  background-color: #c7ef0060 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.QuestionWidget_primaryButtonBlocked__OaUXJ:hover, .QuestionWidget_primaryButtonBlocked__OaUXJ:active, .QuestionWidget_primaryButtonBlocked__OaUXJ.QuestionWidget_active__1-7ZY {
  background-color: #c7ef0060 !important;
  border: 1px solid #c7ef0000 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.QuestionWidget_secondaryButton__2nlV6 {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.QuestionWidget_secondaryButton__2nlV6:hover, .QuestionWidget_secondaryButton__2nlV6:active, .QuestionWidget_secondaryButton__2nlV6.QuestionWidget_active__1-7ZY {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}


.QuestionWidget_primaryLink__3xkmK {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid transparent !important;
  background-color: transparent !important; 
  color: #c7ef00 !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.QuestionWidget_primaryLink__3xkmK:hover, .QuestionWidget_primaryLink__3xkmK:active, .QuestionWidget_primaryLink__3xkmK.QuestionWidget_active__1-7ZY {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.QuestionWidget_toRight__2WgXM {
  margin-left: auto;
  margin-right: 0;
  display: flex !important;
  text-align: right;
}

*:hover a.QuestionWidget_clickableCard__27QWr {
  color: #000 !important;
  color: initial !important;
  text-decoration: none !important;
}

.QuestionWidget_clickableCard__27QWr {
  transition: all 200ms ease-in-out;
}

.QuestionWidget_clickableCard__27QWr:hover {
  box-shadow: 0 0 10px black;
  border-radius: 1.5em;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.QuestionWidget_clickableCard__27QWr .QuestionWidget_buttonIcon__1SYZe {
  transition: all 200ms ease-in-out;
}

.QuestionWidget_clickableCard__27QWr .QuestionWidget_buttonIcon__1SYZe:hover {
  color: #c7ef00;
}

.QuestionWidget_toRight__2WgXM button {
  display: flex;
  margin: 0 0 0 auto;
}

.QuestionWidget_roundButton__19nDH {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 30px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  padding: 15px 30px !important;
  flex: 1 1;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 15px;
  text-decoration: underline !important;
}

.QuestionWidget_roundButton__19nDH:hover, .QuestionWidget_roundButton__19nDH:active, .QuestionWidget_roundButton__19nDH.QuestionWidget_active__1-7ZY {
  background-color: #39424d !important;
  border: 1px solid #39424d !important;
}

.QuestionWidget_outlineSecondaryButton__3Njdd {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 30px !important;
  border: 1px solid #292F36 !important;
  background-color: transparent !important;
  color: #292F36 !important;
  padding: 15px 30px !important;
  text-decoration: underline !important;
  flex: 1 1;
  margin-left: auto;
  margin-right: 0;
}

.QuestionWidget_outlineSecondaryButton__3Njdd:hover, .QuestionWidget_outlineSecondaryButton__3Njdd:active, .QuestionWidget_outlineSecondaryButton__3Njdd.QuestionWidget_active__1-7ZY {
  background-color: #292F36 !important;
  border: 1px solid #292F36 !important;
  color: white !important;
}

.QuestionWidget_secondaryLink__3OmHu {
  cursor: pointer;
  color: #292F36 !important;
  background: none;
  border: none;
  text-decoration: underline;
  padding: 0 !important;
  -webkit-text-decoration-color: #c7ef00;
          text-decoration-color: #c7ef00;
  transition: all 300ms;
}

.QuestionWidget_secondaryLink__3OmHu:hover, .QuestionWidget_secondaryLink__3OmHu:active, .QuestionWidget_secondaryLink__3OmHu.QuestionWidget_active__1-7ZY {
  color: #c7ef00 !important;
  text-decoration: underline !important;
  -webkit-text-decoration-color: #c7ef00 !important;
          text-decoration-color: #c7ef00 !important;
}

.QuestionWidget_bgWhite__7bl6Y {
  background-color: white;
  color: #292F36;
}

.QuestionWidget_borderRadius25__34XNj {
  border-radius: 25px !important;
}

.QuestionWidget_toBottomCenter__1oa1Q {
  margin: 0 auto !important;
}

.QuestionWidget_toCenter__2Pr8e {
  text-align: center;
}

.QuestionWidget_questionWidget__35Ifj {
  line-height: 45px;
  display: flex;
  background-color: white;
  padding: 15px 25px;
  margin: 10px 0;
  border-radius: 25px !important;
  border: 0 !important;
  color: #292F36;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
}

.QuestionWidget_option__2sQKU {
  background-color: white;
  border: solid 2px transparent !important;
  color: #292F36;
  box-shadow: 0 0 5px 1px transparent;
  border-radius: 25px !important;
}

.QuestionWidget_optionActive__13gfn {
  transition: 500ms;
  background-color: white;
  border: solid 2px #c7ef00 !important;
  color: #292F36;
  box-shadow: 0 0 5px 1px #c7ef007e;
  border-radius: 25px !important;
}

.QuestionWidget_optionContent__2hC7k {
  padding-left: 0 !important;
}

.QuestionWidget_optionContent__2hC7k p {
  margin-bottom: 0;
}

.QuestionWidget_optionPosition__3Uhd- {
  height: 25px;
  width: 25px;
  padding: 0 6px;
  border: solid 1px #292f366b;
  border-radius: 50%;
}

.QuestionWidget_optionPositionActive__JbxMu {
  transition: 500ms;
  height: 25px;
  width: 25px;
  padding: 0 6px;
  border: solid 1px #292f36;
  border-radius: 50%;
  color: white !important;
  background: #292f36 !important;
}

.QuestionWidget_minWidth__2b1cr {
  max-width: -webkit-min-content !important;
  max-width: min-content !important;
  flex-grow: inherit !important;
}

.QuestionWidget_centered__1D1k4 {
  margin-left: auto;
  margin-right: auto;
  display: flex !important;
}

.QuestionWidget_modalRigthAnswer__dEvEJ .QuestionWidget_modalContent__t8UCo {
  background-color: #c7ef00 !important;
}

.Search_container__2Ko_g {
  margin-top: 56px;
  padding-bottom: 100px;
  background-color: #292F36;
  color: #fff;
}

.Search_UserArea__1L7Ce {
  z-index: 999 !important;
}

.Search_primaryButton__2sDz5 {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef00 !important;
  background-color: #c7ef00 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.Search_primaryButton__2sDz5:hover, .Search_primaryButton__2sDz5:active, .Search_primaryButton__2sDz5.Search_active__3YED2 {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Search_secondaryButton__O_aMp {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.Search_secondaryButton__O_aMp:hover, .Search_secondaryButton__O_aMp:active, .Search_secondaryButton__O_aMp.Search_active__3YED2 {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Search_roundButton__2n1T_ {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50% !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  padding: 10px 15px !important;
  flex: 1 1;
  margin-left: auto;
  margin-right: 0;
  max-width: 46px;
}

.Search_roundButton__2n1T_:hover, .Search_roundButton__2n1T_:active, .Search_roundButton__2n1T_.Search_active__3YED2 {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  color: #292F36 !important;
}

.Search_primaryLink__2tXbY {
  cursor: pointer;
  color: #c7ef00 !important;
  text-decoration: underline !important;
  -webkit-text-decoration-color: #c7ef00;
          text-decoration-color: #c7ef00;
}

.Search_primaryLink__2tXbY:hover, .Search_primaryLink__2tXbY:active, .Search_primaryLink__2tXbY.Search_active__3YED2 {
  color: #b2d600 !important;
  text-decoration: underline;
  -webkit-text-decoration-color: #b2d600 !important;
          text-decoration-color: #b2d600 !important;
}

.Search_bgWhite__2_qN1 {
  background-color: white;
  border: 0 !important;
  color: #292F36;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
}

.Search_borderRadius25__3alg1 {
  border-radius: 25px !important;
}

.Search_toBottomCenter__2foHt {
  margin: 0 auto !important;
}

.Search_toCenter__2avr4 {
  text-align: center;
}

.Search_questionWidget__F69yC {
  line-height: 45px;
  display: flex;
  background-color: white;
  padding: 15px 25px;
  margin: 10px 0;
  border-radius: 25px !important;
  border: 0 !important;
  color: #292F36;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
}

.Search_dangerCircle__3JJuq {
  width: 6px;
  height: 6px;
  margin: 0 0 2px 10px;
  display: inline-block;
  border-radius: 50%;
  background-color: #d34646;
  box-shadow: 0 0 15px 0 #d34646;
}

.Search_toRigth__1J6yb {
  text-align: right;
}

.Search_foundResults__3L2tn {
  line-height: 2rem;
  font-size: 12px;
  color: rgba(255,255,255, 0.5);
}
.Area_container__1Eufp {
  margin-top: 56px;
  padding-bottom: 100px;
  background-color: #292F36;
  color: #fff;
}

.Area_UserArea__3LTIZ {
  z-index: 999 !important;
}

.Area_primaryButton__nq2o6 {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef00 !important;
  background-color: #c7ef00 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.Area_primaryButton__nq2o6:hover, .Area_primaryButton__nq2o6:active, .Area_primaryButton__nq2o6.Area_active__2jL5P {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Area_secondaryButton__1h5oV {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.Area_secondaryButton__1h5oV:hover, .Area_secondaryButton__1h5oV:active, .Area_secondaryButton__1h5oV.Area_active__2jL5P {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}


.Area_primaryLink__Eg-KM {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid transparent !important;
  background-color: transparent !important; 
  color: #c7ef00 !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.Area_primaryLink__Eg-KM:hover, .Area_primaryLink__Eg-KM:active, .Area_primaryLink__Eg-KM.Area_active__2jL5P {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Area_roundButton__2Bw9v {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50% !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  padding: 10px 15px !important;
  flex: 1 1;
  margin-left: auto;
  margin-right: 0;
  max-width: 46px;
}

.Area_roundButton__2Bw9v:hover, .Area_roundButton__2Bw9v:active, .Area_roundButton__2Bw9v.Area_active__2jL5P {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  color: #292F36 !important;
}

.Area_secondaryLink__1Thxq {
  cursor: pointer;
  color: #292F36;
  text-decoration: underline;
  -webkit-text-decoration-color: #c7ef00;
          text-decoration-color: #c7ef00;
}

.Area_secondaryLink__1Thxq:hover, .Area_secondaryLink__1Thxq:active, .Area_secondaryLink__1Thxq.Area_active__2jL5P {
  color: #c7ef00;
  text-decoration: underline;
  -webkit-text-decoration-color: #c7ef00;
          text-decoration-color: #c7ef00;
}

.Area_bgWhite__3OUzE {
  background-color: white;
  border: 0 !important;
  color: #292F36;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
}

.Area_borderRadius25__1NnJc {
  border-radius: 25px !important;
}

.Area_toBottomCenter__2LJCH {
  margin: 0 auto !important;
}

.Area_toCenter__2oQ4l {
  text-align: center;
}

.Area_toRight__1LeHz {
  text-align: right;
}

.Area_questionWidget__2VZ2A {
  line-height: 45px;
  display: flex;
  background-color: white;
  padding: 15px 25px;
  margin: 10px 0;
  border-radius: 25px !important;
  border: 0 !important;
  color: #292F36;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
}

.Area_infoCard__jVtbV a:not(.Area_textDecorationNone__2Hsgb)  {
  transition: all 200ms;
  font-weight: bold;
  color: #292F36 !important;
  text-decoration: underline !important;
  -webkit-text-decoration-color: #c7ef00 !important;
          text-decoration-color: #c7ef00 !important;
}


.Area_infoCard__jVtbV a:hover:not(.Area_textDecorationNone__2Hsgb) {
  color: #c7ef00 !important;
  text-decoration: underline !important;
  -webkit-text-decoration-color: #c7ef00 !important;
          text-decoration-color: #c7ef00 !important;
}


.Area_tagBanner__1lwkJ {
  transition: all 200ms ease-in-out;
  border-radius: 1.5em;
  color: white;
  background: #3CA55C;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #B5AC49, #3CA55C); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.Area_tagBanner__1lwkJ:hover {
  text-decoration: none !important;
  color: white;
  box-shadow: 0 0 10px black;
  border-radius: 1.5em;
  -webkit-transform: scale(1.05) !important;
          transform: scale(1.05) !important;
}
.byExam_container__2cXe7 {
  margin-top: 56px;
  padding-bottom: 100px;
  background-color: #292F36;
  color: #fff;
}

.byExam_UserArea__FQI4y {
  z-index: 999 !important;
}

.byExam_primaryButton__11aUz {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef00 !important;
  background-color: #c7ef00 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.byExam_primaryButton__11aUz:hover, .byExam_primaryButton__11aUz:active, .byExam_primaryButton__11aUz.byExam_active__1YRWQ {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.byExam_secondaryButton__2AiMm {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.byExam_secondaryButton__2AiMm:hover, .byExam_secondaryButton__2AiMm:active, .byExam_secondaryButton__2AiMm.byExam_active__1YRWQ {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}


.byExam_primaryLink__26atU {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid transparent !important;
  background-color: transparent !important; 
  color: #c7ef00 !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.byExam_primaryLink__26atU:hover, .byExam_primaryLink__26atU:active, .byExam_primaryLink__26atU.byExam_active__1YRWQ {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.byExam_roundButton__1JYUx {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50% !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  padding: 10px 15px !important;
  flex: 1 1;
  margin-left: auto;
  margin-right: 0;
  max-width: 46px;
}

.byExam_roundButton__1JYUx:hover, .byExam_roundButton__1JYUx:active, .byExam_roundButton__1JYUx.byExam_active__1YRWQ {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  color: #292F36 !important;
}

.byExam_secondaryLink__3aJED {
  cursor: pointer;
  color: #292F36;
  text-decoration: underline;
  -webkit-text-decoration-color: #c7ef00;
          text-decoration-color: #c7ef00;
}

.byExam_secondaryLink__3aJED:hover, .byExam_secondaryLink__3aJED:active, .byExam_secondaryLink__3aJED.byExam_active__1YRWQ {
  color: #c7ef00;
  text-decoration: underline;
  -webkit-text-decoration-color: #c7ef00;
          text-decoration-color: #c7ef00;
}

.byExam_bgWhite__1qU0V {
  background-color: white;
  border: 0 !important;
  color: #292F36;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
}

.byExam_borderRadius25__1vXoJ {
  border-radius: 25px !important;
}

.byExam_toBottomCenter__mHMcO {
  margin: 0 auto !important;
}

.byExam_toCenter__1Mbmi {
  text-align: center;
}

.byExam_toRight__3g09I {
  text-align: right;
}

.byExam_questionWidget__2BHBN {
  line-height: 45px;
  display: flex;
  background-color: white;
  padding: 15px 25px;
  margin: 10px 0;
  border-radius: 25px !important;
  border: 0 !important;
  color: #292F36;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
}

.byExam_infoCard__i-jT3 a:not(.byExam_textDecorationNone__39E8s)  {
  transition: all 200ms;
  font-weight: bold;
  color: #292F36 !important;
  text-decoration: underline !important;
  -webkit-text-decoration-color: #c7ef00 !important;
          text-decoration-color: #c7ef00 !important;
}


.byExam_infoCard__i-jT3 a:hover:not(.byExam_textDecorationNone__39E8s) {
  color: #c7ef00 !important;
  text-decoration: underline !important;
  -webkit-text-decoration-color: #c7ef00 !important;
          text-decoration-color: #c7ef00 !important;
}


.byExam_tagBanner__GA0op {
  transition: all 200ms ease-in-out;
  border-radius: 1.5em;
  color: white;
  background: #3CA55C;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #B5AC49, #3CA55C); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.byExam_tagBanner__GA0op:hover {
  text-decoration: none !important;
  color: white;
  box-shadow: 0 0 10px black;
  border-radius: 1.5em;
  -webkit-transform: scale(1.05) !important;
          transform: scale(1.05) !important;
}
.Unauthorized_homeContainer__1k3F8 {
  margin-top: 56px;
  background-color: #292F36;
  color: #fff;
}

.Unauthorized_UserArea__1HejV {
  position: fixed !important;
  z-index: 999 !important;
}

.Unauthorized_primaryButton__3qcmn {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef00 !important;
  background-color: #c7ef00 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.Unauthorized_primaryButton__3qcmn:hover, .Unauthorized_primaryButton__3qcmn:active, .Unauthorized_primaryButton__3qcmn.Unauthorized_active__Oxg6S {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Unauthorized_secondaryButton__2hpaa {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.Unauthorized_secondaryButton__2hpaa:hover, .Unauthorized_secondaryButton__2hpaa:active, .Unauthorized_secondaryButton__2hpaa.Unauthorized_active__Oxg6S {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Unauthorized_bgNone__34FA8 {
  background-color: transparent !important;
  border: 0 !important;
}

.Unauthorized_brightness05__7WBz- {
  -webkit-filter: brightness(0.45);
          filter: brightness(0.45);
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
  border-radius: 25px !important;
}

@media (min-width: 768px) {
  .Unauthorized_spaceUp__1UIQJ {
    padding-top: 200px;
  }

  .Unauthorized_spaceUpTwo__35TqP {
    padding-top: 30px;
  }

  .Unauthorized_spaceThree__2ziva {
    padding: 100px 0;
  }

  .Unauthorized_pb50__2fTuh {
    padding-bottom: 50px;
  }
}

@media (max-width: 768px) {
  .Unauthorized_spaceUp__1UIQJ {
    padding-top: 50px;
    margin: 0 25px;
  }

  .Unauthorized_spaceUpTwo__35TqP {
    padding-top: 0;
  }
}

/**
 * Special
 */

::selection { 
  background: #c7ef00; 
  color: #292F36;
  opacity: 1;
}
.StickersWidget_container__1cIyL {
  margin-top: 56px;
  padding-bottom: 100px;
  background-color: #292F36;
  color: #fff;
}

.StickersWidget_UserArea__2xG2- {
  z-index: 999 !important;
}

.StickersWidget_primaryButton__qXUf4 {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef00 !important;
  background-color: #c7ef00 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.StickersWidget_primaryButton__qXUf4:hover, .StickersWidget_primaryButton__qXUf4:active, .StickersWidget_primaryButton__qXUf4.StickersWidget_active__7bInL {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.StickersWidget_primaryButtonBlocked__4Wdz9 {
  transition: 0.2s;
  cursor: not-allowed !important;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef0000 !important;
  background-color: #c7ef0060 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.StickersWidget_primaryButtonBlocked__4Wdz9:hover, .StickersWidget_primaryButtonBlocked__4Wdz9:active, .StickersWidget_primaryButtonBlocked__4Wdz9.StickersWidget_active__7bInL {
  background-color: #c7ef0060 !important;
  border: 1px solid #c7ef0000 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.StickersWidget_secondaryButton__2f0th {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.StickersWidget_secondaryButton__2f0th:hover, .StickersWidget_secondaryButton__2f0th:active, .StickersWidget_secondaryButton__2f0th.StickersWidget_active__7bInL {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}


.StickersWidget_primaryLink__2Q-Hq {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid transparent !important;
  background-color: transparent !important; 
  color: #c7ef00 !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.StickersWidget_primaryLink__2Q-Hq:hover, .StickersWidget_primaryLink__2Q-Hq:active, .StickersWidget_primaryLink__2Q-Hq.StickersWidget_active__7bInL {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.StickersWidget_toRight__1XglJ {
  margin-left: auto;
  margin-right: 0;
  display: flex !important;
  text-align: right;
}

.StickersWidget_roundButton__2vL-k {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 30px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  padding: 15px 30px !important;
  flex: 1 1;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 15px;
  text-decoration: underline !important;
}

.StickersWidget_roundButton__2vL-k:hover, .StickersWidget_roundButton__2vL-k:active, .StickersWidget_roundButton__2vL-k.StickersWidget_active__7bInL {
  background-color: #39424d !important;
  border: 1px solid #39424d !important;
}

.StickersWidget_outlineSecondaryButton__L0hg1 {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 30px !important;
  border: 1px solid #292F36 !important;
  background-color: transparent !important;
  color: #292F36 !important;
  padding: 15px 30px !important;
  text-decoration: underline !important;
  flex: 1 1;
  margin-left: auto;
  margin-right: 0;
}

.StickersWidget_outlineSecondaryButton__L0hg1:hover, .StickersWidget_outlineSecondaryButton__L0hg1:active, .StickersWidget_outlineSecondaryButton__L0hg1.StickersWidget_active__7bInL {
  background-color: #292F36 !important;
  border: 1px solid #292F36 !important;
  color: white !important;
}

.StickersWidget_secondaryLink__xX51u {
  cursor: pointer;
  color: #292F36;
  text-decoration: underline;
  -webkit-text-decoration-color: #c7ef00;
          text-decoration-color: #c7ef00;
}

.StickersWidget_secondaryLink__xX51u:hover, .StickersWidget_secondaryLink__xX51u:active, .StickersWidget_secondaryLink__xX51u.StickersWidget_active__7bInL {
  color: #c7ef00;
  text-decoration: underline;
  -webkit-text-decoration-color: #c7ef00;
          text-decoration-color: #c7ef00;
}

.StickersWidget_bgDark__2AbYn {
  background-color: #292F36 !important;
  color: white;
  border: 0 solid #000 !important;
}

.StickersWidget_dashed__pTv73 {
  border: 4px dashed #c7ef0067 !important;
}

.StickersWidget_borderRadius25__cF9PV {
  border-radius: 25px !important;
}

.StickersWidget_toBottomCenter__Ia0PW {
  margin: 0 auto !important;
}

.StickersWidget_toCenter__1LYcS {
  text-align: center;
}

.StickersWidget_questionWidget__1-Fh7 {
  line-height: 45px;
  display: flex;
  background-color: white;
  padding: 15px 25px;
  margin: 10px 0;
  border-radius: 25px !important;
  border: 0 !important;
  color: #292F36;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
}

.StickersWidget_option__2ftXM {
  background-color: white;
  border: solid 2px transparent !important;
  color: #292F36;
  box-shadow: 0 0 5px 1px transparent;
  border-radius: 25px !important;
}

.StickersWidget_optionActive__2amOh {
  transition: 500ms;
  background-color: white;
  border: solid 2px #c7ef00 !important;
  color: #292F36;
  box-shadow: 0 0 5px 1px #c7ef007e;
  border-radius: 25px !important;
}

.StickersWidget_optionContent__XxdAZ {
  padding-left: 0 !important;
}

.StickersWidget_optionContent__XxdAZ p {
  margin-bottom: 0;
}

.StickersWidget_optionPosition__2NCTj {
  height: 25px;
  width: 25px;
  padding: 0 6px;
  border: solid 1px #292f366b;
  border-radius: 50%;
}

.StickersWidget_optionPositionActive__2DGSU {
  transition: 500ms;
  height: 25px;
  width: 25px;
  padding: 0 6px;
  border: solid 1px #292f36;
  border-radius: 50%;
  color: white !important;
  background: #292f36 !important;
}

.StickersWidget_minWidth__nMwzY {
  max-width: -webkit-min-content !important;
  max-width: min-content !important;
  flex-grow: inherit !important;
}

.StickersWidget_centered__35w6t {
  margin-left: auto;
  margin-right: auto;
  display: flex !important;
}

.StickersWidget_modalRigthAnswer__1SsMu .StickersWidget_modalContent__KUPzV {
  background-color: #c7ef00 !important;
}

.StickersWidget_tagBanner__3B1Or {
  transition: all 200ms ease-in-out;
  border-radius: 1.5em;
  color: white;
  background: #3CA55C;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #B5AC49, #3CA55C); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.StickersWidget_tagBanner__3B1Or:hover {
  text-decoration: none !important;
  color: white;
  box-shadow: 0 0 10px black;
  border-radius: 1.5em;
  -webkit-transform: scale(1.05) !important;
          transform: scale(1.05) !important;
}

.StickersWidget_examBanner__2zOr6:hover {
  text-decoration: none !important;
  color: white;
  box-shadow: 0 0 10px black;
  border-radius: 1.5em;
  -webkit-transform: scale(1.05) !important;
          transform: scale(1.05) !important;
}

.StickersWidget_areaBanner__3Ve8R {
  transition: all 200ms ease-in-out;
  border-radius: 1.5em;
  color: white;
  background: #136a8a; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #136a8a, #267871); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.StickersWidget_areaBanner__3Ve8R:hover {
  text-decoration: none !important;
  color: white;
  box-shadow: 0 0 10px black;
  border-radius: 1.5em;
  -webkit-transform: scale(1.05) !important;
          transform: scale(1.05) !important;
}

img {
  max-width: 100%;
}

.StickersWidget_titleLevel__3n2xt h5 {
  font-weight: bold;
  opacity: 0.5;
  display: grid;
  grid-template-columns: minmax(20px, 1fr) auto minmax(20px, 1fr);
  align-items: center;
  text-align: center;
  grid-gap: 20px;
  width: 80%;
  margin: 0 auto;

}

.StickersWidget_titleLevel__3n2xt h5:before,
.StickersWidget_titleLevel__3n2xt h5:after {
  content: '';
  border-top: 2px solid ;
}

.StickersWidget_justifyContentCenter__2Mqmt {
  justify-content: center;
  /* display: contents; */
}

.StickersWidget_finalRow__12by0 {
  text-align: center;
  justify-content: center;
  margin-top: 50px;
  max-width: 500px;
}

.StickersWidget_titleRow__40Kxd {
  text-align: center;
  justify-content: center;
  margin-top: 50px;
}

.StickersWidget_examsCard___fhhV {
  transition: all 200ms ease-in-out;
  border-radius: 1.5em;
}

.StickersWidget_examsCard___fhhV img {
  -webkit-filter: brightness(0.5);
          filter: brightness(0.5);
}

.StickersWidget_examsCard___fhhV:hover {
  box-shadow: 0 0 10px black;
  border-radius: 1.5em;
  -webkit-transform: scale(1.05) !important;
          transform: scale(1.05) !important;
}

.StickersWidget_examsCard___fhhV .StickersWidget_buttonIcon__390rD {
  transition: all 200ms ease-in-out;
}

.StickersWidget_examsCard___fhhV:hover .StickersWidget_buttonIcon__390rD {
  color: #c7ef00;
}

.StickersWidget_bgNone__ujAdj {
  background-color: transparent !important;
  border: 0 !important;
}

.StickersWidget_progressCard__2FqlB {
  border: solid 1px #525252;
  padding: 2em;
  border-radius: 25px;
}

.StickersWidget_roadButton__3XSkN {
  padding-right: 30px;
  height: 160px;
}

@media screen and (min-width: 700px) {
  .StickersWidget_container__1cIyL {
    border-right: solid 1px #525252;
  }
}

.StickersWidget_activeSticker__3a4bb, .StickersWidget_disabledSticker__1EShS {
  padding-top: 10px;
  text-align: center;
  align-items: center;
  cursor: pointer;
  -webkit-animation-duration: 500ms;
          animation-duration: 500ms;
}

.StickersWidget_activeSticker__3a4bb:hover, .StickersWidget_disabledSticker__1EShS:hover {
  padding-top: 10px;
  text-align: center;
  align-items: center;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  cursor: pointer;
}

.StickersWidget_closeButton__3Tg4g {
  position: absolute;
  right: 10px;
  top: 10px;
}
.User_container__2FAYo {
  margin-top: 56px;
  padding-bottom: 100px;
  background-color: #292F36;
  color: #fff;
}

.User_UserArea__3xLRK {
  z-index: 999 !important;
}

.User_primaryButton__dH7Il {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef00 !important;
  background-color: #c7ef00 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.User_primaryButton__dH7Il:hover, .User_primaryButton__dH7Il:active, .User_primaryButton__dH7Il.User_active__24V8H {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.User_secondaryButton__3BQ4W {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.User_secondaryButton__3BQ4W:hover, .User_secondaryButton__3BQ4W:active, .User_secondaryButton__3BQ4W.User_active__24V8H {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}


.User_primaryLink__31w9S {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid transparent !important;
  background-color: transparent !important; 
  color: #c7ef00 !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.User_primaryLink__31w9S:hover, .User_primaryLink__31w9S:active, .User_primaryLink__31w9S.User_active__24V8H {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.User_roundButton__1aJpC {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50% !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  padding: 10px 15px !important;
  flex: 1 1;
  margin-left: auto;
  margin-right: 0;
  max-width: 46px;
}

.User_roundButton__1aJpC:hover, .User_roundButton__1aJpC:active, .User_roundButton__1aJpC.User_active__24V8H {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  color: #292F36 !important;
}

.User_secondaryLink__2z-Lu {
  cursor: pointer;
  color: #292F36;
  text-decoration: underline;
  -webkit-text-decoration-color: #c7ef00;
          text-decoration-color: #c7ef00;
}

.User_secondaryLink__2z-Lu:hover, .User_secondaryLink__2z-Lu:active, .User_secondaryLink__2z-Lu.User_active__24V8H {
  color: #c7ef00;
  text-decoration: underline;
  -webkit-text-decoration-color: #c7ef00;
          text-decoration-color: #c7ef00;
}

.User_bgWhite__1BI7y {
  background-color: white;
  border: 0 !important;
  color: #292F36;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
}

.User_borderRadius25__3NSnG {
  border-radius: 25px !important;
}

.User_toBottomCenter__1xBDp {
  margin: 0 auto !important;
}

.User_toCenter__NdCxF {
  text-align: center;
}

.User_toRight__3AfYo {
  text-align: right;
}

.User_questionWidget__3LZAT {
  line-height: 45px;
  display: flex;
  background-color: white;
  padding: 15px 25px;
  margin: 10px 0;
  border-radius: 25px !important;
  border: 0 !important;
  color: #292F36;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
}

.User_infoCard__3etu6 a:not(.User_textDecorationNone__3OdBH)  {
  transition: all 200ms;
  font-weight: bold;
  color: #292F36 !important;
  text-decoration: underline !important;
  -webkit-text-decoration-color: #c7ef00 !important;
          text-decoration-color: #c7ef00 !important;
}


.User_infoCard__3etu6 a:hover:not(.User_textDecorationNone__3OdBH) {
  color: #c7ef00 !important;
  text-decoration: underline !important;
  -webkit-text-decoration-color: #c7ef00 !important;
          text-decoration-color: #c7ef00 !important;
}

.User_loginIcon__cLje5 {
  height: 30px;
  width: 30px;
  background-color: #f8f8f8;
  border-radius: 50%;
  margin: auto 15px;
  padding-left: 8px;
}

.User_userCircle__1kC4D {
  cursor: pointer;
  display:inline-block;
  width: 2em;
  height: 2em;
  font-size: 80px;
  line-height: 2em;
  text-align:center;
  border-radius:50%;
  background: white;
  vertical-align:middle;
  color:#292F36;
}
.User_userCircle__1kC4D:hover {
  transition: 0.2s;
  background-color: #F6F6F6;
}

.User_userLettersName__2GqQZ {
  color:#292F36;
  font-size: 80px;
  line-height: 2em;
  position: relative;
  top: -4px;
}

.User_userCard__3xLls {
  margin-top: 2em;
  border-radius: 2em;
  /* background: linear-gradient(99.26deg, #A43BBE 2.98%, #72417E 100.84%); */
  padding: 2em 0 1em 0;
}

.User_userCardMobile__14WjX {
  margin-top: 2em;
  /* border-radius: 2em; */
  background: linear-gradient(99.26deg, #A43BBE 2.98%, #72417E 100.84%);
  padding: 2em 0 1em 0;
}

.User_userCircleMobile__1qPFr {
  cursor: pointer;
  display:inline-block;
  width: 1.5em;
  height: 1.5em;
  font-size: 80px;
  line-height: 1.5em;
  text-align:center;
  border-radius:50%;
  background: white;
  vertical-align:middle;
  color:#292F36;
}
.User_userCircleMobile__1qPFr:hover {
  transition: 0.2s;
  background-color: #F6F6F6;
}

.User_userLettersNameMobile__wOT7y {
  color:#292F36;
  font-size: 80px;
  line-height: 1.5em;
  position: relative;
  top: -4px;
}

.User_rightLink__6933m {
  display: flex;
  margin: 0 0 0 auto;
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid transparent !important;
  background-color: transparent !important; 
  color: #c7ef00 !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.User_rightLink__6933m:hover, .User_rightLink__6933m:active, .User_rightLink__6933m.User_active__24V8H {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.User_dangerLink__184Te {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid transparent !important;
  background-color: transparent !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.User_dangerLink__184Te:hover, .User_dangerLink__184Te:active, .User_dangerLink__184Te.User_active__24V8H {
  background-color: rgb(255,72,35,0.5) !important;
  border: 1px solid rgb(255,72,35,0.5) !important;
  text-decoration: underline;
  color: white !important;
}

.User_betaTag__1yC4B {
  margin-left: 10px;
  color: #c7ef00;
  text-shadow:
      0 0 7px rgba(255, 255, 255, 0.5),
      0 0 10px rgba(255, 255, 255, 0.5),
      0 0 21px #fff,
      0 0 42px #c7ef00,
      0 0 82px #c7ef00,
      0 0 92px #c7ef00,
      0 0 102px #c7ef00,
      0 0 151px #c7ef00;
}

.User_bgDark__3TS4T {
  background-color: transparent !important;
  border: solid 1px #525252 !important;
  padding: 2em !important;
  border-radius: 25px !important;
}

.User_formInput__2XwJw {
  background-color: transparent !important;
  color: #c7ef00 !important;
  border: solid 1px #c7ef0080 !important;
}

.User_formInput__2XwJw:focus, .User_copyBytton__2Hp-r:focus {
  box-shadow: 0 0 1px 2px #c7ef0080 !important;
}

.User_copyBytton__2Hp-r {
  background-color: #525252 !important;
  border-radius: 50% !important;
  border: transparent !important;
  height: 40px;
}
.Register_container__3iPpU {
  margin-top: 56px;
  padding-bottom: 100px;
  background-color: #292F36;
  color: #fff;
}

.Register_UserArea__29NOA {
  z-index: 999 !important;
}

.Register_primaryButton__CUawA {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef00 !important;
  background-color: #c7ef00 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.Register_primaryButton__CUawA:hover, .Register_primaryButton__CUawA:active, .Register_primaryButton__CUawA.Register_active__2DAad {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Register_secondaryButton__Vvvq4 {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.Register_secondaryButton__Vvvq4:hover, .Register_secondaryButton__Vvvq4:active, .Register_secondaryButton__Vvvq4.Register_active__2DAad {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Register_primaryLink__1KQnT {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid transparent !important;
  background-color: transparent !important; 
  color: #c7ef00 !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.Register_primaryLink__1KQnT:hover, .Register_primaryLink__1KQnT:active, .Register_primaryLink__1KQnT.Register_active__2DAad {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Register_bgNone__GOkXj {
  background-color: transparent !important;
  border: 0 !important;
}

.Register_brightness05__3yPZV {
  -webkit-filter: brightness(0.45);
          filter: brightness(0.45);
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
  border-radius: 25px !important;
}

.Register_dFlex__3QaU3 {
  display: flex !important;
}

.Register_toRight__3b6Bg {
  text-align: right;
}

.Register_toBottomCenter__6423R {
  display: inherit !important;
  max-width: 101px;
  bottom: 0;
  /* display: flex !important; */
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: center;
  /* max-width: 110px; */
}

.Register_maxminHeight215__JZ7QI {
  border-radius: 25px !important;
  min-height: 215px;
  max-height: 215px;
}

::selection { 
  background: #c7ef00; 
  color: #292F36;
  opacity: 1;
}

.Register_progressImage__296bT {
  display: flex;
  margin: 60px auto;
  text-align: center;
}

.Register_containerRegister__anNXr {
  text-align: center;
}

.Register_formField__2Br96 {
  text-align: left;
}

.Register_formError__1ez-K {
  background-color: #ff000010;
  padding: 10px 15px;
  border: 1px solid #FF482390;
  border-radius: 6px;
}

.Register_formInput__1sm2j {
  background-color: transparent !important;
  color: white !important;
  border: 1px solid rgba(255, 255, 255, 0.4) !important;
}
.Register_formInput__1sm2j:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem #c7ef0025 !important;
  border: 1px solid #c7ef00cc !important;
}

.Register_card__3FB_v {
  padding: 3rem 2rem;
  margin-bottom: 30px;
  background-color: transparent !important;
  color: white !important;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  border-radius: 25px;
}

.Register_activeButton__3QAfn, .Register_activeButton__3QAfn:focus {
  padding: 10px !important;
  margin: 5px;
  min-width: 100%;
  border: 1px solid #c7ef00 !important;
  box-shadow: 0 0 5px 0px #c7ef0080 !important;
  background-color: rgba(255, 255, 255, 0.2) !important;
  color: white !important;
  text-decoration: none !important;
}

.Register_notActiveButton__3_HZ5 {
  color: white !important;
  margin: 5px;
  padding: 10px !important;
  min-width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.Register_notActiveButton__3_HZ5:focus {
  box-shadow: 0 0 5px 0px #c7ef0020 !important; 
}

.Register_notActiveButton__3_HZ5:hover {
  text-decoration: none !important;
  background-color: rgba(255, 255, 255, 0.2);
}

.Register_maxWidth400__1FHhj {
  max-width: 400px;
}
.RegisterBeta_container__1RoiL {
  margin-top: 56px;
  padding-bottom: 100px;
  background-color: #292F36;
  color: #fff;
}

.RegisterBeta_UserArea__3ehib {
  z-index: 999 !important;
}

.RegisterBeta_primaryButton__3Bp6b {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef00 !important;
  background-color: #c7ef00 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.RegisterBeta_primaryButton__3Bp6b:hover, .RegisterBeta_primaryButton__3Bp6b:active, .RegisterBeta_primaryButton__3Bp6b.RegisterBeta_active__2_2fF {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.RegisterBeta_secondaryButton__2FzUH {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.RegisterBeta_secondaryButton__2FzUH:hover, .RegisterBeta_secondaryButton__2FzUH:active, .RegisterBeta_secondaryButton__2FzUH.RegisterBeta_active__2_2fF {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.RegisterBeta_primaryLink__4CCcf {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid transparent !important;
  background-color: transparent !important; 
  color: #c7ef00 !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.RegisterBeta_primaryLink__4CCcf:hover, .RegisterBeta_primaryLink__4CCcf:active, .RegisterBeta_primaryLink__4CCcf.RegisterBeta_active__2_2fF {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.RegisterBeta_bgNone__3pcZ- {
  background-color: transparent !important;
  border: 0 !important;
}

.RegisterBeta_brightness05__11x9W {
  -webkit-filter: brightness(0.45);
          filter: brightness(0.45);
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
  border-radius: 25px !important;
}

.RegisterBeta_dFlex__3LdTT {
  display: flex !important;
}

.RegisterBeta_toRight__1cRdG {
  text-align: right;
}

.RegisterBeta_toBottomCenter__1VqHJ {
  display: inherit !important;
  max-width: 101px;
  bottom: 0;
  /* display: flex !important; */
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: center;
  /* max-width: 110px; */
}

.RegisterBeta_maxminHeight215__cW063 {
  border-radius: 25px !important;
  min-height: 215px;
  max-height: 215px;
}

::selection { 
  background: #c7ef00; 
  color: #292F36;
  opacity: 1;
}

.RegisterBeta_progressImage__35u0i {
  display: flex;
  margin: 60px auto;
  text-align: center;
}

.RegisterBeta_containerRegister__3gGyD {
  text-align: center;
}

.RegisterBeta_formField__Gom3b {
  text-align: left;
}

.RegisterBeta_formInput__3Em4L {
  background-color: transparent !important;
  color: white !important;
  border: 1px solid rgba(255, 255, 255, 0.4) !important;
}
.RegisterBeta_formInput__3Em4L:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem #c7ef0025 !important;
  border: 1px solid #c7ef00cc !important;
}

.RegisterBeta_card__20tsy {
  padding: 3rem 2rem;
  margin-bottom: 30px;
  background-color: transparent !important;
  color: white !important;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  border-radius: 25px;
}

.RegisterBeta_activeButton__YqWHk, .RegisterBeta_activeButton__YqWHk:focus {
  padding: 10px !important;
  margin: 5px;
  min-width: 100%;
  border: 1px solid #c7ef00 !important;
  box-shadow: 0 0 5px 0px #c7ef0080 !important;
  background-color: rgba(255, 255, 255, 0.2) !important;
  color: white !important;
  text-decoration: none !important;
}

.RegisterBeta_notActiveButton__2PrQV {
  color: white !important;
  margin: 5px;
  padding: 10px !important;
  min-width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.RegisterBeta_notActiveButton__2PrQV:focus {
  box-shadow: 0 0 5px 0px #c7ef0020 !important; 
}

.RegisterBeta_notActiveButton__2PrQV:hover {
  text-decoration: none !important;
  background-color: rgba(255, 255, 255, 0.2);
}

.RegisterBeta_maxWidth400__1JNVc {
  max-width: 400px;
}

.RegisterBeta_imgLogo__2_lt5 {
  display: flex;
  margin: auto;
  width: 200px;
}

.RegisterBeta_containerInvite__lVkab {
  /* background-color: white; */
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
}

.RegisterBeta_invite__25tiL {
  top: -68px;
  border-radius: 25px !important;
  max-width: 300px;
  padding: 2rem 2rem 100px 2rem;
  color: #292F36;
  display: flex;
}

.RegisterBeta_line__LEAzO {
  width: 312px;
  bottom: 80px;
  left: -6px;
  border-bottom: 13px dotted #292F36;
  position: absolute;
}

.RegisterBeta_inviteIcon__2R165 {
  background-color: #292F36;
  width: 132px;
  height: 132px;
  border-radius: 50%;
  position: absolute;
  left: 80px;
  bottom: -80px;
}

.RegisterBeta_inviteIcon__2R165 img {
  margin: auto;
  margin-top: 24px;
  display: flex;
}

.RegisterBeta_secondaryLink__1j9wn {
  color: #292F36 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}

.RegisterBeta_secondaryLink__1j9wn:hover {
  color: #c7ef00 !important;
}
.Login_container__2K43I {
  margin-top: 56px;
  padding-bottom: 100px;
  background-color: #292F36;
  color: #fff;
}

.Login_UserArea__20TVB {
  z-index: 999 !important;
}

.Login_primaryButton__3avEV {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef00 !important;
  background-color: #c7ef00 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.Login_primaryButton__3avEV:hover, .Login_primaryButton__3avEV:active, .Login_primaryButton__3avEV.Login_active__3sYki {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Login_secondaryButton__-bI7U {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.Login_secondaryButton__-bI7U:hover, .Login_secondaryButton__-bI7U:active, .Login_secondaryButton__-bI7U.Login_active__3sYki {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Login_primaryLink___eERf {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid transparent !important;
  background-color: transparent !important; 
  color: #c7ef00 !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.Login_primaryLink___eERf:hover, .Login_primaryLink___eERf:active, .Login_primaryLink___eERf.Login_active__3sYki {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Login_bgNone__1O9P6 {
  background-color: transparent !important;
  border: 0 !important;
}

.Login_brightness05__ghyBx {
  -webkit-filter: brightness(0.45);
          filter: brightness(0.45);
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
  border-radius: 25px !important;
}

.Login_dFlex__1MvoE {
  display: flex !important;
}

.Login_toRight__27-RY {
  text-align: right;
}

.Login_toBottomCenter__1kZ1u {
  display: inherit !important;
  max-width: 101px;
  bottom: 0;
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: center;
}

.Login_maxminHeight215__1C6Nq {
  border-radius: 25px !important;
  min-height: 215px;
  max-height: 215px;
}

::selection { 
  background: #c7ef00; 
  color: #292F36;
  opacity: 1;
}

.Login_progressImage__2UzTq {
  display: flex;
  margin: 60px auto;
  text-align: center;
}

.Login_containerRegister__3R7bN {
  text-align: center;
}

.Login_formField__3IsWc {
  text-align: left;
}

.Login_formInput__3TJtb {
  background-color: transparent !important;
  color: white !important;
  border: 1px solid rgba(255, 255, 255, 0.4) !important;
  transition: 500ms all;
}

.Login_formInput__3TJtb:focus {
  box-shadow: 0 0 1px 2px #c7ef0080 !important;
}

.Login_card__24zOn {
  margin: auto;
  padding: 3rem 2rem;
  margin-bottom: 30px;
  background-color: transparent !important;
  color: white !important;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  border-radius: 25px;
  max-width: 435px;
}

.Login_card__24zOn button {
  margin-right: auto;
  margin-left: auto;
}

.Login_activeButton__1Pw04, .Login_activeButton__1Pw04:focus {
  padding: 10px !important;
  margin: 5px;
  min-width: 100%;
  border: 1px solid #c7ef00 !important;
  box-shadow: 0 0 5px 0px #c7ef0080 !important;
  background-color: rgba(255, 255, 255, 0.2) !important;
  color: white !important;
  text-decoration: none !important;
}

.Login_notActiveButton__VdLnU {
  color: white !important;
  margin: 5px;
  padding: 10px !important;
  min-width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.Login_notActiveButton__VdLnU:focus {
  box-shadow: 0 0 5px 0px #c7ef0020 !important; 
}

.Login_notActiveButton__VdLnU:hover {
  text-decoration: none !important;
  background-color: rgba(255, 255, 255, 0.2);
}

.Login_secondaryLink__G2_vu {
  cursor: pointer;
  color: white !important;
  text-decoration: underline !important;
  -webkit-text-decoration-color: #c7ef00 !important;
          text-decoration-color: #c7ef00 !important;
  font-weight: bold !important;
}

.Login_secondaryLink__G2_vu:hover, .Login_secondaryLink__G2_vu:active, .Login_secondaryLink__G2_vu.Login_active__3sYki {
  color: #c7ef00 !important;
  text-decoration: underline;
  -webkit-text-decoration-color: #c7ef00 !important;
          text-decoration-color: #c7ef00 !important;
}

.Login_or__3OvuQ {
  display: flex;
  flex-direction: row;
}
.Login_or__3OvuQ:before, .Login_or__3OvuQ:after{
  content: "";
  flex: 1 1;
  border-bottom: 1px solid;
  margin: auto;
}
.Login_or__3OvuQ:before {
  margin-right: 10px
}
.Login_or__3OvuQ:after {
  margin-left: 10px
}

.Edit_container__1P860 {
  margin-top: 56px;
  padding-bottom: 100px;
  background-color: #292F36;
  color: #fff;
}

.Edit_UserArea__2GXIk {
  z-index: 999 !important;
}

.Edit_primaryButton__33PdM {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef00 !important;
  background-color: #c7ef00 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.Edit_primaryButton__33PdM:hover, .Edit_primaryButton__33PdM:active, .Edit_primaryButton__33PdM.Edit_active__q2rUL {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Edit_secondaryButton__3c59_ {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.Edit_secondaryButton__3c59_:hover, .Edit_secondaryButton__3c59_:active, .Edit_secondaryButton__3c59_.Edit_active__q2rUL {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Edit_roundButton__3Tr3W {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50% !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  padding: 10px 15px !important;
  flex: 1 1;
  margin-left: auto;
  margin-right: 0;
  max-width: 46px;
}

.Edit_roundButton__3Tr3W:hover, .Edit_roundButton__3Tr3W:active, .Edit_roundButton__3Tr3W.Edit_active__q2rUL {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  color: #292F36 !important;
}

.Edit_primaryLink__BqIWF {
  cursor: pointer;
  color: #292F36;
  text-decoration: underline;
  -webkit-text-decoration-color: #c7ef00;
          text-decoration-color: #c7ef00;
}

.Edit_primaryLink__BqIWF:hover, .Edit_primaryLink__BqIWF:active, .Edit_primaryLink__BqIWF.Edit_active__q2rUL {
  color: #c7ef00;
  text-decoration: underline;
  -webkit-text-decoration-color: #c7ef00;
          text-decoration-color: #c7ef00;
}

.Edit_bgDark__2Scz6 {
  background-color: #292F36 !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  color: white;
  /* -webkit-box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05); */
  /* box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05); */
}

.Edit_formInput__1FfNH {
  color: white !important;
  background-color: transparent !important;
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.Edit_formInput__1FfNH:focus {
  color: #495057;
  background-color: #fff;
  border-color: #c7ef00 !important;
  outline: 0;
  box-shadow: 0 0 0 0.2rem #c7ef0033 !important;
}

.Edit_borderRadius25__2U8l5 {
  border-radius: 25px !important;
}

.Edit_toBottomCenter__1JVKa {
  margin: 0 auto !important;
}

.Edit_toCenter__-lwBv {
  text-align: center;
}

.Edit_questionWidget__3UVHd {
  line-height: 45px;
  display: flex;
  background-color: white;
  padding: 15px 25px;
  margin: 10px 0;
  border-radius: 25px !important;
  border: 0 !important;
  color: #292F36;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
}

.Edit_card__2ghqn {
  padding: 1rem;
  margin-bottom: 30px;
  background-color: transparent !important;
  color: white !important;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  border-radius: 25px;
}

.Edit_check__3tsww {
  position: absolute;
  height: 23px;
  width: 23px;
  min-width: 23px;
  border-radius: 50%;
  border: solid 2px white;
  background-color: transparent;
}

.Edit_activeCheck__3TQhi {
  position: absolute;
  height: 23px;
  width: 23px;
  min-width: 23px;
  border-radius: 50%;
  border: solid 2px white;
  background-color: transparent;
  transition: all 500ms;
}

.Edit_activeCheck__3TQhi::before {
  content: '';
  position: absolute;
  left: 1px;
  top: 1px;
  height: 17px;
  width: 17px;
  min-width: 17px;
  border-radius: 50%;
  background-color: #c7ef00;
}

.Edit_checkForm__2l_nl {
  padding: 5px;
  cursor: pointer;
}

.Tag_container__1I7ZC {
  margin-top: 56px;
  padding-bottom: 100px;
  background-color: #292F36;
  color: #fff;
}

.Tag_UserArea__1CGFo {
  z-index: 999 !important;
}

.Tag_primaryButton__2KEgo {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef00 !important;
  background-color: #c7ef00 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.Tag_primaryButton__2KEgo:hover, .Tag_primaryButton__2KEgo:active, .Tag_primaryButton__2KEgo.Tag_active__3eLqU {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Tag_secondaryButton__2L3wt {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.Tag_secondaryButton__2L3wt:hover, .Tag_secondaryButton__2L3wt:active, .Tag_secondaryButton__2L3wt.Tag_active__3eLqU {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}


.Tag_primaryLink__2di3q {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid transparent !important;
  background-color: transparent !important; 
  color: #c7ef00 !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.Tag_primaryLink__2di3q:hover, .Tag_primaryLink__2di3q:active, .Tag_primaryLink__2di3q.Tag_active__3eLqU {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Tag_roundButton__xrfXP {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50% !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  padding: 10px 15px !important;
  flex: 1 1;
  margin-left: auto;
  margin-right: 0;
  max-width: 46px;
}

.Tag_roundButton__xrfXP:hover, .Tag_roundButton__xrfXP:active, .Tag_roundButton__xrfXP.Tag_active__3eLqU {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  color: #292F36 !important;
}

.Tag_secondaryLink__z_m5t {
  cursor: pointer;
  color: #292F36;
  text-decoration: underline;
  -webkit-text-decoration-color: #c7ef00;
          text-decoration-color: #c7ef00;
}

.Tag_secondaryLink__z_m5t:hover, .Tag_secondaryLink__z_m5t:active, .Tag_secondaryLink__z_m5t.Tag_active__3eLqU {
  color: #c7ef00;
  text-decoration: underline;
  -webkit-text-decoration-color: #c7ef00;
          text-decoration-color: #c7ef00;
}

.Tag_bgWhite__20E3q {
  background-color: white;
  border: 0 !important;
  color: #292F36;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
}

.Tag_borderRadius25__31QUL {
  border-radius: 25px !important;
}

.Tag_toBottomCenter__-1TQ8 {
  margin: 0 auto !important;
}

.Tag_toCenter__3QTDy {
  text-align: center;
}

.Tag_toRight__1Tx4U {
  text-align: right;
}

.Tag_questionWidget__2ORCe {
  line-height: 45px;
  display: flex;
  background-color: white;
  padding: 15px 25px;
  margin: 10px 0;
  border-radius: 25px !important;
  border: 0 !important;
  color: #292F36;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
}

.Tag_infoCard__G9H78 a:not(.Tag_textDecorationNone__wlVKH)  {
  transition: all 200ms;
  font-weight: bold;
  color: #292F36 !important;
  text-decoration: underline !important;
  -webkit-text-decoration-color: #c7ef00 !important;
          text-decoration-color: #c7ef00 !important;
}


.Tag_infoCard__G9H78 a:hover:not(.Tag_textDecorationNone__wlVKH) {
  color: #c7ef00 !important;
  text-decoration: underline !important;
  -webkit-text-decoration-color: #c7ef00 !important;
          text-decoration-color: #c7ef00 !important;
}


.Tag_tagBanner__1MyKX {
  transition: all 200ms ease-in-out;
  border-radius: 1.5em;
  color: white;
  background: #3CA55C;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #B5AC49, #3CA55C); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.Tag_tagBanner__1MyKX:hover {
  text-decoration: none !important;
  color: white;
  box-shadow: 0 0 10px black;
  border-radius: 1.5em;
  -webkit-transform: scale(1.05) !important;
          transform: scale(1.05) !important;
}
.Attempt_container__2WXIM {
  margin-top: 56px;
  padding-bottom: 100px;
  background-color: #292F36;
  color: #fff;
}

.Attempt_UserArea__2yogd {
  z-index: 999 !important;
}

.Attempt_primaryButton__2VdKP {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef00 !important;
  background-color: #c7ef00 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.Attempt_primaryButton__2VdKP:hover, .Attempt_primaryButton__2VdKP:active, .Attempt_primaryButton__2VdKP.Attempt_active__nCmj6 {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Attempt_secondaryButton__1OY4h {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.Attempt_secondaryButton__1OY4h:hover, .Attempt_secondaryButton__1OY4h:active, .Attempt_secondaryButton__1OY4h.Attempt_active__nCmj6 {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}


.Attempt_primaryLink__38PNq {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid transparent !important;
  background-color: transparent !important; 
  color: #c7ef00 !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.Attempt_primaryLink__38PNq:hover, .Attempt_primaryLink__38PNq:active, .Attempt_primaryLink__38PNq.Attempt_active__nCmj6 {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Attempt_roundButton__2zd6c {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50% !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  padding: 10px 15px !important;
  flex: 1 1;
  margin-left: auto;
  margin-right: 0;
  max-width: 46px;
}

.Attempt_roundButton__2zd6c:hover, .Attempt_roundButton__2zd6c:active, .Attempt_roundButton__2zd6c.Attempt_active__nCmj6 {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  color: #292F36 !important;
}

.Attempt_secondaryLink__2NAd- {
  cursor: pointer;
  color: #292F36;
  text-decoration: underline;
  -webkit-text-decoration-color: #c7ef00;
          text-decoration-color: #c7ef00;
}

.Attempt_secondaryLink__2NAd-:hover, .Attempt_secondaryLink__2NAd-:active, .Attempt_secondaryLink__2NAd-.Attempt_active__nCmj6 {
  color: #c7ef00;
  text-decoration: underline;
  -webkit-text-decoration-color: #c7ef00;
          text-decoration-color: #c7ef00;
}

.Attempt_bgWhite__3aHRP {
  background-color: white;
  border: 0 !important;
  color: #292F36;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
}

.Attempt_borderRadius25__3TsLv {
  border-radius: 25px !important;
}

.Attempt_toBottomCenter__iE5oz {
  margin: 0 auto !important;
}

.Attempt_toCenter__187Bv {
  text-align: center;
}

.Attempt_toRight__pXh0G {
  text-align: right;
}

.Attempt_questionWidget__xI52X {
  line-height: 45px;
  display: flex;
  background-color: white;
  padding: 15px 25px;
  margin: 10px 0;
  border-radius: 25px !important;
  border: 0 !important;
  color: #292F36;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
}

.Attempt_progressBar__3h4sW {
  border-radius: 1rem !important;
}

.Attempt_progress__2Y-JE .Attempt_progress-bar__ABTpT {
  background-color: #c7ef00 !important;
}

.Attempt_result__eqv2v {
  font-size: 40px;
}

.Attempt_modal-content__31B_M{
  border-radius: 25px !important;
}
.Cell_container__3lPW4 {
  margin-top: 56px;
  padding-bottom: 100px;
  background-color: #292F36;
  color: #fff;
}

.Cell_UserArea__EvScs {
  z-index: 999 !important;
}

.Cell_primaryButton__2h285 {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef00 !important;
  background-color: #c7ef00 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.Cell_primaryButton__2h285:hover, .Cell_primaryButton__2h285:active, .Cell_primaryButton__2h285.Cell_active__kDXot {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Cell_primaryButtonBlocked__2pTuz {
  transition: 0.2s;
  cursor: not-allowed !important;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef0000 !important;
  background-color: #c7ef0060 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.Cell_primaryButtonBlocked__2pTuz:hover, .Cell_primaryButtonBlocked__2pTuz:active, .Cell_primaryButtonBlocked__2pTuz.Cell_active__kDXot {
  background-color: #c7ef0060 !important;
  border: 1px solid #c7ef0000 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Cell_secondaryButton__1_fs7 {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.Cell_secondaryButton__1_fs7:hover, .Cell_secondaryButton__1_fs7:active, .Cell_secondaryButton__1_fs7.Cell_active__kDXot {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}


.Cell_primaryLink__3A9KA {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid transparent !important;
  background-color: transparent !important; 
  color: #c7ef00 !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.Cell_primaryLink__3A9KA:hover, .Cell_primaryLink__3A9KA:active, .Cell_primaryLink__3A9KA.Cell_active__kDXot {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Cell_toRight__1ZTwa {
  margin-left: auto;
  margin-right: 0;
  display: flex !important;
  text-align: right;
}

.Cell_roundButton__2aVKf {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 30px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  padding: 15px 30px !important;
  flex: 1 1;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 15px;
  text-decoration: underline !important;
}

.Cell_roundButton__2aVKf:hover, .Cell_roundButton__2aVKf:active, .Cell_roundButton__2aVKf.Cell_active__kDXot {
  background-color: #39424d !important;
  border: 1px solid #39424d !important;
}

.Cell_outlineSecondaryButton__EGLSC {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 30px !important;
  border: 1px solid #292F36 !important;
  background-color: transparent !important;
  color: #292F36 !important;
  padding: 15px 30px !important;
  text-decoration: underline !important;
  flex: 1 1;
  margin-left: auto;
  margin-right: 0;
}

.Cell_outlineSecondaryButton__EGLSC:hover, .Cell_outlineSecondaryButton__EGLSC:active, .Cell_outlineSecondaryButton__EGLSC.Cell_active__kDXot {
  background-color: #292F36 !important;
  border: 1px solid #292F36 !important;
  color: white !important;
}

.Cell_secondaryLink__1IfQh {
  cursor: pointer;
  color: #292F36;
  text-decoration: underline;
  -webkit-text-decoration-color: #c7ef00;
          text-decoration-color: #c7ef00;
}

.Cell_secondaryLink__1IfQh:hover, .Cell_secondaryLink__1IfQh:active, .Cell_secondaryLink__1IfQh.Cell_active__kDXot {
  color: #c7ef00;
  text-decoration: underline;
  -webkit-text-decoration-color: #c7ef00;
          text-decoration-color: #c7ef00;
}

.Cell_bgDark__2zGEk {
  background-color: #292F36 !important;
  color: white;
  border: 0 solid #000 !important;
}

.Cell_dashed__3HlB- {
  border: 4px dashed #c7ef0067 !important;
}

.Cell_borderRadius25__2xZbv {
  border-radius: 25px !important;
}

.Cell_toBottomCenter__3sKMg {
  margin: 0 auto !important;
}

.Cell_toCenter__2degD {
  text-align: center;
}

.Cell_questionWidget__1oBkI {
  line-height: 45px;
  display: flex;
  background-color: white;
  padding: 15px 25px;
  margin: 10px 0;
  border-radius: 25px !important;
  border: 0 !important;
  color: #292F36;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
}

.Cell_option__2lfCE {
  background-color: white;
  border: solid 2px transparent !important;
  color: #292F36;
  box-shadow: 0 0 5px 1px transparent;
  border-radius: 25px !important;
}

.Cell_optionActive__2l-9T {
  transition: 500ms;
  background-color: white;
  border: solid 2px #c7ef00 !important;
  color: #292F36;
  box-shadow: 0 0 5px 1px #c7ef007e;
  border-radius: 25px !important;
}

.Cell_optionContent__XZTn2 {
  padding-left: 0 !important;
}

.Cell_optionContent__XZTn2 p {
  margin-bottom: 0;
}

.Cell_optionPosition__Tmt2- {
  height: 25px;
  width: 25px;
  padding: 0 6px;
  border: solid 1px #292f366b;
  border-radius: 50%;
}

.Cell_optionPositionActive__muWsB {
  transition: 500ms;
  height: 25px;
  width: 25px;
  padding: 0 6px;
  border: solid 1px #292f36;
  border-radius: 50%;
  color: white !important;
  background: #292f36 !important;
}

.Cell_minWidth__19HvS {
  max-width: -webkit-min-content !important;
  max-width: min-content !important;
  flex-grow: inherit !important;
}

.Cell_centered__2T_Z7 {
  margin-left: auto;
  margin-right: auto;
  display: flex !important;
}

.Cell_modalRigthAnswer__odxPe .Cell_modalContent__3dP1Z {
  background-color: #c7ef00 !important;
}

.Cell_tagBanner__RA6Mg {
  transition: all 200ms ease-in-out;
  border-radius: 1.5em;
  color: white;
  background: #3CA55C;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #B5AC49, #3CA55C); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.Cell_tagBanner__RA6Mg:hover {
  text-decoration: none !important;
  color: white;
  box-shadow: 0 0 10px black;
  border-radius: 1.5em;
  -webkit-transform: scale(1.05) !important;
          transform: scale(1.05) !important;
}

.Cell_examBanner__2GqI0:hover {
  text-decoration: none !important;
  color: white;
  box-shadow: 0 0 10px black;
  border-radius: 1.5em;
  -webkit-transform: scale(1.05) !important;
          transform: scale(1.05) !important;
}

.Cell_areaBanner__2XlHJ {
  transition: all 200ms ease-in-out;
  border-radius: 1.5em;
  color: white;
  background: #136a8a; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #136a8a, #267871); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.Cell_areaBanner__2XlHJ:hover {
  text-decoration: none !important;
  color: white;
  box-shadow: 0 0 10px black;
  border-radius: 1.5em;
  -webkit-transform: scale(1.05) !important;
          transform: scale(1.05) !important;
}

img {
  max-width: 100%;
}

.Rating_container__3frrQ {
  margin-top: 56px;
  padding-bottom: 100px;
  background-color: #292F36;
  color: #fff;
}

.Rating_UserArea__2rafH {
  z-index: 999 !important;
}

.Rating_primaryButton__1qhno {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef00 !important;
  background-color: #c7ef00 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.Rating_primaryButton__1qhno:hover, .Rating_primaryButton__1qhno:active, .Rating_primaryButton__1qhno.Rating_active__3A7KF {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Rating_primaryButtonBlocked__1CNQx {
  transition: 0.2s;
  cursor: not-allowed !important;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef0000 !important;
  background-color: #c7ef0060 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.Rating_primaryButtonBlocked__1CNQx:hover, .Rating_primaryButtonBlocked__1CNQx:active, .Rating_primaryButtonBlocked__1CNQx.Rating_active__3A7KF {
  background-color: #c7ef0060 !important;
  border: 1px solid #c7ef0000 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Rating_secondaryButton__1CIpz {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.Rating_secondaryButton__1CIpz:hover, .Rating_secondaryButton__1CIpz:active, .Rating_secondaryButton__1CIpz.Rating_active__3A7KF {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}


.Rating_primaryLink__1tla1 {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid transparent !important;
  background-color: transparent !important; 
  color: #c7ef00 !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.Rating_primaryLink__1tla1:hover, .Rating_primaryLink__1tla1:active, .Rating_primaryLink__1tla1.Rating_active__3A7KF {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}


.Rating_secondaryLink__33PF_ {
  cursor: pointer;
  color: #292F36 !important;
  text-decoration: underline !important;
  -webkit-text-decoration-color: #c7ef00 !important;
          text-decoration-color: #c7ef00 !important;
  font-weight: bold !important;
}

.Rating_secondaryLink__33PF_:hover, .Rating_secondaryLink__33PF_:active, .Rating_secondaryLink__33PF_.Rating_active__3A7KF {
  color: #c7ef00 !important;
  text-decoration: underline;
  -webkit-text-decoration-color: #c7ef00 !important;
          text-decoration-color: #c7ef00 !important;
}

.Rating_toRight__R9HYj {
  margin-left: auto;
  margin-right: 0;
  display: flex !important;
  text-align: right;
}

.Rating_roundButton__1lfE6 {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 30px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  padding: 15px 30px !important;
  flex: 1 1;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 15px;
  text-decoration: underline !important;
}

.Rating_roundButton__1lfE6:hover, .Rating_roundButton__1lfE6:active, .Rating_roundButton__1lfE6.Rating_active__3A7KF {
  background-color: #39424d !important;
  border: 1px solid #39424d !important;
}

.Rating_outlineSecondaryButton__2bqcS {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 30px !important;
  border: 1px solid #292F36 !important;
  background-color: transparent !important;
  color: #292F36 !important;
  padding: 15px 30px !important;
  text-decoration: underline !important;
  flex: 1 1;
  margin-left: auto;
  margin-right: 0;
}

.Rating_outlineSecondaryButton__2bqcS:hover, .Rating_outlineSecondaryButton__2bqcS:active, .Rating_outlineSecondaryButton__2bqcS.Rating_active__3A7KF {
  background-color: #292F36 !important;
  border: 1px solid #292F36 !important;
  color: white !important;
}

.Rating_secondaryLink__33PF_ {
  cursor: pointer;
  color: #292F36;
  text-decoration: underline;
  -webkit-text-decoration-color: #c7ef00;
          text-decoration-color: #c7ef00;
}

.Rating_secondaryLink__33PF_:hover, .Rating_secondaryLink__33PF_:active, .Rating_secondaryLink__33PF_.Rating_active__3A7KF {
  color: #c7ef00;
  text-decoration: underline;
  -webkit-text-decoration-color: #c7ef00;
          text-decoration-color: #c7ef00;
}

.Rating_bgDark__3ik05 {
  background-color: #292F36 !important;
  color: white;
  border: 0 solid #000 !important;
}

.Rating_dashed__1Hqsc {
  border: 4px dashed #c7ef0067 !important;
}

.Rating_borderRadius25__38pRn {
  border-radius: 25px !important;
}

.Rating_toBottomCenter__3lOuN {
  margin: 0 auto !important;
}

.Rating_toCenter__3_x1L {
  text-align: center;
}

.Rating_questionWidget__1uCgd {
  line-height: 45px;
  display: flex;
  background-color: white;
  padding: 15px 25px;
  margin: 10px 0;
  border-radius: 25px !important;
  border: 0 !important;
  color: #292F36;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
}

.Rating_option__VFwq7 {
  background-color: white;
  border: solid 2px transparent !important;
  color: #292F36;
  box-shadow: 0 0 5px 1px transparent;
  border-radius: 25px !important;
}

.Rating_optionActive__2Ofmf {
  transition: 500ms;
  background-color: white;
  border: solid 2px #c7ef00 !important;
  color: #292F36;
  box-shadow: 0 0 5px 1px #c7ef007e;
  border-radius: 25px !important;
}

.Rating_optionContent__1VmJu {
  padding-left: 0 !important;
}

.Rating_optionContent__1VmJu p {
  margin-bottom: 0;
}

.Rating_optionPosition__39nee {
  height: 25px;
  width: 25px;
  padding: 0 6px;
  border: solid 1px #292f366b;
  border-radius: 50%;
}

.Rating_optionPositionActive__1gYDD {
  transition: 500ms;
  height: 25px;
  width: 25px;
  padding: 0 6px;
  border: solid 1px #292f36;
  border-radius: 50%;
  color: white !important;
  background: #292f36 !important;
}

.Rating_minWidth__38fTZ {
  max-width: -webkit-min-content !important;
  max-width: min-content !important;
  flex-grow: inherit !important;
}

.Rating_centered__3vuTQ {
  margin-left: auto;
  margin-right: auto;
  display: flex !important;
}

.Rating_modalRigthAnswer__yXOgg .Rating_modalContent__KHUv9 {
  background-color: #c7ef00 !important;
}

.Rating_tagBanner__3I1ZO {
  transition: all 200ms ease-in-out;
  border-radius: 1.5em;
  color: white;
  background: #3CA55C;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #B5AC49, #3CA55C); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.Rating_tagBanner__3I1ZO:hover {
  text-decoration: none !important;
  color: white;
  box-shadow: 0 0 10px black;
  border-radius: 1.5em;
  -webkit-transform: scale(1.05) !important;
          transform: scale(1.05) !important;
}

.Rating_examBanner__3onLm:hover {
  text-decoration: none !important;
  color: white;
  box-shadow: 0 0 10px black;
  border-radius: 1.5em;
  -webkit-transform: scale(1.05) !important;
          transform: scale(1.05) !important;
}

.Rating_areaBanner__2JFZZ {
  transition: all 200ms ease-in-out;
  border-radius: 1.5em;
  color: white;
  background: #136a8a; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #136a8a, #267871); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.Rating_areaBanner__2JFZZ:hover {
  text-decoration: none !important;
  color: white;
  box-shadow: 0 0 10px black;
  border-radius: 1.5em;
  -webkit-transform: scale(1.05) !important;
          transform: scale(1.05) !important;
}

img {
  max-width: 100%;
}

.LoginModal_container__2cU0O {
  /* margin-top: 56px; */
  /* padding-bottom: 40px; */
  /* background-color: #292F36; */
  /* color: #fff; */
}

.LoginModal_UserArea__230up {
  z-index: 999 !important;
}

.LoginModal_primaryButton__2MVsm {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef00 !important;
  background-color: #c7ef00 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.LoginModal_primaryButton__2MVsm:hover, .LoginModal_primaryButton__2MVsm:active, .LoginModal_primaryButton__2MVsm.LoginModal_active__1B7-c {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.LoginModal_primaryButtonBlocked__1MYly {
  transition: 0.2s;
  cursor: not-allowed !important;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef0000 !important;
  background-color: #c7ef0060 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.LoginModal_primaryButtonBlocked__1MYly:hover, .LoginModal_primaryButtonBlocked__1MYly:active, .LoginModal_primaryButtonBlocked__1MYly.LoginModal_active__1B7-c {
  background-color: #c7ef0060 !important;
  border: 1px solid #c7ef0000 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.LoginModal_secondaryButton__3vXq3 {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.LoginModal_secondaryButton__3vXq3:hover, .LoginModal_secondaryButton__3vXq3:active, .LoginModal_secondaryButton__3vXq3.LoginModal_active__1B7-c {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.LoginModal_primaryLink__1m6T3 {
  padding: 0 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  -webkit-text-decoration-color: #b2d600 !important;
          text-decoration-color: #b2d600 !important;
}

.LoginModal_primaryLink__1m6T3:hover {
  color: #b2d600 !important;
}

.LoginModal_secondaryLink__q1TDa {
  cursor: pointer;
  color: #292F36 !important;
  text-decoration: underline !important;
  -webkit-text-decoration-color: #c7ef00 !important;
          text-decoration-color: #c7ef00 !important;
  font-weight: bold !important;
}

.LoginModal_secondaryLink__q1TDa:hover, .LoginModal_secondaryLink__q1TDa:active, .LoginModal_secondaryLink__q1TDa.LoginModal_active__1B7-c {
  color: #c7ef00 !important;
  text-decoration: underline;
  -webkit-text-decoration-color: #c7ef00 !important;
          text-decoration-color: #c7ef00 !important;
}

.LoginModal_toRight__yvT13 {
  margin-left: auto;
  margin-right: 0;
  display: flex !important;
  text-align: right;
}

.LoginModal_roundButton__1P--x {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 30px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  padding: 15px 30px !important;
  flex: 1 1;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 15px;
  text-decoration: underline !important;
}

.LoginModal_roundButton__1P--x:hover, .LoginModal_roundButton__1P--x:active, .LoginModal_roundButton__1P--x.LoginModal_active__1B7-c {
  background-color: #39424d !important;
  border: 1px solid #39424d !important;
}

.LoginModal_outlineSecondaryButton__cgZrN {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 30px !important;
  border: 1px solid #292F36 !important;
  background-color: transparent !important;
  color: #292F36 !important;
  padding: 15px 30px !important;
  text-decoration: underline !important;
  flex: 1 1;
  margin-left: auto;
  margin-right: 0;
}

.LoginModal_outlineSecondaryButton__cgZrN:hover, .LoginModal_outlineSecondaryButton__cgZrN:active, .LoginModal_outlineSecondaryButton__cgZrN.LoginModal_active__1B7-c {
  background-color: #292F36 !important;
  border: 1px solid #292F36 !important;
  color: white !important;
}

.LoginModal_secondaryLink__q1TDa {
  cursor: pointer;
  color: #292F36;
  text-decoration: underline;
  -webkit-text-decoration-color: #c7ef00;
          text-decoration-color: #c7ef00;
}

.LoginModal_secondaryLink__q1TDa:hover, .LoginModal_secondaryLink__q1TDa:active, .LoginModal_secondaryLink__q1TDa.LoginModal_active__1B7-c {
  color: #c7ef00;
  text-decoration: underline;
  -webkit-text-decoration-color: #c7ef00;
          text-decoration-color: #c7ef00;
}

.LoginModal_bgDark__1kKFe {
  background-color: #292F36 !important;
  color: white;
  border: 0 solid #000 !important;
}

.LoginModal_dashed__1liaT {
  border: 4px dashed #c7ef0067 !important;
}

.LoginModal_borderRadius25__139iw {
  border-radius: 25px !important;
}

.LoginModal_toBottomCenter__2U4Tn {
  margin: 0 auto !important;
}

.LoginModal_toCenter__2yYEo {
  text-align: center;
}

.LoginModal_questionWidget__IucBq {
  line-height: 45px;
  display: flex;
  background-color: white;
  padding: 15px 25px;
  margin: 10px 0;
  border-radius: 25px !important;
  border: 0 !important;
  color: #292F36;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
}

.LoginModal_option__3Mhxh {
  background-color: white;
  border: solid 2px transparent !important;
  color: #292F36;
  box-shadow: 0 0 5px 1px transparent;
  border-radius: 25px !important;
}

.LoginModal_optionActive__TVFxq {
  transition: 500ms;
  background-color: white;
  border: solid 2px #c7ef00 !important;
  color: #292F36;
  box-shadow: 0 0 5px 1px #c7ef007e;
  border-radius: 25px !important;
}

.LoginModal_optionContent__2i2F9 {
  padding-left: 0 !important;
}

.LoginModal_optionContent__2i2F9 p {
  margin-bottom: 0;
}

.LoginModal_optionPosition__Y2WMi {
  height: 25px;
  width: 25px;
  padding: 0 6px;
  border: solid 1px #292f366b;
  border-radius: 50%;
}

.LoginModal_optionPositionActive__3uYsp {
  transition: 500ms;
  height: 25px;
  width: 25px;
  padding: 0 6px;
  border: solid 1px #292f36;
  border-radius: 50%;
  color: white !important;
  background: #292f36 !important;
}

.LoginModal_minWidth__30CbG {
  max-width: -webkit-min-content !important;
  max-width: min-content !important;
  flex-grow: inherit !important;
}

.LoginModal_centered__2EOvf {
  margin-left: auto;
  margin-right: auto;
  display: flex !important;
}

.LoginModal_modalRigthAnswer__L1tAN .LoginModal_modalContent__2gZIV {
  background-color: #c7ef00 !important;
}

.LoginModal_tagBanner__oix9y {
  transition: all 200ms ease-in-out;
  border-radius: 1.5em;
  color: white;
  background: #3CA55C;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #B5AC49, #3CA55C); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.LoginModal_tagBanner__oix9y:hover {
  text-decoration: none !important;
  color: white;
  box-shadow: 0 0 10px black;
  border-radius: 1.5em;
  -webkit-transform: scale(1.05) !important;
          transform: scale(1.05) !important;
}

.LoginModal_examBanner__HVa-6:hover {
  text-decoration: none !important;
  color: white;
  box-shadow: 0 0 10px black;
  border-radius: 1.5em;
  -webkit-transform: scale(1.05) !important;
          transform: scale(1.05) !important;
}

.LoginModal_areaBanner__3xDkJ {
  transition: all 200ms ease-in-out;
  border-radius: 1.5em;
  color: white;
  background: #136a8a; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #136a8a, #267871); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.LoginModal_areaBanner__3xDkJ:hover {
  text-decoration: none !important;
  color: white;
  box-shadow: 0 0 10px black;
  border-radius: 1.5em;
  -webkit-transform: scale(1.05) !important;
          transform: scale(1.05) !important;
}

img {
  max-width: 100%;
}

.LoginModal_login__2Nz6n {
  z-index: 999;
  right: 0;
  -webkit-animation-name: LoginModal_example__fKXM0;
          animation-name: LoginModal_example__fKXM0;
  -webkit-animation-duration: 500ms;
          animation-duration: 500ms;
  text-align: center;
  position: fixed !important;
  min-height: 100vh;
  top: 0;
  border-radius: 0; /* 25px; */
  padding: 2em;
  box-shadow: 0 0 10px 8px rgba(0, 0, 0, 0.05);
  background-color: white;
  color: #2c3e50;
  max-width: 450px !important;
}


.LoginModal_form__1xk9m {
  padding: 25px;
  text-align: left;
}

.LoginModal_loginIcon__15ZeT {
  height: 100px;
  width: 100px;
  background-color: #f8f8f8;
  font-size: 50px;
  border-radius: 50%;
  vertical-align: text-bottom;
  margin: 25px auto;
  line-height: 100px;
}

.LoginModal_secButton__1AFfI {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.LoginModal_secButton__1AFfI:hover, .LoginModal_secButton__1AFfI:active, .LoginModal_secButton__1AFfI.LoginModal_active__1B7-c {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}
.Unity_container__35CYL {
  margin-top: 56px;
  padding-bottom: 100px;
  background-color: #292F36;
  color: #fff;
}

.Unity_UserArea__b54XY {
  z-index: 999 !important;
}

.Unity_primaryButton__uaYX9 {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef00 !important;
  background-color: #c7ef00 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.Unity_primaryButton__uaYX9:hover, .Unity_primaryButton__uaYX9:active, .Unity_primaryButton__uaYX9.Unity_active__1p-O1 {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Unity_secondaryButton__1wXPX {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.Unity_secondaryButton__1wXPX:hover, .Unity_secondaryButton__1wXPX:active, .Unity_secondaryButton__1wXPX.Unity_active__1p-O1 {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Unity_secButton__jZFM8 {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.Unity_secButton__jZFM8:hover, .Unity_secButton__jZFM8:active, .Unity_secButton__jZFM8.Unity_active__1p-O1 {
  background-color: transparent !important;
  border: 1px solid transparent !important;
  text-decoration: underline;
  color: #c7ef00 !important;
}

.Unity_primaryLink__fuKpG {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid transparent !important;
  background-color: transparent !important; 
  color: #c7ef00 !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.Unity_primaryLink__fuKpG:hover, .Unity_primaryLink__fuKpG:active, .Unity_primaryLink__fuKpG.Unity_active__1p-O1 {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Unity_roundButton__fdeUS {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50% !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  padding: 10px 15px !important;
  flex: 1 1;
  margin-left: auto;
  margin-right: 0;
  max-width: 46px;
}

.Unity_roundButton__fdeUS:hover, .Unity_roundButton__fdeUS:active, .Unity_roundButton__fdeUS.Unity_active__1p-O1 {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  color: #292F36 !important;
}

.Unity_secondaryLink__1r7gw {
  cursor: pointer;
  color: #292F36;
  text-decoration: underline;
  -webkit-text-decoration-color: #c7ef00;
          text-decoration-color: #c7ef00;
}

.Unity_secondaryLink__1r7gw:hover, .Unity_secondaryLink__1r7gw:active, .Unity_secondaryLink__1r7gw.Unity_active__1p-O1 {
  color: #c7ef00;
  text-decoration: underline;
  -webkit-text-decoration-color: #c7ef00;
          text-decoration-color: #c7ef00;
}

.Unity_bgWhite__YqcDo {
  background-color: white;
  border: 0 !important;
  color: #292F36;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
}

.Unity_borderRadius25__2n7WB {
  border-radius: 25px !important;
}

.Unity_toBottomCenter__3EBIR {
  margin: 0 auto !important;
}

.Unity_toCenter__f2wj9 {
  text-align: center;
}

.Unity_toRight__2CUGT {
  text-align: right;
}

.Unity_questionWidget__NwDnN {
  line-height: 45px;
  display: flex;
  background-color: white;
  padding: 15px 25px;
  margin: 10px 0;
  border-radius: 25px !important;
  border: 0 !important;
  color: #292F36;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
}

.Unity_finishModal__2b4V- {
  position: absolute !important;
  top: -50px !important;
  z-index: 9999;
  width: 100%;
  height: 100%;
  max-height: 100vh;
}

.Unity_verticalCenter__24R1l {
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.Unity_finish-modal-dialog__1K2_L {
  max-width: 100% !important;
  width: 100%;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
}

.Unity_examBanner__1tgzd {
  transition: all 200ms ease-in-out;
  border-radius: 1.5em;
  color: white;
  background-color: #292f36 !important;
}
.Task_container__1FC6g {
  /* margin-top: 56px; */
  margin-bottom: 100px;
  background-color: #292F36;
  color: #fff;
}

@media (min-width: 768px) {
  .Task_container__1FC6g {
    margin-top: 56px;
  }
}

.Task_UserArea__PKt7- {
  z-index: 999 !important;
}

.Task_primaryDefaultButton__3z_EX {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef00 !important;
  background-color: #c7ef00 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.Task_primaryDefaultButton__3z_EX:hover, .Task_primaryDefaultButton__3z_EX:active, .Task_primaryDefaultButton__3z_EX.Task_active__1S2zN {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Task_secondaryDefaultButton__27_c0 {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.Task_secondaryDefaultButton__27_c0:hover, .Task_secondaryDefaultButton__27_c0:active, .Task_secondaryDefaultButton__27_c0.Task_active__1S2zN {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Task_secDefaultButton__1CV4Q {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.Task_secDefaultButton__1CV4Q:hover, .Task_secDefaultButton__1CV4Q:active, .Task_secDefaultButton__1CV4Q.Task_active__1S2zN {
  background-color: transparent !important;
  border: 1px solid transparent !important;
  text-decoration: underline;
  color: #c7ef00 !important;
}

.Task_primaryLink__3c5na {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid transparent !important;
  background-color: transparent !important; 
  color: #c7ef00 !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.Task_primaryLink__3c5na:hover, .Task_primaryLink__3c5na:active, .Task_primaryLink__3c5na.Task_active__1S2zN {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Task_roundDefaultButton__2724n {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50% !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  padding: 10px 15px !important;
  flex: 1 1;
  margin-left: auto;
  margin-right: 0;
  max-width: 46px;
}

.Task_roundDefaultButton__2724n:hover, .Task_roundDefaultButton__2724n:active, .Task_roundDefaultButton__2724n.Task_active__1S2zN {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  color: #292F36 !important;
}

.Task_secondaryLink__3c_Ht {
  cursor: pointer;
  color: #292F36;
  text-decoration: underline;
  -webkit-text-decoration-color: #c7ef00;
          text-decoration-color: #c7ef00;
}

.Task_secondaryLink__3c_Ht:hover, .Task_secondaryLink__3c_Ht:active, .Task_secondaryLink__3c_Ht.Task_active__1S2zN {
  color: #c7ef00;
  text-decoration: underline;
  -webkit-text-decoration-color: #c7ef00;
          text-decoration-color: #c7ef00;
}

.Task_bgWhite__3TQjn {
  background-color: white;
  border: 0 !important;
  color: #292F36;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
}

.Task_borderRadius25__iMFlS {
  border-radius: 25px !important;
}

.Task_toBottomCenter__1JjMu {
  margin: 0 auto !important;
}

.Task_toCenter__3eD8J {
  text-align: center;
}

.Task_toRight__WE9hE {
  text-align: right;
}

.Task_questionWidget__200Uj {
  line-height: 45px;
  display: flex;
  background-color: white;
  padding: 15px 25px;
  margin: 10px 0;
  border-radius: 25px !important;
  border: 0 !important;
  color: #292F36;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
}

.Task_examBanner__3apA- {
  transition: all 200ms ease-in-out;
  border-radius: 1.5em;
  color: white;
  background-color: #292f36 !important;
}

.Task_starDiv__InL6T {
  margin-top: -50px;
}

.Task_pointsCard__2psDn {
  margin-top: -80px;
  margin-left: 15px;
  margin-right: 15px;
  background-color: #292f36 !important;
  font-size: large;
  color: white;
  font-weight: bolder;
  padding: 15px;
  border: solid 1px rgba(255, 255, 255, 0.2) !important;
  border-radius: 16px !important;
}

.Task_smallStarDiv__2gbwd {
  max-width: -moz-fit-content !important;
  max-width: -webkit-fit-content !important;
  max-width: fit-content !important;
  padding-left: 20px;
}

.Task_poitsDiv__65s5z {
  max-width: -moz-fit-content !important;
  max-width: -webkit-fit-content !important;
  max-width: fit-content !important;
  margin: auto 0;
  padding-left: 15px;
}

.Task_pointsNumberDiv__lqbg7 {
  color: #c7ef00;
  margin: auto 0;
  text-align: right;
  padding-right: 25px;
  font-weight: bolder;
}

@media (min-width: 768px) {
  .Task_completedStreakCircle__1jZEk {
    flex: 1 1;
    line-height: 50px;
    align-items: center;
    font-weight: bolder;
    font-size: 20px;
    color: #292F36 !important;
    padding: 0 !important;
    max-height: 50px !important;
    width: 50px !important;
    max-width: 50px !important;
    background-color: #c7ef00;
    border-radius: 50%;
    box-shadow: 0 0 10px 1px #c7ef00;
  }

  .Task_freezeStreakCircle__3HCNL {
    flex: 1 1;
    line-height: 50px;
    align-items: center;
    font-weight: bolder;
    font-size: 20px;
    color: #FFFFFF30 !important;
    padding: 0 !important;
    max-height: 50px !important;
    width: 50px !important;
    max-width: 50px !important;
    background-color: #FFFFFF20;
    border-radius: 50%;
  }

  .Task_freezeItemStreakCircle__17Yaj {
    flex: 1 1;
    line-height: 50px;
    align-items: center;
    font-weight: bolder;
    font-size: 20px;
    color: #FFFFFF30 !important;
    padding: 0 !important;
    max-height: 50px !important;
    width: 50px !important;
    max-width: 50px !important;
    background-color: #FFFFFF20;
    border-radius: 50%;
  }

  .Task_activeStreakCircle__13Oxl {
    flex: 1 1;
    line-height: 50px;
    align-items: center;
    font-weight: bolder;
    font-size: 20px;
    color: #FFF !important;
    padding: 0 !important;
    max-height: 50px !important;
    width: 50px !important;
    max-width: 50px !important;
    background-color: #C7ef0030;
    border-radius: 50%;
  }

  .Task_glowEffectImage__tTicl {
    max-height: 25px;
    max-width: 50px;
    display: flex;
  }

  .Task_glowEffectImageRotate__HqQrK {
    max-width: 50px;
    display: flex;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }

  .Task_glowEffectSpace__l6xEx {
    height: 25px;
    width: 50px;
    display: flex;
  }
}

@media (max-width: 768px) {
  .Task_completedStreakCircle__1jZEk {
    flex: 1 1;
    line-height: 30px;
    align-items: center;
    font-weight: bolder;
    font-size: 16px;
    color: #292F36 !important;
    padding: 0 !important;
    max-height: 30px !important;
    width: 30px !important;
    max-width: 30px !important;
    background-color: #c7ef00;
    border-radius: 50%;
    box-shadow: 0 0 10px 1px #c7ef00;
  }

  .Task_freezeStreakCircle__3HCNL {
    flex: 1 1;
    line-height: 30px;
    align-items: center;
    font-weight: bolder;
    font-size: 16px;
    color: #FFFFFF30 !important;
    padding: 0 !important;
    max-height: 30px !important;
    width: 30px !important;
    max-width: 30px !important;
    background-color: #FFFFFF20;
    border-radius: 50%;
  }

  .Task_freezeItemStreakCircle__17Yaj {
    flex: 1 1;
    line-height: 30px;
    align-items: center;
    font-weight: bolder;
    font-size: 16px;
    color: #FFFFFF30 !important;
    padding: 0 !important;
    max-height: 30px !important;
    width: 30px !important;
    max-width: 30px !important;
    background-color: #FFFFFF20;
    border-radius: 50%;
  }

  .Task_activeStreakCircle__13Oxl {
    flex: 1 1;
    line-height: 30px;
    align-items: center;
    font-weight: bolder;
    font-size: 16px;
    color: #FFF !important;
    padding: 0 !important;
    max-height: 30px !important;
    width: 30px !important;
    max-width: 30px !important;
    background-color: #C7ef0030;
    border-radius: 50%;
  }

  .Task_glowEffectImage__tTicl {
    max-height: 15px;
    max-width: 30px;
    display: flex;
  }

  .Task_glowEffectImageRotate__HqQrK {
    max-height: 15px;
    max-width: 30px;
    display: flex;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }

  .Task_glowEffectSpace__l6xEx {
    height: 15px;
    width: 30px;
    display: flex;
  }
}
.Task_container__16A6W {
  margin-top: 56px;
  padding-bottom: 100px;
  background-color: #292F36;
  color: #fff;
}

.Task_UserArea__2E48s {
  z-index: 999 !important;
}

.Task_primaryButton__2NNwg {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef00 !important;
  background-color: #c7ef00 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.Task_primaryButton__2NNwg:hover, .Task_primaryButton__2NNwg:active, .Task_primaryButton__2NNwg.Task_active__37z-7 {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Task_primaryButtonBlocked__3arbE {
  transition: 0.2s;
  cursor: not-allowed !important;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef0000 !important;
  background-color: #c7ef0060 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.Task_primaryButtonBlocked__3arbE:hover, .Task_primaryButtonBlocked__3arbE:active, .Task_primaryButtonBlocked__3arbE.Task_active__37z-7 {
  background-color: #c7ef0060 !important;
  border: 1px solid #c7ef0000 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Task_secondaryButton__2XvE2 {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.Task_secondaryButton__2XvE2:hover, .Task_secondaryButton__2XvE2:active, .Task_secondaryButton__2XvE2.Task_active__37z-7 {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}


.Task_primaryLink__ClPNL {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid transparent !important;
  background-color: transparent !important; 
  color: #c7ef00 !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.Task_primaryLink__ClPNL:hover, .Task_primaryLink__ClPNL:active, .Task_primaryLink__ClPNL.Task_active__37z-7 {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Task_toRight__3VURG {
  margin-left: auto;
  margin-right: 0;
  display: flex !important;
  text-align: right;
}

.Task_roundButton__9u6kK {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 30px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  padding: 15px 30px !important;
  flex: 1 1;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 15px;
  text-decoration: underline !important;
}

.Task_roundButton__9u6kK:hover, .Task_roundButton__9u6kK:active, .Task_roundButton__9u6kK.Task_active__37z-7 {
  background-color: #39424d !important;
  border: 1px solid #39424d !important;
}

.Task_outlineSecondaryButton__IBXBK {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 30px !important;
  border: 1px solid #292F36 !important;
  background-color: transparent !important;
  color: #292F36 !important;
  padding: 15px 30px !important;
  text-decoration: underline !important;
  flex: 1 1;
  margin-left: auto;
  margin-right: 0;
}

.Task_outlineSecondaryButton__IBXBK:hover, .Task_outlineSecondaryButton__IBXBK:active, .Task_outlineSecondaryButton__IBXBK.Task_active__37z-7 {
  background-color: #292F36 !important;
  border: 1px solid #292F36 !important;
  color: white !important;
}

.Task_activeButtonIcon__2hbFP {
  color:#c7ef00 !important;
  border-radius: 50%;
  box-shadow: 0 0 5px 1px #c7ef007e;
}

.Task_secondaryLink__3YbB2 {
  cursor: pointer;
  color: #292F36;
  text-decoration: underline;
  -webkit-text-decoration-color: #c7ef00;
          text-decoration-color: #c7ef00;
}

.Task_secondaryLink__3YbB2:hover, .Task_secondaryLink__3YbB2:active, .Task_secondaryLink__3YbB2.Task_active__37z-7 {
  color: #c7ef00;
  text-decoration: underline;
  -webkit-text-decoration-color: #c7ef00;
          text-decoration-color: #c7ef00;
}

.Task_bgDark__KE1QU {
  transition: all 200ms ease-in-out;
  background-color: #292F36 !important;
  color: white !important;
  border: 1px solid rgba(255, 255, 255, 0.30) !important;
}

.Task_bgLink__3FTfw:hover, .Task_bgActiveLink__21bzf:hover {
  cursor: pointer;
  color:#c7ef00 !important;
  border-color:#c7ef00 !important;
}

.Task_bgActiveLink__21bzf {
  background-color: #FB3874 !important;
}

.Task_bgTransparent__2GTvk {
  background-color: transparent !important;
  border-color: transparent !important;
}

.Task_dashed__2Ha3t {
  border: 4px dashed #c7ef0067 !important;
}

.Task_borderRadius25__3c4wY {
  border-radius: 25px !important;
}

.Task_toBottomCenter__1gdOI {
  margin: 0 auto !important;
}

.Task_toCenter__2mmvA {
  text-align: center;
}

.Task_questionWidget__3ooH6 {
  line-height: 45px;
  display: flex;
  background-color: white;
  padding: 15px 25px;
  margin: 10px 0;
  border-radius: 25px !important;
  border: 0 !important;
  color: #292F36;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
}

.Task_option__j9Dby {
  background-color: white;
  border: solid 2px transparent !important;
  color: #292F36;
  box-shadow: 0 0 5px 1px transparent;
  border-radius: 25px !important;
}

.Task_optionActive__11Gfx {
  transition: 500ms;
  background-color: white;
  border: solid 2px #c7ef00 !important;
  color: #292F36;
  box-shadow: 0 0 5px 1px #c7ef007e;
  border-radius: 25px !important;
}

.Task_optionContent__1Zsog {
  padding-left: 0 !important;
}

.Task_optionContent__1Zsog p {
  margin-bottom: 0;
}

.Task_optionPosition__zrglM {
  height: 25px;
  width: 25px;
  padding: 0 6px;
  border: solid 1px #292f366b;
  border-radius: 50%;
}

.Task_optionPositionActive__2wC-d {
  transition: 500ms;
  height: 25px;
  width: 25px;
  padding: 0 6px;
  border: solid 1px #292f36;
  border-radius: 50%;
  color: white !important;
  background: #292f36 !important;
}

.Task_minWidth__qrlhw {
  max-width: -webkit-min-content !important;
  max-width: min-content !important;
  flex-grow: inherit !important;
}

.Task_centered__2EW_V {
  margin-left: auto;
  margin-right: auto;
  display: flex !important;
}

.Task_modalRigthAnswer__3UU2E .Task_modalContent__DW9Ma {
  background-color: #c7ef00 !important;
}

.Task_tagBanner__2iysJ {
  transition: all 200ms ease-in-out;
  border-radius: 1.5em;
  color: white;
  background: #3CA55C;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #B5AC49, #3CA55C); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.Task_tagBanner__2iysJ:hover {
  text-decoration: none !important;
  color: white;
  box-shadow: 0 0 10px black;
  border-radius: 1.5em;
  -webkit-transform: scale(1.05) !important;
          transform: scale(1.05) !important;
}

.Task_examBanner__3dNZQ:hover {
  text-decoration: none !important;
  color: white;
  box-shadow: 0 0 10px black;
  border-radius: 1.5em;
  -webkit-transform: scale(1.05) !important;
          transform: scale(1.05) !important;
}

.Task_areaBanner__1oUdl {
  transition: all 200ms ease-in-out;
  border-radius: 1.5em;
  color: white;
  background: #136a8a; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #136a8a, #267871); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.Task_areaBanner__1oUdl:hover {
  text-decoration: none !important;
  color: white;
  box-shadow: 0 0 10px black;
  border-radius: 1.5em;
  -webkit-transform: scale(1.05) !important;
          transform: scale(1.05) !important;
}

img {
  max-width: 100%;
}

.Task_textCenter__czcS5 {
  text-align: center;
}

.Task_badge__3o4Nr {
  background-color: #555A5F !important;
  color: white !important;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
}

.Task_reviewVideoP__3CfBA {
  margin-top: -6px;
  padding: 10px;
  background-color: #4D5B6B;
  color: #D4D4D4;
  border-radius: 0 0 10px 10px !important;
}
.TaskWidgetBFinish_container__3-KWc {
  margin-top: 56px;
  padding-bottom: 100px;
  background-color: #292F36;
  color: #fff;
}

.TaskWidgetBFinish_UserArea__3W6gS {
  z-index: 999 !important;
}

.TaskWidgetBFinish_primaryButton__mur94 {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef00 !important;
  background-color: #c7ef00 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.TaskWidgetBFinish_primaryButton__mur94:hover, .TaskWidgetBFinish_primaryButton__mur94:active, .TaskWidgetBFinish_primaryButton__mur94.TaskWidgetBFinish_active__2PVGU {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.TaskWidgetBFinish_primaryButtonBlocked__3c3al {
  transition: 0.2s;
  cursor: not-allowed !important;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef0000 !important;
  background-color: #c7ef0060 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.TaskWidgetBFinish_primaryButtonBlocked__3c3al:hover, .TaskWidgetBFinish_primaryButtonBlocked__3c3al:active, .TaskWidgetBFinish_primaryButtonBlocked__3c3al.TaskWidgetBFinish_active__2PVGU {
  background-color: #c7ef0060 !important;
  border: 1px solid #c7ef0000 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.TaskWidgetBFinish_secondaryButton__oQw2- {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.TaskWidgetBFinish_secondaryButton__oQw2-:hover, .TaskWidgetBFinish_secondaryButton__oQw2-:active, .TaskWidgetBFinish_secondaryButton__oQw2-.TaskWidgetBFinish_active__2PVGU {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}


.TaskWidgetBFinish_primaryLink__2i6Ax {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid transparent !important;
  background-color: transparent !important; 
  color: #c7ef00 !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.TaskWidgetBFinish_primaryLink__2i6Ax:hover, .TaskWidgetBFinish_primaryLink__2i6Ax:active, .TaskWidgetBFinish_primaryLink__2i6Ax.TaskWidgetBFinish_active__2PVGU {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.TaskWidgetBFinish_toRight__dKYsS {
  margin-left: auto;
  margin-right: 0;
  display: flex !important;
  text-align: right;
}

.TaskWidgetBFinish_clickableCard__TpWkR {
  color: #292F36 !important;
  transition: all 200ms ease-in-out;
  cursor: pointer;
}


.TaskWidgetBFinish_notClickableCard__2FrHf {
  color: #292F36 !important;
  transition: all 200ms ease-in-out;
  opacity: 0.6;
  cursor: not-allowed;
}


.TaskWidgetBFinish_alwaysGrey__2mA-O, *:hover .TaskWidgetBFinish_alwaysGrey__2mA-O {
  color: #ffffffcc !important;
}

.TaskWidgetBFinish_fitContent__3CyZ_ {
  max-width: -webkit-fit-content !important;
  max-width: -moz-fit-content !important;
  max-width: fit-content !important;
}

/* *:hover a.clickableCard {
  color: initial !important;
  text-decoration: none !important;
} */
/* 
.clickableCard {
  transition: all 200ms ease-in-out;
}

.clickableCard:hover {
  box-shadow: 0 0 10px black;
  border-radius: 1.5em;
  transform: scale(1.05);
}

.clickableCard .buttonIcon {
  transition: all 200ms ease-in-out;
} */
/* 
.clickableCard .buttonIcon:hover {
  color: #c7ef00; 
} */

.TaskWidgetBFinish_toRight__dKYsS button {
  display: flex;
  margin: 0 0 0 auto;
}

.TaskWidgetBFinish_roundButton__1d-W3 {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 30px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  padding: 15px 30px !important;
  flex: 1 1;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 15px;
  text-decoration: underline !important;
}

.TaskWidgetBFinish_roundButton__1d-W3:hover, .TaskWidgetBFinish_roundButton__1d-W3:active, .TaskWidgetBFinish_roundButton__1d-W3.TaskWidgetBFinish_active__2PVGU {
  background-color: #39424d !important;
  border: 1px solid #39424d !important;
}

.TaskWidgetBFinish_outlineSecondaryButton__vSbvU {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 30px !important;
  border: 1px solid #292F36 !important;
  background-color: transparent !important;
  color: #292F36 !important;
  padding: 15px 30px !important;
  text-decoration: underline !important;
  flex: 1 1;
  margin-left: auto;
  margin-right: 0;
}

.TaskWidgetBFinish_outlineSecondaryButton__vSbvU:hover, .TaskWidgetBFinish_outlineSecondaryButton__vSbvU:active, .TaskWidgetBFinish_outlineSecondaryButton__vSbvU.TaskWidgetBFinish_active__2PVGU {
  background-color: #292F36 !important;
  border: 1px solid #292F36 !important;
  color: white !important;
}

.TaskWidgetBFinish_secondaryLink__2LKre {
  cursor: pointer;
  color: #292F36 !important;
  background: none;
  border: none;
  text-decoration: underline;
  padding: 0 !important;
  -webkit-text-decoration-color: #c7ef00;
          text-decoration-color: #c7ef00;
  transition: all 300ms;
}

.TaskWidgetBFinish_secondaryLink__2LKre:hover, .TaskWidgetBFinish_secondaryLink__2LKre:active, .TaskWidgetBFinish_secondaryLink__2LKre.TaskWidgetBFinish_active__2PVGU {
  color: #c7ef00 !important;
  text-decoration: underline !important;
  -webkit-text-decoration-color: #c7ef00 !important;
          text-decoration-color: #c7ef00 !important;
}

.TaskWidgetBFinish_taskIcon__1syou {
  color: white;
  margin-left: auto;
  margin-right: 25px;
  max-width: 100px;
  max-height: 100px;
  width: 100px;
  height: 100px;
  opacity: 0.6;
}

.TaskWidgetBFinish_bgWhite__3vpKU {
  background-color: white;
  color: #292F36;
}

.TaskWidgetBFinish_bgDark__h3BvP {
  background-color: transparent !important;
  border:1px solid rgba(255, 255, 255, 0.2) !important;
}

.TaskWidgetBFinish_bgPink__1QMDk {
  background-color: #FB3874 !important;
  border:1px solid rgba(255, 255, 255, 0.2) !important;
}

.TaskWidgetBFinish_white__1znrX {
  color: white;
}

.TaskWidgetBFinish_borderRadius15__1eFrJ {
  border-radius: 15px !important;
}

.TaskWidgetBFinish_toBottomCenter__3JTSx {
  margin: 0 auto !important;
}

.TaskWidgetBFinish_toCenter__2gooW {
  text-align: center;
}

.TaskWidgetBFinish_questionWidget__8T88Q {
  line-height: 45px;
  display: flex;
  background-color: white;
  padding: 15px 25px;
  margin: 10px 0;
  border-radius: 25px !important;
  border: 0 !important;
  color: #292F36;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
}

.TaskWidgetBFinish_option__2Jnj_ {
  background-color: white;
  border: solid 2px transparent !important;
  color: #292F36;
  box-shadow: 0 0 5px 1px transparent;
  border-radius: 25px !important;
}

.TaskWidgetBFinish_optionActive__LMBlz {
  transition: 500ms;
  background-color: white;
  border: solid 2px #c7ef00 !important;
  color: #292F36;
  box-shadow: 0 0 5px 1px #c7ef007e;
  border-radius: 25px !important;
}

.TaskWidgetBFinish_optionContent__3MQZ3 {
  padding-left: 0 !important;
}

.TaskWidgetBFinish_optionContent__3MQZ3 p {
  margin-bottom: 0;
}

.TaskWidgetBFinish_optionPosition__326VS {
  height: 25px;
  width: 25px;
  padding: 0 6px;
  border: solid 1px #292f366b;
  border-radius: 50%;
}

.TaskWidgetBFinish_optionPositionActive__3_Ekp {
  transition: 500ms;
  height: 25px;
  width: 25px;
  padding: 0 6px;
  border: solid 1px #292f36;
  border-radius: 50%;
  color: white !important;
  background: #292f36 !important;
}

.TaskWidgetBFinish_minWidth__RrJgI {
  max-width: -webkit-min-content !important;
  max-width: min-content !important;
  flex-grow: inherit !important;
}

.TaskWidgetBFinish_centered__Fvarg {
  margin-left: auto;
  margin-right: auto;
  display: flex !important;
}

.TaskWidgetBFinish_modalRigthAnswer__1Pfjf .TaskWidgetBFinish_modalContent__2-HkX {
  background-color: #c7ef00 !important;
}

.TaskWidgetBFinish_completedTaskTitle__1rq0H {
  -webkit-text-decoration: line-through #c7ef00 ;
          text-decoration: line-through #c7ef00 ;
  color: #292f369c;
}

.TaskWidgetBFinish_completedCard__s_g-O {
  opacity: 0.6;
}

.TaskWidgetBFinish_toBeCompleted__3dmMt {
  vertical-align: middle;
  margin: auto;
  max-width: -webkit-fit-content !important;
  max-width: fit-content !important;
  max-width: -moz-fit-content !important;
  padding-left: 25px !important;
}
.GoogleAdsWidget_bgDark__3HdyR {
  background-color: transparent !important;
  border: solid 1px #525252 !important;
  padding: 0 !important;
  display: flex !important;
  margin: auto;
  max-width: 320px !important;
  min-height: 280px !important;
  border-radius: 25px !important;
}

.ScheduleB_container__YDy4a {
  /* margin-top: 56px; */
  /* padding-bottom: 100px; */
  background-color: #292F36;
  color: #fff;
}

.ScheduleB_UserArea__1APbR {
  z-index: 999 !important;
}

.ScheduleB_primaryDefaultButton__6YO9h {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef00 !important;
  background-color: #c7ef00 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.ScheduleB_primaryDefaultButton__6YO9h:hover, .ScheduleB_primaryDefaultButton__6YO9h:active, .ScheduleB_primaryDefaultButton__6YO9h.ScheduleB_active__1DwBx {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.ScheduleB_secondaryDefaultButton__386yn {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.ScheduleB_secondaryDefaultButton__386yn:hover, .ScheduleB_secondaryDefaultButton__386yn:active, .ScheduleB_secondaryDefaultButton__386yn.ScheduleB_active__1DwBx {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.ScheduleB_primaryLink__3Z2Gp {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid transparent !important;
  background-color: transparent !important; 
  color: #c7ef00 !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.ScheduleB_primaryLink__3Z2Gp:hover, .ScheduleB_primaryLink__3Z2Gp:active, .ScheduleB_primaryLink__3Z2Gp.ScheduleB_active__1DwBx {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.ScheduleB_secondaryLink__btWgi {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  color: white !important;
  text-decoration: underline !important;
}

.ScheduleB_secondaryLink__btWgi:hover, .ScheduleB_secondaryLink__btWgi:active, .ScheduleB_secondaryLink__btWgi.ScheduleB_active__1DwBx {
  color: #c7ef00 !important;
}

.ScheduleB_roundDefaultButton__Q7xfE {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50% !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  padding: 10px 15px !important;
  flex: 1 1;
  margin-left: auto;
  margin-right: 0;
  max-width: 46px;
}

.ScheduleB_roundDefaultButton__Q7xfE:hover, .ScheduleB_roundDefaultButton__Q7xfE:active, .ScheduleB_roundDefaultButton__Q7xfE.ScheduleB_active__1DwBx {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  color: #292F36 !important;
}

.ScheduleB_secondaryLink__btWgi {
  cursor: pointer;
  color: #292F36;
  text-decoration: underline;
  -webkit-text-decoration-color: #c7ef00;
          text-decoration-color: #c7ef00;
}

.ScheduleB_secondaryLink__btWgi:hover, .ScheduleB_secondaryLink__btWgi:active, .ScheduleB_secondaryLink__btWgi.ScheduleB_active__1DwBx {
  color: #c7ef00;
  text-decoration: underline;
  -webkit-text-decoration-color: #c7ef00;
          text-decoration-color: #c7ef00;
}

.ScheduleB_bgWhite__1OkoQ {
  background-color: white;
  border: 0 !important;
  color: #292F36;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
}

.ScheduleB_borderRadius25__10YwV {
  border-radius: 25px !important;
}

.ScheduleB_toBottomCenter__qPIqU {
  margin: 0 auto !important;
}

.ScheduleB_toCenter__1PQMw {
  text-align: center;
}

.ScheduleB_toRight__6nzXH {
  text-align: right;
}

.ScheduleB_questionWidget__3Yc9d {
  line-height: 45px;
  display: flex;
  background-color: white;
  padding: 15px 25px;
  margin: 10px 0;
  border-radius: 25px !important;
  border: 0 !important;
  color: #292F36;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
}

.ScheduleB_marginActive__3YpHB {
  border-left: solid 5px #fff;
  margin-left: 8px;
}

.ScheduleB_circle__WuyXs {
  z-index: 1;
  display:block;
  background: #fff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.ScheduleB_activeCircle__2Mtvz {
  z-index: 3;
  position: relative;
  top: 6px;
  left: -26px;
  background: #c7ef00;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.ScheduleB_secondaryCircle__1wN8r {
  z-index: 2;
  position: relative;
  top: 4px;
  left: -16px;
  background: #292F36;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.ScheduleB_activeTime__3oqlI {
  margin-left: -8px;
}

.ScheduleB_unMargin__27XF6 {
  margin-top: 2rem;
}
.ScheduleB_primary__2gPLE {
  color: #c7ef00;
  transition: all 500ms;
}

.ScheduleB_primary__2gPLE:hover {
  color: #a8c710;
}

.ScheduleB_textCenter__2QmII {
  text-align: center;
}

.ScheduleB_weekDay__2Sfjc {
  color: white !important;
  transition: all 500ms;
}

.ScheduleB_weekDay__2Sfjc:hover {
  color: #c7ef00 !important;
}

.ScheduleB_circle__WuyXs {
  display: flex;
  margin: 0 auto;
  background-color: #c7ef00;
  height: 6px;
  min-width: 6px;
  width: 6px;
}

.ScheduleB_darkDefaultButton__2CDKc {
  border-radius: 12px !important;
  border: solid 1px #ffffff60 !important;
  color: white !important;
  transition: all 500ms;
}

.ScheduleB_darkDefaultButton__2CDKc:hover {
  background-color: #ffffff20 !important;
  color: #c7ef00 !important;
}

@media (max-width: 768px) {
  .ScheduleB_calendarDay__ohgzt, .ScheduleB_calendarTodayDay__1fBMA {
    height: 130px;
  }
}

@media (min-width: 768px) {
  .ScheduleB_calendarDay__ohgzt, .ScheduleB_calendarTodayDay__1fBMA {
    height: 200px;
  }
}
.ScheduleB_calendarDay__ohgzt {
  color: #ffffff80 !important;
  cursor: pointer;
  transition: all 500ms;
}

.ScheduleB_calendarDay__ohgzt:hover, .ScheduleB_calendarTodayDay__1fBMA:hover {
  background-color: #c7ef0007 !important;
}

.ScheduleB_calendarTodayDay__1fBMA {
  cursor: pointer;
  transition: all 500ms;
}

.ScheduleB_calendarTodayDay__1fBMA div.ScheduleB_dayNumber__1eTpS {
  width: 24px;
  height: 24px;
  margin: 0 auto;
  text-align: center;
  color: #292F36;
  /* font-size: bolder; */
  background-color: #c7ef00;
  border-radius: 50%;
  padding: 0 2px;
}

.ScheduleB_calendarDay__ohgzt:hover {
  color: #c7ef00 !important;
}

.ScheduleB_desactivate__94QpT {
  opacity: 0.3;
}

.ScheduleB_today__1lNSw {
  margin-bottom: 10px;
  background-color: #c7ef00;
  width: 24px;
  height: 24px;
  font-size: 16px;
  border-radius: 50%;
  color: #292f36;
  font-weight: bolder;
  text-align: center;
  margin: 0 auto;
}

.ScheduleB_otherDays__2kdlN {
  margin-bottom: 10px;
  width: 24px;
  height: 24px;
}

.ScheduleB_weekViewTR__1LuJV {
  display: flex;
}

@media (min-width: 768px) {
  .ScheduleB_dayWeekViewTD__3yJKU {
    text-align: center;
    padding: 0;
    min-width: 66px;
    cursor: pointer;
    transition: all 500ms;
  }
}

@media (max-width: 768px) {
  .ScheduleB_dayWeekViewTD__3yJKU {
    text-align: center;
    padding: 0;
    min-width: 41px;
    cursor: pointer;
    transition: all 500ms;
  }
}

.ScheduleB_emptyWeekDay__1HsaC {
  display: flex;
  width: 100%;
  height: 75%;
  padding: 5px;
}

.ScheduleB_emptyWeekDayBorder__2yWI3 {
  display: flex;
  width: 100%;
  height: 100%;
  border: solid 1px rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}

.ScheduleB_dayWeekViewTD__3yJKU:hover {
  background-color: #c7ef0007;
  border-radius: 16px;
}

.ScheduleB_toRightDefaultButton__3zcLT {
  position: absolute;
  right: -25px;
  top: 28px;
  color: white !important;
}

.ScheduleB_toLeftDefaultButton__2-kPM {
  position: absolute;
  left: -25px;
  top: 28px;
  color: white !important;
}

.ScheduleB_firstDefaultButtonInGroup__QtaOY.ScheduleB_activeDefaultButtonInGroup___lQ1H, .ScheduleB_middleDefaultButtonInGroup__3OE9U.ScheduleB_activeDefaultButtonInGroup___lQ1H, .ScheduleB_lastDefaultButtonInGroup__OEWba.ScheduleB_activeDefaultButtonInGroup___lQ1H {
  background-color: rgba(255, 255, 255, 0.2) !important;
  border-color: rgba(255, 255, 255, 0.1) !important;
}

.ScheduleB_firstDefaultButtonInGroup__QtaOY {
  height: -webkit-max-content !important;
  height: max-content !important;
  background-color: rgba(0, 0, 0, 0.05) !important;
  border-color: rgba(255, 255, 255, 0.1) !important;
  padding: 5px 10px !important;
  font-size: normal !important;
  border-top-left-radius: 16px !important;
  border-bottom-left-radius: 16px !important;
}

.ScheduleB_middleDefaultButtonInGroup__3OE9U {
  height: -webkit-max-content !important;
  height: max-content !important;
  background-color: rgba(0, 0, 0, 0.05) !important;
  border-color: rgba(255, 255, 255, 0.1) !important;
  padding: 5px 10px !important;
  font-size: normal !important;
}

.ScheduleB_lastDefaultButtonInGroup__OEWba {
  height: -webkit-max-content !important;
  height: max-content !important;
  background-color: rgba(0, 0, 0, 0.05) !important;
  border-color: rgba(255, 255, 255, 0.1) !important;
  padding: 5px 10px !important;
  font-size: normal !important;
  border-top-right-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
}

.ScheduleB_monthViewTD__2d8tk {
  /* display: flex; */
  align-items:initial;
  text-align: center;
  border: solid 1px rgba(255,255,255,0.1);
}

/* .monthViewTable {

} */

.ScheduleB_monthViewTable__2bGwc {
  width: 100%;
  table-layout: fixed;
}

.ScheduleB_monthViewTable__2bGwc, .ScheduleB_monthViewTable__2bGwc th {
  /* width: 100%; */
  border: solid 1px rgba(255,255,255,0.1);
}

.ScheduleB_pointingToViews__3Y6iZ {
  position: absolute;
}


.ScheduleB_pointingToBlocks__1LNge {
  position: absolute;
  
}

@media (min-width: 768px) {
  .ScheduleB_pointingToViews__3Y6iZ {
    right: 10px;
    top: -250px;
  }
  .ScheduleB_pointingToBlocks__1LNge {
    left: 230px;
    top: 200px;
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
  }

  .ScheduleB_toNextTaskDiv__20LJ4 {
    bottom: 20px;
  }
}

@media (max-width: 768px) {
  .ScheduleB_pointingToViews__3Y6iZ {
    right: -10px;
    top: -220px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1) scaleY(0.8);
  }
  .ScheduleB_pointingToBlocks__1LNge {
    left: 50px;
    top: 200px;
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
  }

  .ScheduleB_toNextTaskDiv__20LJ4 {
    bottom: 66px;
  }
}

.ScheduleB_toNextTaskDiv__20LJ4 {
  z-index: 999;
  width: 100%;
  position:fixed;
}

.ScheduleB_toNextTaskDiv__20LJ4 defaultbutton {
  display: flex;
  margin: 0 auto;
}

.ScheduleB_darkCard__3scjJ {
  background-color: transparent !important;
  border: solid 1px rgba(255, 255, 255, 0.1) !important;
  border-radius: 20px !important;
}
.TaskWidgetB_container__1cuqt {
  margin-top: 56px;
  padding-bottom: 100px;
  background-color: #292F36;
  color: #fff;
}

.TaskWidgetB_UserArea__Z-Ddt {
  z-index: 999 !important;
}

.TaskWidgetB_primaryButton__2FH3B {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef00 !important;
  background-color: #c7ef00 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.TaskWidgetB_primaryButton__2FH3B:hover, .TaskWidgetB_primaryButton__2FH3B:active, .TaskWidgetB_primaryButton__2FH3B.TaskWidgetB_active__18mAn {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.TaskWidgetB_primaryButtonBlocked__1XEPi {
  transition: 0.2s;
  cursor: not-allowed !important;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef0000 !important;
  background-color: #c7ef0060 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.TaskWidgetB_primaryButtonBlocked__1XEPi:hover, .TaskWidgetB_primaryButtonBlocked__1XEPi:active, .TaskWidgetB_primaryButtonBlocked__1XEPi.TaskWidgetB_active__18mAn {
  background-color: #c7ef0060 !important;
  border: 1px solid #c7ef0000 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.TaskWidgetB_secondaryButton__3oaK5 {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.TaskWidgetB_secondaryButton__3oaK5:hover, .TaskWidgetB_secondaryButton__3oaK5:active, .TaskWidgetB_secondaryButton__3oaK5.TaskWidgetB_active__18mAn {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}


.TaskWidgetB_primaryLink__3t6O6 {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid transparent !important;
  background-color: transparent !important; 
  color: #c7ef00 !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.TaskWidgetB_primaryLink__3t6O6:hover, .TaskWidgetB_primaryLink__3t6O6:active, .TaskWidgetB_primaryLink__3t6O6.TaskWidgetB_active__18mAn {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.TaskWidgetB_toRight__1mrrP {
  margin-left: auto;
  margin-right: 0;
  display: flex !important;
  text-align: right;
}

.TaskWidgetB_clickableCard__1_2WV {
  color: #292F36 !important;
  transition: all 200ms ease-in-out;
}

.TaskWidgetB_clickableCard__1_2WV:hover {
  /* color: #c7ef00 !important; */
  /* border: 1px solid #c7ef00 !important; */
  opacity: 0.8;
}

.TaskWidgetB_notClickableCard__1oChR {
  color: #292F36 !important;
  transition: all 200ms ease-in-out;
  opacity: 0.6;
  cursor: not-allowed;
}
/* 
.notClickableCard:hover {
  color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
} */

.TaskWidgetB_alwaysGrey__1NhCf, *:hover .TaskWidgetB_alwaysGrey__1NhCf {
  color: #ffffffcc !important;
}

.TaskWidgetB_fitContent__3VMYH {
  max-width: -webkit-fit-content !important;
  max-width: -moz-fit-content !important;
  max-width: fit-content !important;
}

/* *:hover a.clickableCard {
  color: initial !important;
  text-decoration: none !important;
} */
/* 
.clickableCard {
  transition: all 200ms ease-in-out;
}

.clickableCard:hover {
  box-shadow: 0 0 10px black;
  border-radius: 1.5em;
  transform: scale(1.05);
}

.clickableCard .buttonIcon {
  transition: all 200ms ease-in-out;
} */
/* 
.clickableCard .buttonIcon:hover {
  color: #c7ef00; 
} */

.TaskWidgetB_toRight__1mrrP button {
  display: flex;
  margin: 0 0 0 auto;
}

.TaskWidgetB_roundButton__2jn5f {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 30px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  padding: 15px 30px !important;
  flex: 1 1;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 15px;
  text-decoration: underline !important;
}

.TaskWidgetB_roundButton__2jn5f:hover, .TaskWidgetB_roundButton__2jn5f:active, .TaskWidgetB_roundButton__2jn5f.TaskWidgetB_active__18mAn {
  background-color: #39424d !important;
  border: 1px solid #39424d !important;
}

.TaskWidgetB_outlineSecondaryButton__1aDjB {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 30px !important;
  border: 1px solid #292F36 !important;
  background-color: transparent !important;
  color: #292F36 !important;
  padding: 15px 30px !important;
  text-decoration: underline !important;
  flex: 1 1;
  margin-left: auto;
  margin-right: 0;
}

.TaskWidgetB_outlineSecondaryButton__1aDjB:hover, .TaskWidgetB_outlineSecondaryButton__1aDjB:active, .TaskWidgetB_outlineSecondaryButton__1aDjB.TaskWidgetB_active__18mAn {
  background-color: #292F36 !important;
  border: 1px solid #292F36 !important;
  color: white !important;
}

.TaskWidgetB_secondaryLink__3QPhE {
  cursor: pointer;
  color: #292F36 !important;
  background: none;
  border: none;
  text-decoration: underline;
  padding: 0 !important;
  -webkit-text-decoration-color: #c7ef00;
          text-decoration-color: #c7ef00;
  transition: all 300ms;
}

.TaskWidgetB_secondaryLink__3QPhE:hover, .TaskWidgetB_secondaryLink__3QPhE:active, .TaskWidgetB_secondaryLink__3QPhE.TaskWidgetB_active__18mAn {
  color: #c7ef00 !important;
  text-decoration: underline !important;
  -webkit-text-decoration-color: #c7ef00 !important;
          text-decoration-color: #c7ef00 !important;
}

.TaskWidgetB_taskIcon__1iS4u {
  color: white;
  margin-left: auto;
  margin-right: 25px;
  max-width: 100px;
  max-height: 100px;
  width: 100px;
  height: 100px;
  opacity: 0.6;
}

.TaskWidgetB_bgWhite__-rtTP {
  background-color: white;
  color: #292F36;
}

.TaskWidgetB_bgDark__Jsc0_ {
  background-color: transparent !important;
  border:1px solid rgba(255, 255, 255, 0.2) !important;
}

.TaskWidgetB_bgPink__2YnEb {
  background-color: #FB3874 !important;
  border:1px solid rgba(255, 255, 255, 0.2) !important;
}

.TaskWidgetB_white__JR76h {
  color: white;
}

.TaskWidgetB_borderRadius15__3f-jd {
  border-radius: 15px !important;
}

.TaskWidgetB_toBottomCenter__1hFNY {
  margin: 0 auto !important;
}

.TaskWidgetB_toCenter__2ayVf {
  text-align: center;
}

.TaskWidgetB_questionWidget__4o3CD {
  line-height: 45px;
  display: flex;
  background-color: white;
  padding: 15px 25px;
  margin: 10px 0;
  border-radius: 25px !important;
  border: 0 !important;
  color: #292F36;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
}

.TaskWidgetB_option__19LfU {
  background-color: white;
  border: solid 2px transparent !important;
  color: #292F36;
  box-shadow: 0 0 5px 1px transparent;
  border-radius: 25px !important;
}

.TaskWidgetB_optionActive__2lKds {
  transition: 500ms;
  background-color: white;
  border: solid 2px #c7ef00 !important;
  color: #292F36;
  box-shadow: 0 0 5px 1px #c7ef007e;
  border-radius: 25px !important;
}

.TaskWidgetB_optionContent__1YMUN {
  padding-left: 0 !important;
}

.TaskWidgetB_optionContent__1YMUN p {
  margin-bottom: 0;
}

.TaskWidgetB_optionPosition__2c6M7 {
  height: 25px;
  width: 25px;
  padding: 0 6px;
  border: solid 1px #292f366b;
  border-radius: 50%;
}

.TaskWidgetB_optionPositionActive__25ROA {
  transition: 500ms;
  height: 25px;
  width: 25px;
  padding: 0 6px;
  border: solid 1px #292f36;
  border-radius: 50%;
  color: white !important;
  background: #292f36 !important;
}

.TaskWidgetB_minWidth__poyYT {
  max-width: -webkit-min-content !important;
  max-width: min-content !important;
  flex-grow: inherit !important;
}

.TaskWidgetB_centered__2F_Yg {
  margin-left: auto;
  margin-right: auto;
  display: flex !important;
}

.TaskWidgetB_modalRigthAnswer__2poOW .TaskWidgetB_modalContent__1WKM1 {
  background-color: #c7ef00 !important;
}

.TaskWidgetB_completedTaskTitle__2c6EV {
  -webkit-text-decoration: line-through #c7ef00 ;
          text-decoration: line-through #c7ef00 ;
  color: #292f369c;
}

.TaskWidgetB_completedCard__2w-xc {
  opacity: 0.6;
}
.TaskNanoWidget_container__1OloG {
  margin-top: 56px;
  padding-bottom: 100px;
  background-color: #292F36;
  color: #fff;
}

.TaskNanoWidget_UserArea___2zwi {
  z-index: 999 !important;
}

.TaskNanoWidget_primaryButton__OWG74 {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef00 !important;
  background-color: #c7ef00 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.TaskNanoWidget_primaryButton__OWG74:hover, .TaskNanoWidget_primaryButton__OWG74:active, .TaskNanoWidget_primaryButton__OWG74.TaskNanoWidget_active__1GW4W {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.TaskNanoWidget_primaryButtonBlocked__1FSP- {
  transition: 0.2s;
  cursor: not-allowed !important;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef0000 !important;
  background-color: #c7ef0060 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.TaskNanoWidget_primaryButtonBlocked__1FSP-:hover, .TaskNanoWidget_primaryButtonBlocked__1FSP-:active, .TaskNanoWidget_primaryButtonBlocked__1FSP-.TaskNanoWidget_active__1GW4W {
  background-color: #c7ef0060 !important;
  border: 1px solid #c7ef0000 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.TaskNanoWidget_secondaryButton__3-4VR {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.TaskNanoWidget_secondaryButton__3-4VR:hover, .TaskNanoWidget_secondaryButton__3-4VR:active, .TaskNanoWidget_secondaryButton__3-4VR.TaskNanoWidget_active__1GW4W {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}


.TaskNanoWidget_primaryLink__1aofi {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid transparent !important;
  background-color: transparent !important; 
  color: #c7ef00 !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.TaskNanoWidget_primaryLink__1aofi:hover, .TaskNanoWidget_primaryLink__1aofi:active, .TaskNanoWidget_primaryLink__1aofi.TaskNanoWidget_active__1GW4W {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.TaskNanoWidget_toRight__32e2p {
  margin-left: auto;
  margin-right: 0;
  display: flex !important;
  text-align: right;
}

.TaskNanoWidget_clickableCard__3AcIt {
  color: #292F36 !important;
  transition: all 200ms ease-in-out;
}

.TaskNanoWidget_clickableCard__3AcIt:hover {
  /* color: #c7ef00 !important; */
  /* border: 1px solid #c7ef00 !important; */
  opacity: 0.8;
}

.TaskNanoWidget_notClickableCard__3nclD {
  color: #292F36 !important;
  transition: all 200ms ease-in-out;
  opacity: 0.6;
  cursor: not-allowed;
}
/* 
.notClickableCard:hover {
  color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
} */

.TaskNanoWidget_alwaysGrey__23J81, *:hover .TaskNanoWidget_alwaysGrey__23J81 {
  color: #ffffffcc !important;
}

.TaskNanoWidget_fitContent__3tpUw {
  max-width: -webkit-fit-content !important;
  max-width: -moz-fit-content !important;
  max-width: fit-content !important;
}

/* *:hover a.clickableCard {
  color: initial !important;
  text-decoration: none !important;
} */
/* 
.clickableCard {
  transition: all 200ms ease-in-out;
}

.clickableCard:hover {
  box-shadow: 0 0 10px black;
  border-radius: 1.5em;
  transform: scale(1.05);
}

.clickableCard .buttonIcon {
  transition: all 200ms ease-in-out;
} */
/* 
.clickableCard .buttonIcon:hover {
  color: #c7ef00; 
} */

.TaskNanoWidget_toRight__32e2p button {
  display: flex;
  margin: 0 0 0 auto;
}

.TaskNanoWidget_roundButton__ECE7T {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 30px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  padding: 15px 30px !important;
  flex: 1 1;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 15px;
  text-decoration: underline !important;
}

.TaskNanoWidget_roundButton__ECE7T:hover, .TaskNanoWidget_roundButton__ECE7T:active, .TaskNanoWidget_roundButton__ECE7T.TaskNanoWidget_active__1GW4W {
  background-color: #39424d !important;
  border: 1px solid #39424d !important;
}

.TaskNanoWidget_outlineSecondaryButton__3uyEL {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 30px !important;
  border: 1px solid #292F36 !important;
  background-color: transparent !important;
  color: #292F36 !important;
  padding: 15px 30px !important;
  text-decoration: underline !important;
  flex: 1 1;
  margin-left: auto;
  margin-right: 0;
}

.TaskNanoWidget_outlineSecondaryButton__3uyEL:hover, .TaskNanoWidget_outlineSecondaryButton__3uyEL:active, .TaskNanoWidget_outlineSecondaryButton__3uyEL.TaskNanoWidget_active__1GW4W {
  background-color: #292F36 !important;
  border: 1px solid #292F36 !important;
  color: white !important;
}

.TaskNanoWidget_secondaryLink__3Hknh {
  cursor: pointer;
  color: #292F36 !important;
  background: none;
  border: none;
  text-decoration: underline;
  padding: 0 !important;
  -webkit-text-decoration-color: #c7ef00;
          text-decoration-color: #c7ef00;
  transition: all 300ms;
}

.TaskNanoWidget_secondaryLink__3Hknh:hover, .TaskNanoWidget_secondaryLink__3Hknh:active, .TaskNanoWidget_secondaryLink__3Hknh.TaskNanoWidget_active__1GW4W {
  color: #c7ef00 !important;
  text-decoration: underline !important;
  -webkit-text-decoration-color: #c7ef00 !important;
          text-decoration-color: #c7ef00 !important;
}

.TaskNanoWidget_taskIcon__4wvap {
  color: white;
  margin-left: auto;
  margin-right: 25px;
  max-width: 100px;
  max-height: 100px;
  width: 100px;
  height: 100px;
  opacity: 0.6;
}

.TaskNanoWidget_bgWhite__2lczH {
  background-color: white;
  color: #292F36;
}

.TaskNanoWidget_bgDark__3Uv1M {
  background-color: transparent !important;
  border:1px solid rgba(255, 255, 255, 0.2) !important;
}

.TaskNanoWidget_bgPink__13hyD {
  background-color: #FB3874 !important;
  border:1px solid rgba(255, 255, 255, 0.2) !important;
}

.TaskNanoWidget_white__3geOL {
  color: white;
}

.TaskNanoWidget_borderRadius15__3_c4k {
  border-radius: 15px !important;
}

.TaskNanoWidget_toBottomCenter__2jpAW {
  margin: 0 auto !important;
}

.TaskNanoWidget_toCenter__TL4ob {
  text-align: center;
}

.TaskNanoWidget_questionWidget__1H1WY {
  line-height: 45px;
  display: flex;
  background-color: white;
  padding: 15px 25px;
  margin: 10px 0;
  border-radius: 25px !important;
  border: 0 !important;
  color: #292F36;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
}

.TaskNanoWidget_option__3e5hi {
  background-color: white;
  border: solid 2px transparent !important;
  color: #292F36;
  box-shadow: 0 0 5px 1px transparent;
  border-radius: 25px !important;
}

.TaskNanoWidget_optionActive__OtxpO {
  transition: 500ms;
  background-color: white;
  border: solid 2px #c7ef00 !important;
  color: #292F36;
  box-shadow: 0 0 5px 1px #c7ef007e;
  border-radius: 25px !important;
}

.TaskNanoWidget_optionContent__2Qil6 {
  padding-left: 0 !important;
}

.TaskNanoWidget_optionContent__2Qil6 p {
  margin-bottom: 0;
}

.TaskNanoWidget_optionPosition__243ZZ {
  height: 25px;
  width: 25px;
  padding: 0 6px;
  border: solid 1px #292f366b;
  border-radius: 50%;
}

.TaskNanoWidget_optionPositionActive__2yNSy {
  transition: 500ms;
  height: 25px;
  width: 25px;
  padding: 0 6px;
  border: solid 1px #292f36;
  border-radius: 50%;
  color: white !important;
  background: #292f36 !important;
}

.TaskNanoWidget_minWidth__3WdHr {
  max-width: -webkit-min-content !important;
  max-width: min-content !important;
  flex-grow: inherit !important;
}

.TaskNanoWidget_centered__2Q_Jd {
  margin-left: auto;
  margin-right: auto;
  display: flex !important;
}

.TaskNanoWidget_modalRigthAnswer__rg66O .TaskNanoWidget_modalContent__XLeBL {
  background-color: #c7ef00 !important;
}

.TaskNanoWidget_completedTaskTitle__1HYeh {
  -webkit-text-decoration: line-through #c7ef00 ;
          text-decoration: line-through #c7ef00 ;
  color: #292f369c;
}

.TaskNanoWidget_completedCard__ESrmn {
  opacity: 0.6;
}

.TaskNanoWidget_nanoTask__r0-bx {
  width: calc(100vw/8);
  font-weight: bold;
  color: #292F36;
  /* padding-left: 15px !important; */
  text-align: left;
  word-wrap: break-word;
}

@media (min-width: 768px) {
  .TaskNanoWidget_nanoTask__r0-bx {
    border-radius: 10px;
    margin: 5px 2px;
    padding: 0px 0px 0px 1px;
    max-width: 62px;
    font-size: smaller;
    min-height: 64px;
  }

  .TaskNanoWidget_nanoCol__3fkkG {
    padding: 3px 0 3px 10px !important;
  }

  .TaskNanoWidget_subjectTitle__1qSap {
    font-size: 16px;
    font-weight: bolder;
  }

  .TaskNanoWidget_taskTitle__2mSEZ {
    margin-top: -2px;
    display: flex;
    font-size: 12px;
    font-weight: bold;
    word-break: break-all;
  }

  .TaskNanoWidget_checkSign__3hlqZ {
    -webkit-transform: translate(0%,-175%);
            transform: translate(0%,-175%);
  }
}

@media (max-width: 768px) {
  .TaskNanoWidget_nanoTask__r0-bx {
    border-radius: 10px;
    margin: 5px 2px;
    padding: 0px 0px 0px 1px;
    max-width: calc(100vw/6);
    font-size: smaller;
    min-height: 45px;
  }

  .TaskNanoWidget_nanoCol__3fkkG {
    padding: 2px 0 2px 5px !important;
  }

  .TaskNanoWidget_subjectTitle__1qSap {
    font-size: 12px;
    font-weight: bolder;
  }

  .TaskNanoWidget_taskTitle__2mSEZ {
    margin-top: -2px;
    display: flex;
    font-size: 8px;
    font-weight: bold;
    word-break: break-all;
  }

  .TaskNanoWidget_checkSign__3hlqZ {
    -webkit-transform: translate(0%,-140%);
            transform: translate(0%,-140%);
  }
}

.TaskNanoWidget_completedNanoTask__1lb8z {
  -webkit-filter: brightness(50%);
          filter: brightness(50%);
}

.TaskNanoWidget_checkSignDiv__12CCR {
  position: relative;
  height: 0;
  top: 0;
  margin-bottom: -5px;
}

.TaskNanoWidget_checkSign__3hlqZ {
  /* position: absolute; */
  top: 50%; left: 50%;
  /* width: 70px; height: 70px; */
  
  z-index: 1;
}

.TaskPicoWidget_container__3fVch {
  margin-top: 56px;
  padding-bottom: 100px;
  background-color: #292F36;
  color: #fff;
}

.TaskPicoWidget_UserArea__2KcH- {
  z-index: 999 !important;
}

.TaskPicoWidget_primaryButton__258pY {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef00 !important;
  background-color: #c7ef00 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.TaskPicoWidget_primaryButton__258pY:hover, .TaskPicoWidget_primaryButton__258pY:active, .TaskPicoWidget_primaryButton__258pY.TaskPicoWidget_active__2GGRv {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.TaskPicoWidget_primaryButtonBlocked__JbIYW {
  transition: 0.2s;
  cursor: not-allowed !important;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef0000 !important;
  background-color: #c7ef0060 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.TaskPicoWidget_primaryButtonBlocked__JbIYW:hover, .TaskPicoWidget_primaryButtonBlocked__JbIYW:active, .TaskPicoWidget_primaryButtonBlocked__JbIYW.TaskPicoWidget_active__2GGRv {
  background-color: #c7ef0060 !important;
  border: 1px solid #c7ef0000 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.TaskPicoWidget_secondaryButton__d0Lub {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.TaskPicoWidget_secondaryButton__d0Lub:hover, .TaskPicoWidget_secondaryButton__d0Lub:active, .TaskPicoWidget_secondaryButton__d0Lub.TaskPicoWidget_active__2GGRv {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}


.TaskPicoWidget_primaryLink__rdL93 {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid transparent !important;
  background-color: transparent !important; 
  color: #c7ef00 !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.TaskPicoWidget_primaryLink__rdL93:hover, .TaskPicoWidget_primaryLink__rdL93:active, .TaskPicoWidget_primaryLink__rdL93.TaskPicoWidget_active__2GGRv {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.TaskPicoWidget_toRight__3u_Ix {
  margin-left: auto;
  margin-right: 0;
  display: flex !important;
  text-align: right;
}

.TaskPicoWidget_clickableCard__2NEZg {
  color: #292F36 !important;
  transition: all 200ms ease-in-out;
}

.TaskPicoWidget_clickableCard__2NEZg:hover {
  /* color: #c7ef00 !important; */
  /* border: 1px solid #c7ef00 !important; */
  opacity: 0.8;
}

.TaskPicoWidget_notClickableCard__hWujU {
  color: #292F36 !important;
  transition: all 200ms ease-in-out;
  opacity: 0.6;
  cursor: not-allowed;
}
/* 
.notClickableCard:hover {
  color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
} */

.TaskPicoWidget_alwaysGrey__183kO, *:hover .TaskPicoWidget_alwaysGrey__183kO {
  color: #ffffffcc !important;
}

.TaskPicoWidget_fitContent__2-7C4 {
  max-width: -webkit-fit-content !important;
  max-width: -moz-fit-content !important;
  max-width: fit-content !important;
}

/* *:hover a.clickableCard {
  color: initial !important;
  text-decoration: none !important;
} */
/* 
.clickableCard {
  transition: all 200ms ease-in-out;
}

.clickableCard:hover {
  box-shadow: 0 0 10px black;
  border-radius: 1.5em;
  transform: scale(1.05);
}

.clickableCard .buttonIcon {
  transition: all 200ms ease-in-out;
} */
/* 
.clickableCard .buttonIcon:hover {
  color: #c7ef00; 
} */

.TaskPicoWidget_toRight__3u_Ix button {
  display: flex;
  margin: 0 0 0 auto;
}

.TaskPicoWidget_roundButton__MXMLB {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 30px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  padding: 15px 30px !important;
  flex: 1 1;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 15px;
  text-decoration: underline !important;
}

.TaskPicoWidget_roundButton__MXMLB:hover, .TaskPicoWidget_roundButton__MXMLB:active, .TaskPicoWidget_roundButton__MXMLB.TaskPicoWidget_active__2GGRv {
  background-color: #39424d !important;
  border: 1px solid #39424d !important;
}

.TaskPicoWidget_outlineSecondaryButton__1Vd_z {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 30px !important;
  border: 1px solid #292F36 !important;
  background-color: transparent !important;
  color: #292F36 !important;
  padding: 15px 30px !important;
  text-decoration: underline !important;
  flex: 1 1;
  margin-left: auto;
  margin-right: 0;
}

.TaskPicoWidget_outlineSecondaryButton__1Vd_z:hover, .TaskPicoWidget_outlineSecondaryButton__1Vd_z:active, .TaskPicoWidget_outlineSecondaryButton__1Vd_z.TaskPicoWidget_active__2GGRv {
  background-color: #292F36 !important;
  border: 1px solid #292F36 !important;
  color: white !important;
}

.TaskPicoWidget_secondaryLink__3Omg5 {
  cursor: pointer;
  color: #292F36 !important;
  background: none;
  border: none;
  text-decoration: underline;
  padding: 0 !important;
  -webkit-text-decoration-color: #c7ef00;
          text-decoration-color: #c7ef00;
  transition: all 300ms;
}

.TaskPicoWidget_secondaryLink__3Omg5:hover, .TaskPicoWidget_secondaryLink__3Omg5:active, .TaskPicoWidget_secondaryLink__3Omg5.TaskPicoWidget_active__2GGRv {
  color: #c7ef00 !important;
  text-decoration: underline !important;
  -webkit-text-decoration-color: #c7ef00 !important;
          text-decoration-color: #c7ef00 !important;
}

.TaskPicoWidget_taskIcon__3nBp_ {
  color: white;
  margin-left: auto;
  margin-right: 25px;
  max-width: 100px;
  max-height: 100px;
  width: 100px;
  height: 100px;
  opacity: 0.6;
}

.TaskPicoWidget_bgWhite__pbUZI {
  background-color: white;
  color: #292F36;
}

.TaskPicoWidget_bgDark__1l8m_ {
  background-color: transparent !important;
  border:1px solid rgba(255, 255, 255, 0.2) !important;
}

.TaskPicoWidget_bgPink__3idU1 {
  background-color: #FB3874 !important;
  border:1px solid rgba(255, 255, 255, 0.2) !important;
}

.TaskPicoWidget_white__2SoTn {
  color: white;
}

.TaskPicoWidget_borderRadius15__37XEy {
  border-radius: 15px !important;
}

.TaskPicoWidget_toBottomCenter__BAYqB {
  margin: 0 auto !important;
}

.TaskPicoWidget_toCenter__3A8ZY {
  text-align: center;
}

.TaskPicoWidget_questionWidget__3hNWm {
  line-height: 45px;
  display: flex;
  background-color: white;
  padding: 15px 25px;
  margin: 10px 0;
  border-radius: 25px !important;
  border: 0 !important;
  color: #292F36;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
}

.TaskPicoWidget_option__16J0B {
  background-color: white;
  border: solid 2px transparent !important;
  color: #292F36;
  box-shadow: 0 0 5px 1px transparent;
  border-radius: 25px !important;
}

.TaskPicoWidget_optionActive__1TxRc {
  transition: 500ms;
  background-color: white;
  border: solid 2px #c7ef00 !important;
  color: #292F36;
  box-shadow: 0 0 5px 1px #c7ef007e;
  border-radius: 25px !important;
}

.TaskPicoWidget_optionContent__v2xsu {
  padding-left: 0 !important;
}

.TaskPicoWidget_optionContent__v2xsu p {
  margin-bottom: 0;
}

.TaskPicoWidget_optionPosition__1__QL {
  height: 25px;
  width: 25px;
  padding: 0 6px;
  border: solid 1px #292f366b;
  border-radius: 50%;
}

.TaskPicoWidget_optionPositionActive__KanWp {
  transition: 500ms;
  height: 25px;
  width: 25px;
  padding: 0 6px;
  border: solid 1px #292f36;
  border-radius: 50%;
  color: white !important;
  background: #292f36 !important;
}

.TaskPicoWidget_minWidth__2H95a {
  max-width: -webkit-min-content !important;
  max-width: min-content !important;
  flex-grow: inherit !important;
}

.TaskPicoWidget_centered__2PELV {
  margin-left: auto;
  margin-right: auto;
  display: flex !important;
}

.TaskPicoWidget_modalRigthAnswer__MU7o6 .TaskPicoWidget_modalContent__2gFO9 {
  background-color: #c7ef00 !important;
}

.TaskPicoWidget_completedTaskTitle__2jpwz {
  -webkit-text-decoration: line-through #c7ef00 ;
          text-decoration: line-through #c7ef00 ;
  color: #292f369c;
}

.TaskPicoWidget_completedCard__1zhfx {
  opacity: 0.6;
}

.TaskPicoWidget_nanoTask__2UQTD {
  width: calc(100vw/8);
  font-weight: bold;
  color: #292F36;
  text-align: left;
  word-wrap: break-word;
}

@media (min-width: 768px) {
  .TaskPicoWidget_nanoTask__2UQTD {
    border-radius: 6px;
    margin: 1px auto;
    min-width: 62px;
    max-width: 82px;
    font-size: smaller;
  }

  .TaskPicoWidget_nanoCol__1_EXs {
    padding: 0 0 0 5px !important;
  }

  .TaskPicoWidget_subjectTitle__14roj {
    font-size: 16px;
    font-weight: bolder;
  }

  .TaskPicoWidget_taskTitle__308Y2 {
    margin-top: -2px;
    display: flex;
    font-size: 12px;
    font-weight: bold;
    word-break: break-all;
  }

  .TaskPicoWidget_checkSign__3rkhI {
    -webkit-transform: translate(0%,-175%);
            transform: translate(0%,-175%);
  }
}

@media (max-width: 768px) {
  .TaskPicoWidget_nanoTask__2UQTD {
    border-radius: 10px;
    margin: 1px;
    padding: 0px 0px 0px 1px;
    max-width: calc(100vw/6);
    font-size: smaller;
  }

  .TaskPicoWidget_nanoCol__1_EXs {
    padding: 2px 0 2px 5px !important;
  }

  .TaskPicoWidget_subjectTitle__14roj {
    font-size: 12px;
    font-weight: bolder;
  }

  .TaskPicoWidget_taskTitle__308Y2 {
    margin-top: -2px;
    display: flex;
    font-size: 8px;
    font-weight: bold;
    word-break: break-all;
  }

  .TaskPicoWidget_checkSign__3rkhI {
    -webkit-transform: translate(0%,-140%);
            transform: translate(0%,-140%);
  }
}

.TaskPicoWidget_completedNanoTask__3KpCl {
  -webkit-filter: brightness(50%);
          filter: brightness(50%);
  text-decoration: line-through;
  -webkit-text-decoration-color: #c7ef00;
          text-decoration-color: #c7ef00;
}

.TaskPicoWidget_checkSignDiv__3lA7N {
  position: relative;
  height: 0;
  top: 0;
  margin-bottom: -5px;
}

.TaskPicoWidget_checkSign__3rkhI {
  /* position: absolute; */
  top: 50%; left: 50%;
  /* width: 70px; height: 70px; */
  
  z-index: 1;
}

.UserWidget_container__1EeFo {
  margin-top: 56px;
  padding-bottom: 100px;
  background-color: #292F36;
  color: #fff;
}

.UserWidget_UserArea__1y7aQ {
  z-index: 999 !important;
}

.UserWidget_primaryButton__1jWfX {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef00 !important;
  background-color: #c7ef00 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.UserWidget_primaryButton__1jWfX:hover, .UserWidget_primaryButton__1jWfX:active, .UserWidget_primaryButton__1jWfX.UserWidget_active__1g4cx {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.UserWidget_secondaryButton__3YqKX {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.UserWidget_secondaryButton__3YqKX:hover, .UserWidget_secondaryButton__3YqKX:active, .UserWidget_secondaryButton__3YqKX.UserWidget_active__1g4cx {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}


.UserWidget_primaryLink__2lNjU {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid transparent !important;
  background-color: transparent !important; 
  color: #c7ef00 !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.UserWidget_primaryLink__2lNjU:hover, .UserWidget_primaryLink__2lNjU:active, .UserWidget_primaryLink__2lNjU.UserWidget_active__1g4cx {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.UserWidget_roundButton__3UqY2 {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50% !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  padding: 10px 15px !important;
  flex: 1 1;
  margin-left: auto;
  margin-right: 0;
  max-width: 46px;
}

.UserWidget_roundButton__3UqY2:hover, .UserWidget_roundButton__3UqY2:active, .UserWidget_roundButton__3UqY2.UserWidget_active__1g4cx {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  color: #292F36 !important;
}

.UserWidget_secondaryLink__3dt0y {
  cursor: pointer;
  color: #292F36;
  text-decoration: underline;
  -webkit-text-decoration-color: #c7ef00;
          text-decoration-color: #c7ef00;
}

.UserWidget_secondaryLink__3dt0y:hover, .UserWidget_secondaryLink__3dt0y:active, .UserWidget_secondaryLink__3dt0y.UserWidget_active__1g4cx {
  color: #c7ef00;
  text-decoration: underline;
  -webkit-text-decoration-color: #c7ef00;
          text-decoration-color: #c7ef00;
}

.UserWidget_bgWhite__3OMUg {
  background-color: white;
  border: 0 !important;
  color: #292F36;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
}

.UserWidget_borderRadius25__2a0vg {
  border-radius: 25px !important;
}

.UserWidget_toBottomCenter__2wMF5 {
  margin: 0 auto !important;
}

.UserWidget_toCenter__hDmTD {
  text-align: center;
}

.UserWidget_toRight__2HYj0 {
  text-align: right;
}

.UserWidget_questionWidget__2Kdke {
  line-height: 45px;
  display: flex;
  background-color: white;
  padding: 15px 25px;
  margin: 10px 0;
  border-radius: 25px !important;
  border: 0 !important;
  color: #292F36;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
}

.UserWidget_infoCard__3Wk91 a:not(.UserWidget_textDecorationNone__2ltYi)  {
  transition: all 200ms;
  font-weight: bold;
  color: #292F36 !important;
  text-decoration: underline !important;
  -webkit-text-decoration-color: #c7ef00 !important;
          text-decoration-color: #c7ef00 !important;
}


.UserWidget_infoCard__3Wk91 a:hover:not(.UserWidget_textDecorationNone__2ltYi) {
  color: #c7ef00 !important;
  text-decoration: underline !important;
  -webkit-text-decoration-color: #c7ef00 !important;
          text-decoration-color: #c7ef00 !important;
}

.UserWidget_loginIcon__2quKZ {
  height: 30px;
  width: 30px;
  background-color: #f8f8f8;
  border-radius: 50%;
  margin: auto 15px;
  padding-left: 8px;
}

.UserWidget_userCircle__3GaUn {
  cursor: pointer;
  display:inline-block;
  width: 45px;
  height: 45px;
  font-size: 24px;
  line-height: 45px;
  text-align:center;
  border-radius: 50%;
  background: white;
  vertical-align:middle;
  color:#292F36;
}
.UserWidget_userCircle__3GaUn:hover {
  transition: 0.2s;
  background-color: #F6F6F6;
}

.UserWidget_userLettersName__3k1SH {
  color:#292F36;
  font-size: 20px;
  line-height: 45px;
  font-weight: bolder;
  position: relative;
  top: -1px;
}

.UserWidget_userCard__17OZE {
  margin-top: 2em;
  border-radius: 2em;
  background: linear-gradient(99.26deg, #A43BBE 2.98%, #72417E 100.84%);
  padding: 2em 0 1em 0;
}

.UserWidget_userCardMobile__78arP {
  margin-top: 2em;
  /* border-radius: 2em; */
  background: linear-gradient(99.26deg, #A43BBE 2.98%, #72417E 100.84%);
  padding: 2em 0 1em 0;
}

.UserWidget_userCircleMobile__2YMtL {
  cursor: pointer;
  display:inline-block;
  width: 1.5em;
  height: 1.5em;
  font-size: 80px;
  line-height: 1.5em;
  text-align:center;
  border-radius:50%;
  background: white;
  vertical-align:middle;
  color:#292F36;
}
.UserWidget_userCircleMobile__2YMtL:hover {
  transition: 0.2s;
  background-color: #F6F6F6;
}

.UserWidget_userLettersNameMobile___E__0 {
  color:#292F36;
  font-size: 80px;
  line-height: 1.5em;
  position: relative;
  top: -4px;
}

.UserWidget_rightLink__4SIj9 {
  display: flex;
  margin: 0 0 0 auto;
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid transparent !important;
  background-color: transparent !important; 
  color: #c7ef00 !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.UserWidget_rightLink__4SIj9:hover, .UserWidget_rightLink__4SIj9:active, .UserWidget_rightLink__4SIj9.UserWidget_active__1g4cx {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.UserWidget_dangerLink__2PFTq {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid transparent !important;
  background-color: transparent !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.UserWidget_dangerLink__2PFTq:hover, .UserWidget_dangerLink__2PFTq:active, .UserWidget_dangerLink__2PFTq.UserWidget_active__1g4cx {
  background-color: rgb(255,72,35,0.5) !important;
  border: 1px solid rgb(255,72,35,0.5) !important;
  text-decoration: underline;
  color: white !important;
}

.UserWidget_betaTag__3XE3w {
  margin-left: 10px;
  color: #c7ef00;
  text-shadow:
      0 0 7px rgba(255, 255, 255, 0.5),
      0 0 10px rgba(255, 255, 255, 0.5),
      0 0 21px #fff,
      0 0 42px #c7ef00,
      0 0 82px #c7ef00,
      0 0 92px #c7ef00,
      0 0 102px #c7ef00,
      0 0 151px #c7ef00;
}

.UserWidget_bgDark__35Zdu {
  background-color: transparent !important;
  border: solid 1px #525252 !important;
  padding: 20px !important;
  border-radius: 25px !important;
}

.UserWidget_formInput__1fIS- {
  background-color: transparent !important;
  color: #c7ef00 !important;
  border: solid 1px #c7ef0080 !important;
}

.UserWidget_formInput__1fIS-:focus, .UserWidget_copyBytton__39EvH:focus {
  box-shadow: 0 0 1px 2px #c7ef0080 !important;
}

.UserWidget_copyBytton__39EvH {
  background-color: #525252 !important;
  border-radius: 50% !important;
  border: transparent !important;
  height: 40px;
}

.UserWidget_userName__17Fks {

}

.UserWidget_maxWidthMaxContent__2i04I {
  max-width: -webkit-max-content !important;
  max-width: max-content !important;
  max-width: -moz-max-content !important;
}
.RegisterScheduleB_container__2PkuY {
  margin-top: 56px;
  padding-bottom: 100px;
  background-color: #292F36;
  color: #fff;
}

@media (min-width: 768px) {
  .RegisterScheduleB_scheduleProgress__2iwz_ {
    margin-top: 56px;
  }
}

.RegisterScheduleB_UserArea__2sXJK {
  z-index: 999 !important;
}

.RegisterScheduleB_primaryButton__3-Yj5 {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef00 !important;
  background-color: #c7ef00 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.RegisterScheduleB_primaryButton__3-Yj5:hover, .RegisterScheduleB_primaryButton__3-Yj5:active, .RegisterScheduleB_primaryButton__3-Yj5.RegisterScheduleB_active__39muz {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.RegisterScheduleB_secondaryButton__K7jj_ {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.RegisterScheduleB_secondaryButton__K7jj_:hover, .RegisterScheduleB_secondaryButton__K7jj_:active, .RegisterScheduleB_secondaryButton__K7jj_.RegisterScheduleB_active__39muz {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.RegisterScheduleB_primaryLink__17JM4 {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid transparent !important;
  background-color: transparent !important; 
  color: #c7ef00 !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.RegisterScheduleB_primaryLink__17JM4:hover, .RegisterScheduleB_primaryLink__17JM4:active, .RegisterScheduleB_primaryLink__17JM4.RegisterScheduleB_active__39muz {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.RegisterScheduleB_bgNone__1a8Cb {
  background-color: transparent !important;
  border: 0 !important;
}

.RegisterScheduleB_brightness05__1bjjb {
  -webkit-filter: brightness(0.45);
          filter: brightness(0.45);
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
  border-radius: 25px !important;
}

.RegisterScheduleB_dFlex__2ac0H {
  display: flex !important;
}

.RegisterScheduleB_toRight__5GNxN {
  text-align: right;
}

.RegisterScheduleB_toBottomCenter__3tLAD {
  display: inherit !important;
  max-width: 101px;
  bottom: 0;
  /* display: flex !important; */
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: center;
  /* max-width: 110px; */
}

.RegisterScheduleB_centered__rr3aM {
  display: flex !important;
  margin: 0 auto !important;
  text-align: center impor !important;
}

.RegisterScheduleB_maxminHeight215__3D2AH {
  border-radius: 25px !important;
  min-height: 215px;
  max-height: 215px;
}

::selection { 
  background: #c7ef00; 
  color: #292F36;
  opacity: 1;
}

.RegisterScheduleB_progressImage__22KiG {
  display: flex;
  margin: 60px auto;
  text-align: center;
}

.RegisterScheduleB_containerRegister__2gLk_ {
  text-align: left;
}

.RegisterScheduleB_formField__2BLNu {
  text-align: left;
}

.RegisterScheduleB_formInput__63VLs {
  background-color: transparent !important;
  color: white !important;
  border: 1px solid rgba(255, 255, 255, 0.4) !important;
}

.RegisterScheduleB_card__1PjpU {
  padding: 1rem 2rem;
  margin-bottom: 30px;
  background-color: transparent !important;
  color: white !important;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  border-radius: 25px;
}

.RegisterScheduleB_activeButton__3bz5X, .RegisterScheduleB_activeButton__3bz5X:focus {
  padding: 10px !important;
  margin: 5px;
  min-width: 100%;
  border: 1px solid #c7ef00 !important;
  box-shadow: 0 0 5px 0px #c7ef0080 !important;
  background-color: rgba(255, 255, 255, 0.2) !important;
  color: white !important;
  text-decoration: none !important;
}

.RegisterScheduleB_notActiveButton__2DJoc {
  color: white !important;
  margin: 5px;
  padding: 10px !important;
  min-width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.RegisterScheduleB_notActiveButton__2DJoc:focus {
  box-shadow: 0 0 5px 0px #c7ef0020 !important; 
}

.RegisterScheduleB_notActiveButton__2DJoc:hover {
  text-decoration: none !important;
  background-color: rgba(255, 255, 255, 0.2);
}

.RegisterScheduleB_check__1_Rkz {
  position: absolute;
  height: 23px;
  width: 23px;
  min-width: 23px;
  border-radius: 50%;
  border: solid 2px white;
  background-color: transparent;
}

.RegisterScheduleB_activeCheck__3o3lR {
  position: absolute;
  height: 23px;
  width: 23px;
  min-width: 23px;
  border-radius: 50%;
  border: solid 2px white;
  background-color: transparent;
  transition: all 500ms;
}

.RegisterScheduleB_activeCheck__3o3lR::before {
  content: '';
  position: absolute;
  left: 1px;
  top: 1px;
  height: 17px;
  width: 17px;
  min-width: 17px;
  border-radius: 50%;
  background-color: #c7ef00;
}

.RegisterScheduleB_checkForm__34MAt {
  padding: 5px;
  cursor: pointer;
}

.RegisterScheduleB_examBanner__1OWGg {
  transition: all 200ms ease-in-out;
  border-radius: 1.5em;
  color: white;
  background-color: #292f36 !important;
}

.RegisterScheduleB_borderRadius25__3qzHP {
  border-radius: 25px !important;
}

.RegisterScheduleB_darkCard__1BPVs {
  background-color: transparent !important;
  border: solid 1px rgba(255, 255, 255, 0.1) !important;
  border-radius: 20px !important;
}


.RegisterScheduleB_starDiv__3djtI {
  margin-top: -50px;
}

.RegisterScheduleB_pointsCard__1NeRq {
  margin-top: -80px;
  margin-left: 15px;
  margin-right: 15px;
  background-color: #292f36 !important;
  font-size: large;
  color: white;
  font-weight: bolder;
  padding: 15px;
  border: solid 1px rgba(255, 255, 255, 0.2) !important;
  border-radius: 16px !important;
}

@media (min-width: 768px) {
  .RegisterScheduleB_completedStreakCircle__ktun9 {
    flex: 1 1;
    line-height: 50px;
    align-items: center;
    font-weight: bolder;
    font-size: 20px;
    color: #292F36 !important;
    padding: 0 !important;
    max-height: 50px !important;
    width: 50px !important;
    max-width: 50px !important;
    background-color: #c7ef00;
    border-radius: 50%;
    box-shadow: 0 0 10px 1px #c7ef00;
  }

  .RegisterScheduleB_freezeStreakCircle__2Agq5 {
    flex: 1 1;
    line-height: 50px;
    align-items: center;
    font-weight: bolder;
    font-size: 20px;
    color: #FFFFFF30 !important;
    padding: 0 !important;
    max-height: 50px !important;
    width: 50px !important;
    max-width: 50px !important;
    background-color: #FFFFFF20;
    border-radius: 50%;
  }

  .RegisterScheduleB_freezeItemStreakCircle__2u8w2 {
    flex: 1 1;
    line-height: 50px;
    align-items: center;
    font-weight: bolder;
    font-size: 20px;
    color: #FFFFFF30 !important;
    padding: 0 !important;
    max-height: 50px !important;
    width: 50px !important;
    max-width: 50px !important;
    background-color: #FFFFFF20;
    border-radius: 50%;
  }

  .RegisterScheduleB_activeStreakCircle__AiZVU {
    flex: 1 1;
    line-height: 50px;
    align-items: center;
    font-weight: bolder;
    font-size: 20px;
    color: #FFF !important;
    padding: 0 !important;
    max-height: 50px !important;
    width: 50px !important;
    max-width: 50px !important;
    background-color: #C7ef0030;
    border-radius: 50%;
  }

  .RegisterScheduleB_glowEffectImage__kcjRL {
    max-height: 25px;
    max-width: 50px;
    display: flex;
  }

  .RegisterScheduleB_glowEffectImageRotate__2HqQj {
    max-width: 50px;
    display: flex;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }

  .RegisterScheduleB_glowEffectSpace__13vS6 {
    height: 25px;
    width: 50px;
    display: flex;
  }
}

@media (max-width: 768px) {
  .RegisterScheduleB_completedStreakCircle__ktun9 {
    flex: 1 1;
    line-height: 30px;
    align-items: center;
    font-weight: bolder;
    font-size: 16px;
    color: #292F36 !important;
    padding: 0 !important;
    max-height: 30px !important;
    width: 30px !important;
    max-width: 30px !important;
    background-color: #c7ef00;
    border-radius: 50%;
    box-shadow: 0 0 10px 1px #c7ef00;
  }

  .RegisterScheduleB_freezeStreakCircle__2Agq5 {
    flex: 1 1;
    line-height: 30px;
    align-items: center;
    font-weight: bolder;
    font-size: 16px;
    color: #FFFFFF30 !important;
    padding: 0 !important;
    max-height: 30px !important;
    width: 30px !important;
    max-width: 30px !important;
    background-color: #FFFFFF20;
    border-radius: 50%;
  }

  .RegisterScheduleB_freezeItemStreakCircle__2u8w2 {
    flex: 1 1;
    line-height: 30px;
    align-items: center;
    font-weight: bolder;
    font-size: 16px;
    color: #FFFFFF30 !important;
    padding: 0 !important;
    max-height: 30px !important;
    width: 30px !important;
    max-width: 30px !important;
    background-color: #FFFFFF20;
    border-radius: 50%;
  }

  .RegisterScheduleB_activeStreakCircle__AiZVU {
    flex: 1 1;
    line-height: 30px;
    align-items: center;
    font-weight: bolder;
    font-size: 16px;
    color: #FFF !important;
    padding: 0 !important;
    max-height: 30px !important;
    width: 30px !important;
    max-width: 30px !important;
    background-color: #C7ef0030;
    border-radius: 50%;
  }

  .RegisterScheduleB_glowEffectImage__kcjRL {
    max-height: 15px;
    max-width: 30px;
    display: flex;
  }

  .RegisterScheduleB_glowEffectImageRotate__2HqQj {
    max-height: 15px;
    max-width: 30px;
    display: flex;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }

  .RegisterScheduleB_glowEffectSpace__13vS6 {
    height: 15px;
    width: 30px;
    display: flex;
  }
}
.Calendar_container__EQUkk {
  /* margin-top: 56px; */
  /* padding-bottom: 100px; */
  background-color: #292F36;
  color: #fff;
}

.Calendar_UserArea__2DSWJ {
  z-index: 999 !important;
}

.Calendar_primaryButton__3e1pq {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef00 !important;
  background-color: #c7ef00 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.Calendar_primaryButton__3e1pq:hover, .Calendar_primaryButton__3e1pq:active, .Calendar_primaryButton__3e1pq.Calendar_active__3ehSr {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Calendar_secondaryButton__1sMVu {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.Calendar_secondaryButton__1sMVu:hover, .Calendar_secondaryButton__1sMVu:active, .Calendar_secondaryButton__1sMVu.Calendar_active__3ehSr {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Calendar_primaryLink__pywrz {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid transparent !important;
  background-color: transparent !important; 
  color: #c7ef00 !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.Calendar_primaryLink__pywrz:hover, .Calendar_primaryLink__pywrz:active, .Calendar_primaryLink__pywrz.Calendar_active__3ehSr {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Calendar_secondaryLink__DIPlf {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  color: white !important;
  text-decoration: underline !important;
}

.Calendar_secondaryLink__DIPlf:hover, .Calendar_secondaryLink__DIPlf:active, .Calendar_secondaryLink__DIPlf.Calendar_active__3ehSr {
  color: #c7ef00 !important;
}

.Calendar_roundButton__1tQg0 {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50% !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  padding: 10px 15px !important;
  flex: 1 1;
  margin-left: auto;
  margin-right: 0;
  max-width: 46px;
}

.Calendar_roundButton__1tQg0:hover, .Calendar_roundButton__1tQg0:active, .Calendar_roundButton__1tQg0.Calendar_active__3ehSr {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  color: #292F36 !important;
}

.Calendar_secondaryLink__DIPlf {
  cursor: pointer;
  color: #292F36;
  text-decoration: underline;
  -webkit-text-decoration-color: #c7ef00;
          text-decoration-color: #c7ef00;
}

.Calendar_secondaryLink__DIPlf:hover, .Calendar_secondaryLink__DIPlf:active, .Calendar_secondaryLink__DIPlf.Calendar_active__3ehSr {
  color: #c7ef00;
  text-decoration: underline;
  -webkit-text-decoration-color: #c7ef00;
          text-decoration-color: #c7ef00;
}

.Calendar_bgWhite__2lTyC {
  background-color: white;
  border: 0 !important;
  color: #292F36;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
}

.Calendar_borderRadius25__TYHOY {
  border-radius: 25px !important;
}

.Calendar_toBottomCenter__22sYN {
  margin: 0 auto !important;
}

.Calendar_toCenter__3_wl4 {
  text-align: center;
}

.Calendar_toRight__fHqKg {
  text-align: right;
}

.Calendar_questionWidget__2jl-5 {
  line-height: 45px;
  display: flex;
  background-color: white;
  padding: 15px 25px;
  margin: 10px 0;
  border-radius: 25px !important;
  border: 0 !important;
  color: #292F36;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
}

.Calendar_marginActive__gmmFC {
  border-left: solid 5px #fff;
  margin-left: 8px;
}

.Calendar_circle___v5gh {
  z-index: 1;
  display:block;
  background: #fff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.Calendar_activeCircle__Te8s0 {
  z-index: 3;
  position: relative;
  top: 6px;
  left: -26px;
  background: #c7ef00;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.Calendar_secondaryCircle__1mlHY {
  z-index: 2;
  position: relative;
  top: 4px;
  left: -16px;
  background: #292F36;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.Calendar_activeTime__3JHb5 {
  margin-left: -8px;
}

.Calendar_unMargin__ktXBA {
  margin-top: 2rem;
}
.Calendar_primary__eeSjT {
  color: #c7ef00;
  transition: all 500ms;
}

.Calendar_primary__eeSjT:hover {
  color: #a8c710;
}

.Calendar_textCenter__3BklY {
  text-align: center;
}

.Calendar_weekDay__2zBhT {
  color: white !important;
  transition: all 500ms;
}

.Calendar_weekDay__2zBhT:hover {
  color: #c7ef00 !important;
}

.Calendar_circle___v5gh {
  display: flex;
  margin: 0 auto;
  background-color: #c7ef00;
  height: 6px;
  min-width: 6px;
  width: 6px;
}

.Calendar_darkButton__2QNK9 {
  border-radius: 12px !important;
  border: solid 1px #ffffff60 !important;
  color: white !important;
  transition: all 500ms;
}

.Calendar_darkButton__2QNK9:hover {
  background-color: #ffffff20 !important;
  color: #c7ef00 !important;
}

.Calendar_calendarDay__1GToW {
  color: white !important;
  cursor: pointer;
  transition: all 500ms;
}

.Calendar_calendarDay__1GToW:hover {
  color: #c7ef00 !important;
}

.Calendar_desactivate__AoDSa {
  opacity: 0.3;
}
.TaskMiniWidget_container__EwwLs {
  margin-top: 56px;
  padding-bottom: 100px;
  background-color: #292F36;
  color: #fff;
}

.TaskMiniWidget_UserArea__2Un2N {
  z-index: 999 !important;
}

.TaskMiniWidget_primaryButton__q6Z8q {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef00 !important;
  background-color: #c7ef00 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.TaskMiniWidget_primaryButton__q6Z8q:hover, .TaskMiniWidget_primaryButton__q6Z8q:active, .TaskMiniWidget_primaryButton__q6Z8q.TaskMiniWidget_active__p1tOq {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.TaskMiniWidget_primaryButtonBlocked__1eMix {
  transition: 0.2s;
  cursor: not-allowed !important;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef0000 !important;
  background-color: #c7ef0060 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.TaskMiniWidget_primaryButtonBlocked__1eMix:hover, .TaskMiniWidget_primaryButtonBlocked__1eMix:active, .TaskMiniWidget_primaryButtonBlocked__1eMix.TaskMiniWidget_active__p1tOq {
  background-color: #c7ef0060 !important;
  border: 1px solid #c7ef0000 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.TaskMiniWidget_secondaryButton__5P1FT {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.TaskMiniWidget_secondaryButton__5P1FT:hover, .TaskMiniWidget_secondaryButton__5P1FT:active, .TaskMiniWidget_secondaryButton__5P1FT.TaskMiniWidget_active__p1tOq {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}


.TaskMiniWidget_primaryLink__396PN {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid transparent !important;
  background-color: transparent !important; 
  color: #c7ef00 !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.TaskMiniWidget_primaryLink__396PN:hover, .TaskMiniWidget_primaryLink__396PN:active, .TaskMiniWidget_primaryLink__396PN.TaskMiniWidget_active__p1tOq {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.TaskMiniWidget_toRight__38UG7 {
  margin-left: auto;
  margin-right: 0;
  display: flex !important;
  text-align: right;
}

.TaskMiniWidget_clickableCard__2yLO- {
  color: white !important;
  transition: all 200ms ease-in-out;
}

.TaskMiniWidget_clickableCard__2yLO-:hover {
  color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
}

.TaskMiniWidget_alwaysGrey__pet2s, *:hover .TaskMiniWidget_alwaysGrey__pet2s {
  color: #ffffff80 !important;
}

.TaskMiniWidget_fitContent__3V26N {
  max-width: -webkit-fit-content !important;
  max-width: -moz-fit-content !important;
  max-width: fit-content !important;
}

/* *:hover a.clickableCard {
  color: initial !important;
  text-decoration: none !important;
} */
/* 
.clickableCard {
  transition: all 200ms ease-in-out;
}

.clickableCard:hover {
  box-shadow: 0 0 10px black;
  border-radius: 1.5em;
  transform: scale(1.05);
}

.clickableCard .buttonIcon {
  transition: all 200ms ease-in-out;
} */
/* 
.clickableCard .buttonIcon:hover {
  color: #c7ef00; 
} */

.TaskMiniWidget_toRight__38UG7 button {
  display: flex;
  margin: 0 0 0 auto;
}

.TaskMiniWidget_roundButton__CxERj {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 30px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  padding: 15px 30px !important;
  flex: 1 1;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 15px;
  text-decoration: underline !important;
}

.TaskMiniWidget_roundButton__CxERj:hover, .TaskMiniWidget_roundButton__CxERj:active, .TaskMiniWidget_roundButton__CxERj.TaskMiniWidget_active__p1tOq {
  background-color: #39424d !important;
  border: 1px solid #39424d !important;
}

.TaskMiniWidget_outlineSecondaryButton__246nO {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 30px !important;
  border: 1px solid #292F36 !important;
  background-color: transparent !important;
  color: #292F36 !important;
  padding: 15px 30px !important;
  text-decoration: underline !important;
  flex: 1 1;
  margin-left: auto;
  margin-right: 0;
}

.TaskMiniWidget_outlineSecondaryButton__246nO:hover, .TaskMiniWidget_outlineSecondaryButton__246nO:active, .TaskMiniWidget_outlineSecondaryButton__246nO.TaskMiniWidget_active__p1tOq {
  background-color: #292F36 !important;
  border: 1px solid #292F36 !important;
  color: white !important;
}

.TaskMiniWidget_secondaryLink__8vRmn {
  cursor: pointer;
  color: #292F36 !important;
  background: none;
  border: none;
  text-decoration: underline;
  padding: 0 !important;
  -webkit-text-decoration-color: #c7ef00;
          text-decoration-color: #c7ef00;
  transition: all 300ms;
}

.TaskMiniWidget_secondaryLink__8vRmn:hover, .TaskMiniWidget_secondaryLink__8vRmn:active, .TaskMiniWidget_secondaryLink__8vRmn.TaskMiniWidget_active__p1tOq {
  color: #c7ef00 !important;
  text-decoration: underline !important;
  -webkit-text-decoration-color: #c7ef00 !important;
          text-decoration-color: #c7ef00 !important;
}

.TaskMiniWidget_taskIcon__3o5Yc {
  color: white;
  margin-left: auto;
  margin-right: 25px;
  max-width: 100px;
  max-height: 100px;
  width: 100px;
  height: 100px;
  opacity: 0.6;
}

.TaskMiniWidget_bgWhite__1Wf4_ {
  background-color: white;
  color: #292F36;
}

.TaskMiniWidget_bgDark__2p06X {
  background-color: transparent !important;
  border:1px solid rgba(255, 255, 255, 0.2) !important;
}

.TaskMiniWidget_bgPink__3zySH {
  background-color: #FB3874 !important;
  border:1px solid rgba(255, 255, 255, 0.2) !important;
}

.TaskMiniWidget_white__3Q11H {
  color: white;
}

.TaskMiniWidget_borderRadius25__2tZI6 {
  border-radius: 25px !important;
}

.TaskMiniWidget_toBottomCenter__3W0q8 {
  margin: 0 auto !important;
}

.TaskMiniWidget_toCenter__2OunM {
  text-align: center;
}

.TaskMiniWidget_questionWidget__n47Xo {
  line-height: 45px;
  display: flex;
  background-color: white;
  padding: 15px 25px;
  margin: 10px 0;
  border-radius: 25px !important;
  border: 0 !important;
  color: #292F36;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
}

.TaskMiniWidget_option__ONJVj {
  background-color: white;
  border: solid 2px transparent !important;
  color: #292F36;
  box-shadow: 0 0 5px 1px transparent;
  border-radius: 25px !important;
}

.TaskMiniWidget_optionActive__a67QU {
  transition: 500ms;
  background-color: white;
  border: solid 2px #c7ef00 !important;
  color: #292F36;
  box-shadow: 0 0 5px 1px #c7ef007e;
  border-radius: 25px !important;
}

.TaskMiniWidget_optionContent__1sqYF {
  padding-left: 0 !important;
}

.TaskMiniWidget_optionContent__1sqYF p {
  margin-bottom: 0;
}

.TaskMiniWidget_optionPosition__1lfr7 {
  height: 25px;
  width: 25px;
  padding: 0 6px;
  border: solid 1px #292f366b;
  border-radius: 50%;
}

.TaskMiniWidget_optionPositionActive__1UcOs {
  transition: 500ms;
  height: 25px;
  width: 25px;
  padding: 0 6px;
  border: solid 1px #292f36;
  border-radius: 50%;
  color: white !important;
  background: #292f36 !important;
}

.TaskMiniWidget_minWidth__1yGXZ {
  max-width: -webkit-min-content !important;
  max-width: min-content !important;
  flex-grow: inherit !important;
}

.TaskMiniWidget_centered__2OIpO {
  margin-left: auto;
  margin-right: auto;
  display: flex !important;
}

.TaskMiniWidget_modalRigthAnswer__2aMuT .TaskMiniWidget_modalContent__1iRPN {
  background-color: #c7ef00 !important;
}

a.TaskMiniWidget_linkCard__5DADU {
  color: white !important;
  transition: all 500ms;
}

a.TaskMiniWidget_linkCard__5DADU:hover {
  color: #c7ef00 !important;
}
.Road_container__DQbIW {
  margin-top: 56px;
  padding-bottom: 100px;
  background-color: #292F36;
  color: #fff;
}

.Road_UserArea__18JrF {
  z-index: 999 !important;
}

.Road_primaryButton__3gD2v {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef00 !important;
  background-color: #c7ef00 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.Road_primaryButton__3gD2v:hover, .Road_primaryButton__3gD2v:active, .Road_primaryButton__3gD2v.Road_active__1BbuY {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Road_primaryButtonBlocked__1Zn6C {
  transition: 0.2s;
  cursor: not-allowed !important;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef0000 !important;
  background-color: #c7ef0060 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.Road_primaryButtonBlocked__1Zn6C:hover, .Road_primaryButtonBlocked__1Zn6C:active, .Road_primaryButtonBlocked__1Zn6C.Road_active__1BbuY {
  background-color: #c7ef0060 !important;
  border: 1px solid #c7ef0000 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Road_secondaryButton__34r4j {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.Road_secondaryButton__34r4j:hover, .Road_secondaryButton__34r4j:active, .Road_secondaryButton__34r4j.Road_active__1BbuY {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}


.Road_primaryLink__j1eFX {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid transparent !important;
  background-color: transparent !important; 
  color: #c7ef00 !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.Road_primaryLink__j1eFX:hover, .Road_primaryLink__j1eFX:active, .Road_primaryLink__j1eFX.Road_active__1BbuY {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Road_toRight__OI_2X {
  margin-left: auto;
  margin-right: 0;
  display: flex !important;
  text-align: right;
}

.Road_roundButton__2FQn9 {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 30px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  padding: 15px 30px !important;
  flex: 1 1;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 15px;
  text-decoration: underline !important;
}

.Road_roundButton__2FQn9:hover, .Road_roundButton__2FQn9:active, .Road_roundButton__2FQn9.Road_active__1BbuY {
  background-color: #39424d !important;
  border: 1px solid #39424d !important;
}

.Road_outlineSecondaryButton__2BcTK {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 30px !important;
  border: 1px solid #292F36 !important;
  background-color: transparent !important;
  color: #292F36 !important;
  padding: 15px 30px !important;
  text-decoration: underline !important;
  flex: 1 1;
  margin-left: auto;
  margin-right: 0;
}

.Road_outlineSecondaryButton__2BcTK:hover, .Road_outlineSecondaryButton__2BcTK:active, .Road_outlineSecondaryButton__2BcTK.Road_active__1BbuY {
  background-color: #292F36 !important;
  border: 1px solid #292F36 !important;
  color: white !important;
}

.Road_secondaryLink__25ZLx {
  cursor: pointer;
  color: #292F36;
  text-decoration: underline;
  -webkit-text-decoration-color: #c7ef00;
          text-decoration-color: #c7ef00;
}

.Road_secondaryLink__25ZLx:hover, .Road_secondaryLink__25ZLx:active, .Road_secondaryLink__25ZLx.Road_active__1BbuY {
  color: #c7ef00;
  text-decoration: underline;
  -webkit-text-decoration-color: #c7ef00;
          text-decoration-color: #c7ef00;
}

.Road_bgDark__3ox31 {
  background-color: #292F36 !important;
  color: white;
  border: 0 solid #000 !important;
}

.Road_dashed__1mV_W {
  border: 4px dashed #c7ef0067 !important;
}

.Road_borderRadius25__2Myqr {
  border-radius: 25px !important;
}

.Road_toBottomCenter__1-_7k {
  margin: 0 auto !important;
}

.Road_toCenter__2Y6RU {
  text-align: center;
}

.Road_questionWidget__2nm3j {
  line-height: 45px;
  display: flex;
  background-color: white;
  padding: 15px 25px;
  margin: 10px 0;
  border-radius: 25px !important;
  border: 0 !important;
  color: #292F36;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
}

.Road_option__1d7-8 {
  background-color: white;
  border: solid 2px transparent !important;
  color: #292F36;
  box-shadow: 0 0 5px 1px transparent;
  border-radius: 25px !important;
}

.Road_optionActive__3gZsB {
  transition: 500ms;
  background-color: white;
  border: solid 2px #c7ef00 !important;
  color: #292F36;
  box-shadow: 0 0 5px 1px #c7ef007e;
  border-radius: 25px !important;
}

.Road_optionContent__2P9KS {
  padding-left: 0 !important;
}

.Road_optionContent__2P9KS p {
  margin-bottom: 0;
}

.Road_optionPosition__1z144 {
  height: 25px;
  width: 25px;
  padding: 0 6px;
  border: solid 1px #292f366b;
  border-radius: 50%;
}

.Road_optionPositionActive__2lnqf {
  transition: 500ms;
  height: 25px;
  width: 25px;
  padding: 0 6px;
  border: solid 1px #292f36;
  border-radius: 50%;
  color: white !important;
  background: #292f36 !important;
}

.Road_minWidth__Mdsw_ {
  max-width: -webkit-min-content !important;
  max-width: min-content !important;
  flex-grow: inherit !important;
}

.Road_centered__2Hd68 {
  margin-left: auto;
  margin-right: auto;
  display: flex !important;
}

.Road_modalRigthAnswer__1yQaZ .Road_modalContent__34ROK {
  background-color: #c7ef00 !important;
}

.Road_tagBanner__P5jYc {
  transition: all 200ms ease-in-out;
  border-radius: 1.5em;
  color: white;
  background: #3CA55C;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #B5AC49, #3CA55C); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.Road_tagBanner__P5jYc:hover {
  text-decoration: none !important;
  color: white;
  box-shadow: 0 0 10px black;
  border-radius: 1.5em;
  -webkit-transform: scale(1.05) !important;
          transform: scale(1.05) !important;
}

.Road_examBanner__gfjHI:hover {
  text-decoration: none !important;
  color: white;
  box-shadow: 0 0 10px black;
  border-radius: 1.5em;
  -webkit-transform: scale(1.05) !important;
          transform: scale(1.05) !important;
}

.Road_areaBanner__2PEnl {
  transition: all 200ms ease-in-out;
  border-radius: 1.5em;
  color: white;
  background: #136a8a; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #136a8a, #267871); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.Road_areaBanner__2PEnl:hover {
  text-decoration: none !important;
  color: white;
  box-shadow: 0 0 10px black;
  border-radius: 1.5em;
  -webkit-transform: scale(1.05) !important;
          transform: scale(1.05) !important;
}

img {
  max-width: 100%;
}

.Road_titleLevel__25U2F h5 {
  font-weight: bold;
  opacity: 0.5;
  display: grid;
  grid-template-columns: minmax(20px, 1fr) auto minmax(20px, 1fr);
  align-items: center;
  text-align: center;
  grid-gap: 20px;
  width: 80%;
  margin: 0 auto;

}

.Road_titleLevel__25U2F h5:before,
.Road_titleLevel__25U2F h5:after {
  content: '';
  border-top: 2px solid ;
}

.Road_justifyContentCenter__25O-A {
  justify-content: center;
  display: contents;
}

.Road_finalRow__2_8zX {
  text-align: center;
  justify-content: center;
  margin-top: 50px;
  max-width: 500px;
}

.Road_titleRow__1U2x_ {
  text-align: center;
  justify-content: center;
  margin-top: 50px;
}

.Road_examsCard__22eja {
  transition: all 200ms ease-in-out;
  border-radius: 1.5em;
}

.Road_examsCard__22eja img {
  -webkit-filter: brightness(0.5);
          filter: brightness(0.5);
}

.Road_examsCard__22eja:hover {
  box-shadow: 0 0 10px black;
  border-radius: 1.5em;
  -webkit-transform: scale(1.05) !important;
          transform: scale(1.05) !important;
}

.Road_examsCard__22eja .Road_buttonIcon__2A1Up {
  transition: all 200ms ease-in-out;
}

.Road_examsCard__22eja:hover .Road_buttonIcon__2A1Up {
  color: #c7ef00;
}

.Road_bgNone__3cJau {
  background-color: transparent !important;
  border: 0 !important;
}
.Road_UserArea__2oaR4 {
  position: fixed !important;
  z-index: 999 !important;
}

.Road_container__uKTsP {
  /* margin-top: 56px; */
  background-color: #fff;
  color: #292F36;
}

.Road_form__z4dy- {
  padding: 25px;
  text-align: left;
}

.Road_centered__2MaL_ {
  margin-left: auto;
  margin-right: auto;
  display: flex !important;
}

.Road_primaryButton__2inhu {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef00 !important;
  background-color: #c7ef00 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.Road_primaryButton__2inhu:hover, .Road_primaryButton__2inhu:active, .Road_primaryButton__2inhu.Road_active__1J_Y6 {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Road_secondaryButton__1imEx {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.Road_secondaryButton__1imEx:hover, .Road_secondaryButton__1imEx:active, .Road_secondaryButton__1imEx.Road_active__1J_Y6 {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}


.Road_secButton__1s7HQ {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.Road_secButton__1s7HQ:hover, .Road_secButton__1s7HQ:active, .Road_secButton__1s7HQ.Road_active__1J_Y6 {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Road_loginIcon__2Ygrx {
  height: 100px;
  width: 100px;
  background-color: #f8f8f8;
  font-size: 50px;
  border-radius: 50%;
  vertical-align: text-bottom;
  margin: 25px auto;
  line-height: 100px;
}


.Road_primaryLink__3q42q {
  padding: 0 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  -webkit-text-decoration-color: #b2d600 !important;
          text-decoration-color: #b2d600 !important;
}

.Road_primaryLink__3q42q:hover {
  color: #b2d600 !important;
}

.Road_secondaryLink__1Qs8h {
  cursor: pointer;
  color: #292F36 !important;
  text-decoration: underline !important;
  -webkit-text-decoration-color: #c7ef00 !important;
          text-decoration-color: #c7ef00 !important;
  font-weight: bold !important;
}

.Road_secondaryLink__1Qs8h:hover, .Road_secondaryLink__1Qs8h:active, .Road_secondaryLink__1Qs8h.Road_active__1J_Y6 {
  color: #c7ef00 !important;
  text-decoration: underline;
  -webkit-text-decoration-color: #c7ef00 !important;
          text-decoration-color: #c7ef00 !important;
}

.Road_bgNone__2CQhw {
  background-color: transparent !important;
  border: 0 !important;
}

.Road_brightness05__SH_4b {
  -webkit-filter: brightness(0.45);
          filter: brightness(0.45);
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
  border-radius: 25px !important;
}

@media (min-width: 768px) {
  .Road_spaceUp__3UdNJ {
    padding-top: 200px;
  }

  .Road_spaceUpTwo__13DO9 {
    padding-top: 30px;
  }

  .Road_spaceThree__1iqzq {
    padding: 100px 0;
  }

  .Road_pb50__3FRxY {
    padding-bottom: 50px;
  }
}

@media (max-width: 768px) {
  .Road_spaceUp__3UdNJ {
    padding-top: 50px;
    margin: 0 25px;
  }

  .Road_spaceUpTwo__13DO9 {
    padding-top: 0;
  }
}

/**
 * Special
 */

::selection { 
  background: #c7ef00; 
  color: #292F36;
  opacity: 1;
}

.Road_examsCard__3Uwn- {
  transition: all 300ms ease-in-out !important;
}

.Road_examsCard__3Uwn-:hover {
  box-shadow: 0 0 10px black;
  border-radius: 1.5em;
  -webkit-transform: scale(1.05) !important;
          transform: scale(1.05) !important;
}

.Road_examsCard__3Uwn- .Road_buttonIcon__3mXVf {
  transition: all 200ms ease-in-out;
}

.Road_examsCard__3Uwn-:hover .Road_buttonIcon__3mXVf {
  color: #c7ef00;
}

@media (min-width: 768px) {
  .Road_missionCard__3NeDI {
    padding: 8em;
  }
}

@media (max-width: 768px) {
  .Road_missionCard__3NeDI {
    padding: 4em;
  }
}

.Road_missionCard__3NeDI {
  border-radius: 50px !important;
  color: #292F36;
  line-height: 2em;
}

.Road_missionTextTitle__2oR-X {
  font-size: 30px !important;
  font-weight: bolder !important;
  color: #292F36;
}

.Road_strong__2RYcB {
  background-color: #c7ef00;
  text-decoration: underline;
  -webkit-text-decoration-color: #292F36;
          text-decoration-color: #292F36;
}

.Road_top56__3hBRO {
  top: 56px !important;
}

.Road_roadPrincipal__3oBuZ {
  text-align: center;
}

.Road_toRight__2jPZT {
  display: flex;
  margin: 0 0 0 auto;
}

.Road_justifyContentCenter__1dgie {
  justify-content: center;
}

.Road_pt3__3Luo- {
  padding-top: 3rem;
}
.Roads_homeContainer__2RqcS {
  margin-top: 56px;
  background-color: #292F36;
  color: #fff;
}

.Roads_UserArea__38hmI {
  position: fixed !important;
  z-index: 999 !important;
}

.Roads_primaryButton__28QKo {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef00 !important;
  background-color: #c7ef00 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.Roads_primaryButton__28QKo:hover, .Roads_primaryButton__28QKo:active, .Roads_primaryButton__28QKo.Roads_active__1WM9a {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Roads_secondaryButton__3gvqA {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.Roads_secondaryButton__3gvqA:hover, .Roads_secondaryButton__3gvqA:active, .Roads_secondaryButton__3gvqA.Roads_active__1WM9a {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Roads_primaryLink__2MsZZ {
  padding: 0 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  -webkit-text-decoration-color: #b2d600 !important;
          text-decoration-color: #b2d600 !important;
}

.Roads_primaryLink__2MsZZ:hover {
  color: #b2d600 !important;
}

.Roads_bgNone__JzpEY {
  background-color: transparent !important;
  border: 0 !important;
}

.Roads_brightness05__2t544 {
  -webkit-filter: brightness(0.45);
          filter: brightness(0.45);
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
  border-radius: 25px !important;
}

@media (min-width: 768px) {
  .Roads_spaceUp__2WTCX {
    padding-top: 200px;
  }

  .Roads_spaceUpTwo__1DomH {
    padding-top: 30px;
  }

  .Roads_spaceThree__kF3FF {
    padding: 100px 0;
  }

  .Roads_pb50__2JBdC {
    padding-bottom: 50px;
  }
}

@media (max-width: 768px) {
  .Roads_spaceUp__2WTCX {
    padding-top: 50px;
    margin: 0 25px;
  }

  .Roads_spaceUpTwo__1DomH {
    padding-top: 0;
  }
}

/**
 * Special
 */

::selection { 
  background: #c7ef00; 
  color: #292F36;
  opacity: 1;
}

.Roads_examsCard__3UdeE {
  transition: all 300ms ease-in-out !important;
}

.Roads_examsCard__3UdeE:hover {
  box-shadow: 0 0 10px black;
  border-radius: 1.5em;
  -webkit-transform: scale(1.05) !important;
          transform: scale(1.05) !important;
}

.Roads_examsCard__3UdeE .Roads_buttonIcon__2MB92 {
  transition: all 200ms ease-in-out;
}

.Roads_examsCard__3UdeE:hover .Roads_buttonIcon__2MB92 {
  color: #c7ef00;
}

@media (min-width: 768px) {
  .Roads_missionCard__34Z94 {
    padding: 8em;
  }
}

@media (max-width: 768px) {
  .Roads_missionCard__34Z94 {
    padding: 4em;
  }
}

.Roads_missionCard__34Z94 {
  border-radius: 50px !important;
  color: #292F36;
  line-height: 2em;
}

.Roads_missionTextTitle__2VLwp {
  font-size: 30px !important;
  font-weight: bolder !important;
  color: #292F36;
}

.Roads_strong__Pfjxn {
  background-color: #c7ef00;
  text-decoration: underline;
  -webkit-text-decoration-color: #292F36;
          text-decoration-color: #292F36;
}

.Roads_top56__3uuoP {
  top: 56px !important;
}

.Roads_roadPrincipal__XDGCV {
  text-align: center;
}

.Roads_toRight__1zCzZ {
  display: flex;
  margin: 0 0 0 auto;
}

.Roads_justifyContentCenter__14QOL {
  justify-content: center;
}

.Roads_pt3__2myV_ {
  padding-top: 3rem;
}
.LeadRegister_containerRegister__2nlyZ {
  padding-top: 156px;
  padding-bottom: 100px;
  height: 100vh;
  /* background-color: #292F36; */
  color: #fff;
}

@media (max-width: 768px) {
  .LeadRegister_containerRegister__2nlyZ {
    padding-top: 56px !important;
  }
}

.LeadRegister_UserArea__2E_na {
  z-index: 999 !important;
}

.LeadRegister_primaryButton__1OG6S {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef00 !important;
  background-color: #c7ef00 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.LeadRegister_primaryButton__1OG6S:hover, .LeadRegister_primaryButton__1OG6S:active, .LeadRegister_primaryButton__1OG6S.LeadRegister_active__1gKH4 {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.LeadRegister_secondaryButton__2ET6M {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.LeadRegister_secondaryButton__2ET6M:hover, .LeadRegister_secondaryButton__2ET6M:active, .LeadRegister_secondaryButton__2ET6M.LeadRegister_active__1gKH4 {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.LeadRegister_primaryLink__3vO5g {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid transparent !important;
  background-color: transparent !important; 
  color: #c7ef00 !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.LeadRegister_primaryLink__3vO5g:hover, .LeadRegister_primaryLink__3vO5g:active, .LeadRegister_primaryLink__3vO5g.LeadRegister_active__1gKH4 {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.LeadRegister_bgNone__2LZvh {
  background-color: transparent !important;
  border: 0 !important;
}

.LeadRegister_brightness05__1xU4Y {
  -webkit-filter: brightness(0.45);
          filter: brightness(0.45);
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
  border-radius: 25px !important;
}

.LeadRegister_dFlex__2tAYD {
  display: flex !important;
}

.LeadRegister_toRight__198Es {
  text-align: right;
}

.LeadRegister_toBottomCenter__1pQ67 {
  display: inherit !important;
  max-width: 101px;
  bottom: 0;
  /* display: flex !important; */
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: center;
  /* max-width: 110px; */
}

.LeadRegister_maxminHeight215__1-mDK {
  border-radius: 25px !important;
  min-height: 215px;
  max-height: 215px;
}

::selection { 
  background: #c7ef00; 
  color: #292F36;
  opacity: 1;
}

.LeadRegister_progressImage__LZcN0 {
  display: flex;
  margin: 60px auto;
  text-align: center;
}

.LeadRegister_containerRegister__2nlyZ {
  text-align: center;
}

.LeadRegister_formField__3rEbc {
  text-align: left;
}

.LeadRegister_formInput__1LhC9 {
  background-color: transparent !important;
  color: white !important;
  border: 1px solid rgba(255, 255, 255, 0.4) !important;
}
.LeadRegister_formInput__1LhC9:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem #c7ef0025 !important;
  border: 1px solid #c7ef00cc !important;
}

.LeadRegister_card__tzZUk {
  padding: 3rem 2rem;
  margin-bottom: 30px;
  background-color: #292F36 !important;
  color: white !important;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  border-radius: 25px;
}

.LeadRegister_activeButton__2Z_qA, .LeadRegister_activeButton__2Z_qA:focus {
  padding: 10px !important;
  margin: 5px;
  min-width: 100%;
  border: 1px solid #c7ef00 !important;
  box-shadow: 0 0 5px 0px #c7ef0080 !important;
  background-color: rgba(255, 255, 255, 0.2) !important;
  color: white !important;
  text-decoration: none !important;
}

.LeadRegister_notActiveButton__2yGG7 {
  color: white !important;
  margin: 5px;
  padding: 10px !important;
  min-width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.LeadRegister_notActiveButton__2yGG7:focus {
  box-shadow: 0 0 5px 0px #c7ef0020 !important; 
}

.LeadRegister_notActiveButton__2yGG7:hover {
  text-decoration: none !important;
  background-color: rgba(255, 255, 255, 0.2);
}

.LeadRegister_maxWidth400__2COCd {
  max-width: 400px;
}

.LeadRegister_imgLogo__3uzG1 {
  display: flex;
  margin: auto;
  width: 250px;
  padding-bottom: 50px;
}
.Obrigado_containerRegister__3-XkX {
  padding-top: 156px;
  padding-bottom: 100px;
  height: 50vh;
  /* background-color: #292F36; */
  color: #fff;
}

@media (max-width: 768px) {
  .Obrigado_containerRegister__3-XkX {
    padding-top: 56px !important;
  }
}

.Obrigado_UserArea__3Xo4k {
  z-index: 999 !important;
}

.Obrigado_primaryButton__1EQ9m {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef00 !important;
  background-color: #c7ef00 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.Obrigado_primaryButton__1EQ9m:hover, .Obrigado_primaryButton__1EQ9m:active, .Obrigado_primaryButton__1EQ9m.Obrigado_active__1Z0B9 {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Obrigado_secondaryButton__3hgWm {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.Obrigado_secondaryButton__3hgWm:hover, .Obrigado_secondaryButton__3hgWm:active, .Obrigado_secondaryButton__3hgWm.Obrigado_active__1Z0B9 {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Obrigado_primaryLink__2SFXK {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid transparent !important;
  background-color: transparent !important; 
  color: #c7ef00 !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.Obrigado_primaryLink__2SFXK:hover, .Obrigado_primaryLink__2SFXK:active, .Obrigado_primaryLink__2SFXK.Obrigado_active__1Z0B9 {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Obrigado_bgNone__2a-pM {
  background-color: transparent !important;
  border: 0 !important;
}

.Obrigado_brightness05__3SjvW {
  -webkit-filter: brightness(0.45);
          filter: brightness(0.45);
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
  border-radius: 25px !important;
}

.Obrigado_dFlex__3Q4tQ {
  display: flex !important;
}

.Obrigado_toRight__1Dzya {
  text-align: right;
}

.Obrigado_toBottomCenter__18mxb {
  display: inherit !important;
  max-width: 101px;
  bottom: 0;
  /* display: flex !important; */
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: center;
  /* max-width: 110px; */
}

.Obrigado_maxminHeight215__2JryS {
  border-radius: 25px !important;
  min-height: 215px;
  max-height: 215px;
}

::selection { 
  background: #c7ef00; 
  color: #292F36;
  opacity: 1;
}

.Obrigado_progressImage__17hdG {
  display: flex;
  margin: 60px auto;
  text-align: center;
}

.Obrigado_containerRegister__3-XkX {
  text-align: center;
}

.Obrigado_formField__rVv0b {
  text-align: left;
}

.Obrigado_formInput__1S4Th {
  background-color: transparent !important;
  color: white !important;
  border: 1px solid rgba(255, 255, 255, 0.4) !important;
}
.Obrigado_formInput__1S4Th:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem #c7ef0025 !important;
  border: 1px solid #c7ef00cc !important;
}

.Obrigado_card__37ta3 {
  padding: 3rem 2rem;
  margin-bottom: 30px;
  background-color: #292F36 !important;
  color: white !important;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  border-radius: 25px;
}

.Obrigado_activeButton__2y97c, .Obrigado_activeButton__2y97c:focus {
  padding: 10px !important;
  margin: 5px;
  min-width: 100%;
  border: 1px solid #c7ef00 !important;
  box-shadow: 0 0 5px 0px #c7ef0080 !important;
  background-color: rgba(255, 255, 255, 0.2) !important;
  color: white !important;
  text-decoration: none !important;
}

.Obrigado_notActiveButton__10tbL {
  color: white !important;
  margin: 5px;
  padding: 10px !important;
  min-width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.Obrigado_notActiveButton__10tbL:focus {
  box-shadow: 0 0 5px 0px #c7ef0020 !important; 
}

.Obrigado_notActiveButton__10tbL:hover {
  text-decoration: none !important;
  background-color: rgba(255, 255, 255, 0.2);
}

.Obrigado_maxWidth400__25HWG {
  max-width: 400px;
}

.Obrigado_imgLogo__3_c0J {
  display: flex;
  margin: auto;
  width: 250px;
  padding-bottom: 50px;
}
.Welcome_homeContainer__11WsZ {
  margin-top: 56px;
  background-color: #292F36;
  color: #fff;
}

.Welcome_UserArea__3sd6K {
  position: fixed !important;
  z-index: 999 !important;
}

.Welcome_primaryButton__39F-S {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef00 !important;
  background-color: #c7ef00 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.Welcome_primaryButton__39F-S:hover, .Welcome_primaryButton__39F-S:active, .Welcome_primaryButton__39F-S.Welcome_active__kGOT- {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Welcome_secondaryButton__2FbeE {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.Welcome_secondaryButton__2FbeE:hover, .Welcome_secondaryButton__2FbeE:active, .Welcome_secondaryButton__2FbeE.Welcome_active__kGOT- {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Welcome_primaryLink__zl7DO {
  padding: 0 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  -webkit-text-decoration-color: #b2d600 !important;
          text-decoration-color: #b2d600 !important;
}

.Welcome_primaryLink__zl7DO:hover {
  color: #b2d600 !important;
}

.Welcome_primaryWhiteLink__24aTY {
  padding: 0 !important;
  color: white !important;
  text-decoration: underline !important;
  -webkit-text-decoration-color: #b2d600 !important;
          text-decoration-color: #b2d600 !important;
  transition: all 500ms;
}

.Welcome_primaryWhiteLink__24aTY:hover {
  color: #b2d600 !important;
  text-decoration: underline !important;
  -webkit-text-decoration-color: #b2d600 !important;
          text-decoration-color: #b2d600 !important;
}

.Welcome_bgNone__1lNCt {
  background-color: transparent !important;
  border: 0 !important;
}

.Welcome_brightness05__6BNAQ {
  -webkit-filter: brightness(0.45);
          filter: brightness(0.45);
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
  border-radius: 25px !important;
}

@media (min-width: 768px) {
  .Welcome_spaceUp__3ycsY {
    padding-top: 200px;
  }

  .Welcome_spaceUpTwo__1cFM8 {
    padding-top: 30px;
  }

  .Welcome_spaceThree__2P_Ym {
    padding: 100px 0;
  }

  .Welcome_pb50__VscFw {
    padding-bottom: 50px;
  }
}

@media (max-width: 768px) {
  .Welcome_spaceUp__3ycsY {
    padding-top: 50px;
    margin: 0 25px;
  }

  .Welcome_spaceUpTwo__1cFM8 {
    padding-top: 0;
  }
}

/**
 * Special
 */

::selection { 
  background: #c7ef00; 
  color: #292F36;
  opacity: 1;
}

.Welcome_examsCard__14xJG {
  transition: all 300ms ease-in-out !important;
}

.Welcome_examsCard__14xJG:hover {
  box-shadow: 0 0 10px black;
  border-radius: 1.5em;
  -webkit-transform: scale(1.05) !important;
          transform: scale(1.05) !important;
}

.Welcome_examsCard__14xJG .Welcome_buttonIcon__1PI52 {
  transition: all 200ms ease-in-out;
}

.Welcome_examsCard__14xJG:hover .Welcome_buttonIcon__1PI52 {
  color: #c7ef00;
}

.Welcome_missionCard__Rqs_K {
  padding: 3em;
  border-radius: 50px !important;
  color: #292F36;
  line-height: 2em;
}

.Welcome_missionTextTitle__3i8lV {
  font-size: 30px !important;
  font-weight: bolder !important;
  color: #292F36;
}

.Welcome_strong__1-1CW {
  background-color: #c7ef00;
  text-decoration: underline;
  -webkit-text-decoration-color: #292F36;
          text-decoration-color: #292F36;
}

.Welcome_mediaTitle__3zY2e {
  font-weight: bold !important;
  color: #c7ef00 !important;
}
.UserArea_closeButton__22r0K {
  z-index: 999;
  position: absolute;
  right: 1em;
  top: 1em;
  background: none;
  border: none;
  border-radius:50%;
}

@-webkit-keyframes UserArea_example__22EO8 {
  0%   {right:-999px;}
  100% {right: 10px;}
}

@keyframes UserArea_example__22EO8 {
  0%   {right:-999px;}
  100% {right: 10px;}
}

.UserArea_login__ms0F9 {
  z-index: 999;
  right: 0;
  -webkit-animation-name: UserArea_example__22EO8;
          animation-name: UserArea_example__22EO8;
  -webkit-animation-duration: 500ms;
          animation-duration: 500ms;
  text-align: center;
  position: fixed !important;
  min-height: 100vh;
  top: 0;
  border-radius: 0; /* 25px; */
  padding: 2em;
  box-shadow: 0 0 10px 8px rgba(0, 0, 0, 0.05);
  background-color: white;
  color: #2c3e50;
  max-width: 450px !important;
}

@media (min-width: 320px) and (max-width: 480px) {
  .UserArea_login__ms0F9 {
    max-width: calc(100vw - 20px) !important;
    padding: 15px;
  }
}

.UserArea_form__39Jdw {
  padding: 25px;
  text-align: left;
}

.UserArea_loginIcon__VrRkY {
  height: 100px;
  width: 100px;
  background-color: #f8f8f8;
  font-size: 50px;
  border-radius: 50%;
  vertical-align: text-bottom;
  margin: 25px auto;
  line-height: 100px;
}

.UserArea_secButton__5aFz_ {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.UserArea_secButton__5aFz_:hover, .UserArea_secButton__5aFz_:active, .UserArea_secButton__5aFz_.UserArea_active__2_YJI {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.UserArea_dangerLink__2tt3H {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid transparent !important;
  background-color: transparent !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.UserArea_dangerLink__2tt3H:hover, .UserArea_dangerLink__2tt3H:active, .UserArea_dangerLink__2tt3H.UserArea_active__2_YJI {
  background-color: rgb(255,72,35,0.5) !important;
  border: 1px solid rgb(255,72,35,0.5) !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.UserArea_formError__l81HO {
  padding: 25px;
  color: #ef2800;
  font-size: large;
}

.UserArea_containerLoading__Vbt1l {
  padding: 0 15px;
}

.UserArea_centered__T1fjV {
  margin: auto;
  display: flex !important;
}

.UserArea_primaryButton__TPtdX {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef00 !important;
  background-color: #c7ef00 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.UserArea_primaryButton__TPtdX:hover, .UserArea_primaryButton__TPtdX:active, .UserArea_primaryButton__TPtdX.UserArea_active__2_YJI {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.UserArea_primaryLink__1mqRY {
  color: #292F36 !important;
  text-decoration: underline !important;
  -webkit-text-decoration-color: #c7ef00 !important;
          text-decoration-color: #c7ef00 !important;
}

.UserArea_secondaryLink__2T1KM {
  padding-bottom: 10px !important;
  font-weight: bold !important;
  color: #292F36 !important;
  text-decoration: underline !important;
}
.PublicNavBar_imgLogo__y69go {
  max-height: 56px;
}

.PublicNavBar_navbar__9Midj {
  display: flex;
  z-index: 9;
  height: 56px;
  background-color: #292F36;
  color: #292f36;
  padding: .5rem 1rem;
  top: 80px;
}

.PublicNavBar_secondaryButton__1cgaL {
  transition: 0.2s;
  cursor: pointer;
  border-radius: 0 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: none !important;
  padding: 10px 15px !important;
  border-bottom: 3px solid #ffffff3f !important;
}

.PublicNavBar_secondaryButton__1cgaL:hover, .PublicNavBar_secondaryButton__1cgaL:active, .PublicNavBar_secondaryButton__1cgaL.PublicNavBar_active__22opV, .PublicNavBar_primaryButton__Hui3R:hover, .PublicNavBar_primaryButton__Hui3R:active, .PublicNavBar_primaryButton__Hui3R.PublicNavBar_active__22opV {
  text-decoration: underline;
  color: #c7ef00 !important;
  padding: 10px 15px !important;
  border-bottom: 3px solid #c7ef00 !important;

}

.PublicNavBar_primaryButton__Hui3R {
  transition: 0.2s;
  cursor: pointer;
  border-radius: 0px !important;
  border: 1px solid #292F36 !important;
  color: white !important;
  text-decoration: none !important;
  padding: 10px 15px !important;
  border-bottom: 3px solid #c7ef00 !important;
}

.Mission_homeContainer__HNH1C {
  margin-top: 56px;
  background-color: #292F36;
  color: #fff;
}

.Mission_UserArea__3wTqu {
  position: fixed !important;
  z-index: 999 !important;
}

.Mission_primaryButton__2fAQw {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef00 !important;
  background-color: #c7ef00 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.Mission_primaryButton__2fAQw:hover, .Mission_primaryButton__2fAQw:active, .Mission_primaryButton__2fAQw.Mission_active__2Rlq0 {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Mission_secondaryButton__JsVju {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.Mission_secondaryButton__JsVju:hover, .Mission_secondaryButton__JsVju:active, .Mission_secondaryButton__JsVju.Mission_active__2Rlq0 {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Mission_bgNone__28V3J {
  background-color: transparent !important;
  border: 0 !important;
}

.Mission_brightness05__479wO {
  -webkit-filter: brightness(0.45);
          filter: brightness(0.45);
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
  border-radius: 25px !important;
}

@media (min-width: 768px) {
  .Mission_spaceUp__3q8MD {
    padding-top: 200px;
  }

  .Mission_spaceUpTwo__3qKYD {
    padding-top: 30px;
  }

  .Mission_spaceThree__2Q39B {
    padding: 100px 0;
  }

  .Mission_pb50__RGQsK {
    padding-bottom: 50px;
  }
}

@media (max-width: 768px) {
  .Mission_spaceUp__3q8MD {
    padding-top: 50px;
    margin: 0 25px;
  }

  .Mission_spaceUpTwo__3qKYD {
    padding-top: 0;
  }
}

/**
 * Special
 */

::selection { 
  background: #c7ef00; 
  color: #292F36;
  opacity: 1;
}

.Mission_examsCard__2HM4E {
  transition: all 300ms ease-in-out !important;
}

.Mission_examsCard__2HM4E:hover {
  box-shadow: 0 0 10px black;
  border-radius: 1.5em;
  -webkit-transform: scale(1.05) !important;
          transform: scale(1.05) !important;
}

.Mission_examsCard__2HM4E .Mission_buttonIcon__3mroW {
  transition: all 200ms ease-in-out;
}

.Mission_examsCard__2HM4E:hover .Mission_buttonIcon__3mroW {
  color: #c7ef00;
}

@media (min-width: 768px) {
  .Mission_missionCard__3YRHI {
    padding: 8em;
  }
}

@media (max-width: 768px) {
  .Mission_missionCard__3YRHI {
    padding: 4em;
  }
}

.Mission_missionCard__3YRHI {
  border-radius: 50px !important;
  color: #292F36;
  line-height: 2em;
}

.Mission_missionTextTitle__10yia {
  font-size: 30px !important;
  font-weight: bolder !important;
  color: #292F36;
}

.Mission_strong__1-yyr {
  background-color: #c7ef00;
  text-decoration: underline;
  -webkit-text-decoration-color: #292F36;
          text-decoration-color: #292F36;
}

.Mission_top56__2btIa {
  top: 56px !important;
}
.Method_homeContainer__TE8xg {
  margin-top: 56px;
  background-color: #292F36;
  color: #fff;
}

.Method_UserArea__o0ZoJ {
  position: fixed !important;
  z-index: 999 !important;
}

.Method_primaryButton__3pQKW {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef00 !important;
  background-color: #c7ef00 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.Method_primaryButton__3pQKW:hover, .Method_primaryButton__3pQKW:active, .Method_primaryButton__3pQKW.Method_active__2S-R- {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Method_secondaryButton__38WCl {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.Method_secondaryButton__38WCl:hover, .Method_secondaryButton__38WCl:active, .Method_secondaryButton__38WCl.Method_active__2S-R- {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Method_bgNone__38CEC {
  background-color: transparent !important;
  border: 0 !important;
}

.Method_brightness05__1X-zU {
  -webkit-filter: brightness(0.45);
          filter: brightness(0.45);
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
  border-radius: 25px !important;
}

@media (min-width: 768px) {
  .Method_spaceUp__1vR6w {
    padding-top: 200px;
  }

  .Method_spaceUpTwo__3aqdr {
    padding-top: 30px;
  }

  .Method_spaceThree__3jyRr {
    padding: 100px 0;
  }

  .Method_pb50__1fsoq {
    padding-bottom: 50px;
  }
}

@media (max-width: 768px) {
  .Method_spaceUp__1vR6w {
    padding-top: 50px;
    margin: 0 25px;
  }

  .Method_spaceUpTwo__3aqdr {
    padding-top: 0;
  }
}

/**
 * Special
 */

::selection { 
  background: #c7ef00; 
  color: #292F36;
  opacity: 1;
}

.Method_examsCard__31alP {
  transition: all 300ms ease-in-out !important;
}

.Method_examsCard__31alP:hover {
  box-shadow: 0 0 10px black;
  border-radius: 1.5em;
  -webkit-transform: scale(1.05) !important;
          transform: scale(1.05) !important;
}

.Method_examsCard__31alP .Method_buttonIcon__3opo4 {
  transition: all 200ms ease-in-out;
}

.Method_examsCard__31alP:hover .Method_buttonIcon__3opo4 {
  color: #c7ef00;
}

@media (min-width: 768px) {
  .Method_missionCard__17Yqf {
    padding: 8em;
  }
}

@media (max-width: 768px) {
  .Method_missionCard__17Yqf {
    padding: 4em;
  }
}

.Method_missionCard__17Yqf {
  border-radius: 50px !important;
  color: #292F36;
  line-height: 2em;
}

.Method_missionTextTitle__3JSfZ {
  font-size: 30px !important;
  font-weight: bolder !important;
  color: #292F36;
}

.Method_strong__1yl6i {
  background-color: #c7ef00;
  text-decoration: underline;
  -webkit-text-decoration-color: #292F36;
          text-decoration-color: #292F36;
}

.Method_top56__101vv {
  top: 56px !important;
}

.Method_h2TextTitle__1lZ8l {
  margin-top: 20px;
  font-weight: bolder !important;
}
.AboutUs_homeContainer__2K6Ah {
  margin-top: 56px;
  background-color: #292F36;
  color: #fff;
}

.AboutUs_UserArea__1_NfI {
  position: fixed !important;
  z-index: 999 !important;
}

.AboutUs_primaryButton__3g2F0 {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef00 !important;
  background-color: #c7ef00 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.AboutUs_primaryButton__3g2F0:hover, .AboutUs_primaryButton__3g2F0:active, .AboutUs_primaryButton__3g2F0.AboutUs_active__B6oYt {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.AboutUs_secondaryButton__2i_DG {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.AboutUs_secondaryButton__2i_DG:hover, .AboutUs_secondaryButton__2i_DG:active, .AboutUs_secondaryButton__2i_DG.AboutUs_active__B6oYt {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.AboutUs_primaryLink__3B_8R {
  padding: 0 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  -webkit-text-decoration-color: #b2d600 !important;
          text-decoration-color: #b2d600 !important;
}

.AboutUs_primaryLink__3B_8R:hover {
  color: #b2d600 !important;
}

.AboutUs_bgNone__c2ZD8 {
  background-color: transparent !important;
  border: 0 !important;
}

.AboutUs_brightness05__EuSiF {
  -webkit-filter: brightness(0.45);
          filter: brightness(0.45);
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
  border-radius: 25px !important;
}

@media (min-width: 768px) {
  .AboutUs_spaceUp__1GnCn {
    padding-top: 200px;
  }

  .AboutUs_spaceUpTwo___fJD4 {
    padding-top: 30px;
  }

  .AboutUs_spaceThree__1XH3F {
    padding: 100px 0;
  }

  .AboutUs_pb50__17jhI {
    padding-bottom: 50px;
  }
}

@media (max-width: 768px) {
  .AboutUs_spaceUp__1GnCn {
    padding-top: 50px;
    margin: 0 25px;
  }

  .AboutUs_spaceUpTwo___fJD4 {
    padding-top: 0;
  }
}

/**
 * Special
 */

::selection { 
  background: #c7ef00; 
  color: #292F36;
  opacity: 1;
}

.AboutUs_examsCard__2SGH8 {
  transition: all 300ms ease-in-out !important;
}

.AboutUs_examsCard__2SGH8:hover {
  box-shadow: 0 0 10px black;
  border-radius: 1.5em;
  -webkit-transform: scale(1.05) !important;
          transform: scale(1.05) !important;
}

.AboutUs_examsCard__2SGH8 .AboutUs_buttonIcon__3Oii4 {
  transition: all 200ms ease-in-out;
}

.AboutUs_examsCard__2SGH8:hover .AboutUs_buttonIcon__3Oii4 {
  color: #c7ef00;
}

@media (min-width: 768px) {
  .AboutUs_missionCard__2RGVM {
    padding: 8em;
  }
}

@media (max-width: 768px) {
  .AboutUs_missionCard__2RGVM {
    padding: 4em;
  }
}

.AboutUs_missionCard__2RGVM {
  border-radius: 50px !important;
  color: #292F36;
  line-height: 2em;
}

.AboutUs_missionTextTitle__2JPBw {
  font-size: 30px !important;
  font-weight: bolder !important;
  color: #292F36;
}

.AboutUs_strong__1Nv5N {
  background-color: #c7ef00;
  text-decoration: underline;
  -webkit-text-decoration-color: #292F36;
          text-decoration-color: #292F36;
}

.AboutUs_top56__dNnpU {
  top: 56px !important;
}
.Jobs_homeContainer__3teEV {
  margin-top: 56px;
  background-color: #292F36;
  color: #fff;
}

.Jobs_UserArea__1-1LS {
  position: fixed !important;
  z-index: 999 !important;
}

.Jobs_primaryButton__3obxe {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef00 !important;
  background-color: #c7ef00 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.Jobs_primaryButton__3obxe:hover, .Jobs_primaryButton__3obxe:active, .Jobs_primaryButton__3obxe.Jobs_active__1leaD {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Jobs_secondaryButton__1vnv1 {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.Jobs_secondaryButton__1vnv1:hover, .Jobs_secondaryButton__1vnv1:active, .Jobs_secondaryButton__1vnv1.Jobs_active__1leaD {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Jobs_primaryLink__25Q5W {
  padding: 0 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  -webkit-text-decoration-color: #b2d600 !important;
          text-decoration-color: #b2d600 !important;
}

.Jobs_primaryLink__25Q5W:hover {
  color: #b2d600 !important;
}

.Jobs_primaryWhiteLink__1HePD {
  padding: 0 !important;
  color: white !important;
  text-decoration: underline !important;
  -webkit-text-decoration-color: #b2d600 !important;
          text-decoration-color: #b2d600 !important;
  transition: all 500ms;
}

.Jobs_primaryWhiteLink__1HePD:hover {
  color: #b2d600 !important;
  text-decoration: underline !important;
  -webkit-text-decoration-color: #b2d600 !important;
          text-decoration-color: #b2d600 !important;
}

.Jobs_bgNone__3lQwf {
  background-color: transparent !important;
  border: 0 !important;
}

.Jobs_brightness05__10o8U {
  -webkit-filter: brightness(0.45);
          filter: brightness(0.45);
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
  border-radius: 25px !important;
}

@media (min-width: 768px) {
  .Jobs_spaceUp__iwrRv {
    padding-top: 200px;
  }

  .Jobs_spaceUpTwo__1ZjkV {
    padding-top: 30px;
  }

  .Jobs_spaceThree__3-Y_s {
    padding: 100px 0;
  }

  .Jobs_pb50__2gcUC {
    padding-bottom: 50px;
  }
}

@media (max-width: 768px) {
  .Jobs_spaceUp__iwrRv {
    padding-top: 50px;
    margin: 0 25px;
  }

  .Jobs_spaceUpTwo__1ZjkV {
    padding-top: 0;
  }
}

/**
 * Special
 */

::selection { 
  background: #c7ef00; 
  color: #292F36;
  opacity: 1;
}

.Jobs_examsCard__12QaK {
  transition: all 300ms ease-in-out !important;
}

.Jobs_examsCard__12QaK:hover {
  box-shadow: 0 0 10px black;
  border-radius: 1.5em;
  -webkit-transform: scale(1.05) !important;
          transform: scale(1.05) !important;
}

.Jobs_examsCard__12QaK .Jobs_buttonIcon__36zgW {
  transition: all 200ms ease-in-out;
}

.Jobs_examsCard__12QaK:hover .Jobs_buttonIcon__36zgW {
  color: #c7ef00;
}

.Jobs_missionCard__1dj4i {
  padding: 4em;
  border-radius: 50px !important;
  color: #292F36;
  line-height: 2em;
}

.Jobs_missionTextTitle__13vpi {
  font-size: 30px !important;
  font-weight: bolder !important;
  color: #292F36;
}

.Jobs_strong__1nBSd {
  background-color: #c7ef00;
  text-decoration: underline;
  -webkit-text-decoration-color: #292F36;
          text-decoration-color: #292F36;
}

.Jobs_mediaTitle__34Bcz {
  font-weight: bold !important;
  color: #c7ef00 !important;
}
.Terms_homeContainer__3Ieqe {
  margin-top: 56px;
  background-color: #292F36;
  color: #fff;
}

.Terms_UserArea__1oOCP {
  position: fixed !important;
  z-index: 999 !important;
}

.Terms_primaryButton__2cdkC {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef00 !important;
  background-color: #c7ef00 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.Terms_primaryButton__2cdkC:hover, .Terms_primaryButton__2cdkC:active, .Terms_primaryButton__2cdkC.Terms_active__1lYJK {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Terms_secondaryButton__3zMMN {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.Terms_secondaryButton__3zMMN:hover, .Terms_secondaryButton__3zMMN:active, .Terms_secondaryButton__3zMMN.Terms_active__1lYJK {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.Terms_bgNone__3yTCE {
  background-color: transparent !important;
  border: 0 !important;
}

.Terms_brightness05__1ik0_ {
  -webkit-filter: brightness(0.45);
          filter: brightness(0.45);
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
  border-radius: 25px !important;
}

@media (min-width: 768px) {
  .Terms_spaceUp__3YstH {
    padding-top: 200px;
  }

  .Terms_spaceUpTwo__3SS4P {
    padding-top: 30px;
  }

  .Terms_spaceThree__2-vIp {
    padding: 100px 0;
  }

  .Terms_pb50__1tsZV {
    padding-bottom: 50px;
  }
}

@media (max-width: 768px) {
  .Terms_spaceUp__3YstH {
    padding-top: 50px;
    margin: 0 25px;
  }

  .Terms_spaceUpTwo__3SS4P {
    padding-top: 0;
  }
}

/**
 * Special
 */

::selection { 
  background: #c7ef00; 
  color: #292F36;
  opacity: 1;
}

.Terms_examsCard__1LCsW {
  transition: all 300ms ease-in-out !important;
}

.Terms_examsCard__1LCsW:hover {
  box-shadow: 0 0 10px black;
  border-radius: 1.5em;
  -webkit-transform: scale(1.05) !important;
          transform: scale(1.05) !important;
}

.Terms_examsCard__1LCsW .Terms_buttonIcon__36qx2 {
  transition: all 200ms ease-in-out;
}

.Terms_examsCard__1LCsW:hover .Terms_buttonIcon__36qx2 {
  color: #c7ef00;
}

@media (min-width: 768px) {
  .Terms_missionCard__2mqMO {
    padding: 8em;
  }
}

@media (max-width: 768px) {
  .Terms_missionCard__2mqMO {
    padding: 4em;
  }
}

.Terms_missionCard__2mqMO {
  border-radius: 50px !important;
  color: #292F36;
  line-height: 2em;
}

.Terms_missionTextTitle__x7M4i {
  font-size: 30px !important;
  font-weight: bolder !important;
  color: #292F36;
}

.Terms_strong__2elNl {
  background-color: #c7ef00;
  text-decoration: underline;
  -webkit-text-decoration-color: #292F36;
          text-decoration-color: #292F36;
}

.Terms_top56__2Ka7t {
  top: 56px !important;
}
@media screen and (max-width: 600px) {
  .SearchBar_searchForm__1eFh3 {
    padding: 0 15px;
    position: absolute;
    top: 56px;
    width: 100%;
  }
  .SearchBar_searchWrapper__1X-HK {
    max-width: 95vw;
  }
}

.SearchBar_searchForm__1eFh3 {
  display: inline-flex;
  text-align: right;
  right: 0;
  background: #292f36;
  padding: 5px;
}

.SearchBar_searchWrapper__1X-HK {
  max-width: 700px;
  display: inline-flex;
  margin-bottom: 0 !important;
}

.SearchBar_searchButton__3aVfb {
  opacity: 0.9;
  color: white !important;
  margin-left: auto;
  transition: all 500ms;
}

.SearchBar_searchButton__3aVfb:hover {
  opacity: 1;
}

@media screen and (max-width: 600px) {
  .SearchBar_searchButtonBigScreen__Ky-1_ {
    display: none !important;
  }
  .SearchBar_searchInput__3SGm1 {
    width: calc(100vw - 114px) !important;
  }
  .SearchBar_searchWrapper__1X-HK {
    margin: 0 auto;
  }
}

@media screen and (min-width: 600px) {
  .SearchBar_searchButtonMobile__2R7fw {
    display: none !important;
  }
  .SearchBar_searchInput__3SGm1 {
    width: 400px !important;
  }
}

.SearchBar_searchInput__3SGm1 {
  color: white !important;
  background-color: #4b525a !important;
  border: 1px solid #4b525a !important;
}

.SearchBar_searchInput__3SGm1::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #c4c4c4 !important;
  opacity: 1; /* Firefox */
}

.SearchBar_searchInput__3SGm1:-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #c4c4c4 !important;
  opacity: 1; /* Firefox */
}

.SearchBar_searchInput__3SGm1::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #c4c4c4 !important;
  opacity: 1; /* Firefox */
}

.SearchBar_searchInput__3SGm1:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #c4c4c4 !important;
}

.SearchBar_searchInput__3SGm1::-ms-input-placeholder { /* Microsoft Edge */
  color: #c4c4c4 !important;
}
@media only screen and (max-width: 768px) {
  .NavigationBar_imgLogo__30aBI {
    max-height: 40px;
  }
}

@media only screen and (min-width: 768px) {
  .NavigationBar_imgLogo__30aBI {
    max-height: 56px;
  }
}

.NavigationBar_navbar__3ZlbT {
  display: flex;
  z-index: 10;
  height: 56px;
  background-color: #292F36;
  color: #292f36;
  padding: .5rem 1rem;
}

.NavigationBar_userCircle__3TPUO {
  cursor: pointer;
  display:inline-block;
  width: 2em;
  height: 2em;
  font-size: 20px;
  line-height: 32px;
  text-align:center;
  border-radius:50%;
  background: white;
  vertical-align:middle;
  color:#292F36;
}
.NavigationBar_userCircle__3TPUO:hover {
  transition: 0.2s;
  background-color: #F6F6F6;
}

.NavigationBar_userLettersName__22QVY {
  color:#292F36;
  font-size: 20px;
  line-height: 48px;
  font-weight: bold;
  position: relative;
  padding-left: 2px;
  top: -3px;
}

.NavigationBar_rightComponent__2obwl {
  text-align: right;
  flex: 1 1;
}

.NavigationBar_btnNoBackground__34S5W, .NavigationBar_btnNoBackground__34S5W:hover, .NavigationBar_btnNoBackground__34S5W:active, .NavigationBar_btnNoBackground__34S5W.NavigationBar_active__1b-oA {
  background:none;
  right: 0;
  padding: 0 !important;
  margin-right: 0;
  border: 1px solid transparent;
}

.NavigationBar_secondaryButton__1G0GQ {
  transition: 0.2s;
  cursor: pointer;
  /* font-weight: bold !important; */
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.NavigationBar_secondaryButton__1G0GQ:hover, .NavigationBar_secondaryButton__1G0GQ:active, .NavigationBar_secondaryButton__1G0GQ.NavigationBar_active__1b-oA {
  text-decoration: underline;
  color: #c7ef00 !important;
  padding: 10px 15px !important;
}


.NavigationBar_roadButton__3UqIU, .NavigationBar_scheduleButton__1OUrU, .NavigationBar_calendarButton__3h55v {
  transition: 0.2s;
  cursor: pointer;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  opacity: 0.7;
  color: white !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.NavigationBar_roadButton__3UqIU:hover, .NavigationBar_roadButton__3UqIU:active, .NavigationBar_roadButton__3UqIU.NavigationBar_active__1b-oA, .NavigationBar_scheduleButton__1OUrU:hover, .NavigationBar_scheduleButton__1OUrU:active, .NavigationBar_scheduleButton__1OUrU.NavigationBar_active__1b-oA, .NavigationBar_calendarButton__3h55v:hover, .NavigationBar_calendarButton__3h55v:active, .NavigationBar_calendarButton__3h55v:active {
  opacity: 1 !important;
  padding: 10px 15px !important;
}

.NavigationBar_navbarBottom__1E1IO {
  border-top: solid 1px #ffffff30;
}

.NavigationBar_bottomComponent__1l6ly {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.NavigationBar_inactiveActualStreakVal__3p-j3 {
  color: #666 !important;
  font-weight: bolder;
}

span.NavigationBar_inactiveActualStreakVal__3p-j3 {
  text-decoration: none !important;
}

span.NavigationBar_activeActualStreakVal__1LufK {
  text-decoration: none !important;
}

.NavigationBar_activeActualStreakVal__1LufK {
  color: #c7ef00 !important;
  font-weight: bolder;
}

.NavigationBar_btn-link__3dJuQ:hover {
  color: none !important;
  text-decoration: none !important;
}
.Footer_imgLogo__1n5OQ {
  max-height: 56px;
}

.Footer_footer__381x8 {
  margin-top: 50px;
  text-align: left;
}

.Footer_footerLink__3s1dc {
  display: inline-block;
  padding: 1em 0;
  color: #ffffff80 !important;
  text-decoration: underline !important;
  -webkit-text-decoration-color: #c7ef0080 !important;
          text-decoration-color: #c7ef0080 !important;
}

.Footer_footerLink__3s1dc:active, .Footer_footerLink__3s1dc:hover, .Footer_footerLink__3s1dc.Footer_active__3LV2P {
  color: #c7ef00 !important;
  -webkit-text-decoration-color: #c7ef00 !important;
          text-decoration-color: #c7ef00 !important;
}

@media (max-width: 768px) {
  .Footer_topSpace__36FC7 {
    margin-top: 50px;
  }
}
h1, h2 {
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
}

body {
  background-color: #292F36;
  font-family: 'Nunito', Helvetica, Arial, sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

.App_primaryButton__6bNYV {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef00 !important;
  background-color: #c7ef00 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.App_primaryButton__6bNYV:hover, .App_primaryButton__6bNYV:active, .App_primaryButton__6bNYV.App_active__eYtTS {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}


.PasswordRecover_container__2kiJB {
  margin-top: 56px;
  padding-bottom: 100px;
  background-color: #292F36;
  color: #fff;
}

.PasswordRecover_UserArea__3EUJH {
  z-index: 999 !important;
}

.PasswordRecover_primaryButton__3pSfG {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef00 !important;
  background-color: #c7ef00 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.PasswordRecover_primaryButton__3pSfG:hover, .PasswordRecover_primaryButton__3pSfG:active, .PasswordRecover_primaryButton__3pSfG.PasswordRecover_active__IQA18 {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.PasswordRecover_secondaryButton__39zQ2 {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #292F36 !important;
  background-color: #292F36 !important;
  color: white !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.PasswordRecover_secondaryButton__39zQ2:hover, .PasswordRecover_secondaryButton__39zQ2:active, .PasswordRecover_secondaryButton__39zQ2.PasswordRecover_active__IQA18 {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.PasswordRecover_primaryLink__16yiV {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid transparent !important;
  background-color: transparent !important; 
  color: #c7ef00 !important;
  text-decoration: underline !important;
  padding: 10px 15px !important;
}

.PasswordRecover_primaryLink__16yiV:hover, .PasswordRecover_primaryLink__16yiV:active, .PasswordRecover_primaryLink__16yiV.PasswordRecover_active__IQA18 {
  background-color: #c7ef00 !important;
  border: 1px solid #c7ef00 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

.PasswordRecover_bgNone__d7qs- {
  background-color: transparent !important;
  border: 0 !important;
}

.PasswordRecover_brightness05__3YBYA {
  -webkit-filter: brightness(0.45);
          filter: brightness(0.45);
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
  border-radius: 25px !important;
}

.PasswordRecover_dFlex__3dMex {
  display: flex !important;
}

.PasswordRecover_toRight__ozPxb {
  text-align: right;
}

.PasswordRecover_toBottomCenter__3OhEj {
  display: inherit !important;
  max-width: 101px;
  bottom: 0;
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: center;
}

.PasswordRecover_maxminHeight215__3u1-e {
  border-radius: 25px !important;
  min-height: 215px;
  max-height: 215px;
}

::selection { 
  background: #c7ef00; 
  color: #292F36;
  opacity: 1;
}

.PasswordRecover_progressImage__1-XFy {
  display: flex;
  margin: 60px auto;
  text-align: center;
}

.PasswordRecover_containerRegister__FyGlu {
  text-align: center;
}

.PasswordRecover_formField__26iVk {
  text-align: left;
}

.PasswordRecover_formInput__1NQXP {
  background-color: transparent !important;
  color: white !important;
  border: 1px solid rgba(255, 255, 255, 0.4) !important;
  transition: 500ms all;
}

.PasswordRecover_formInput__1NQXP:focus {
  box-shadow: 0 0 1px 2px #c7ef0080 !important;
}

.PasswordRecover_card__1f89W {
  padding: 3rem 2rem;
  margin-bottom: 30px;
  background-color: transparent !important;
  color: white !important;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  border-radius: 25px;
}

.PasswordRecover_activeButton__2vYl5, .PasswordRecover_activeButton__2vYl5:focus {
  padding: 10px !important;
  margin: 5px;
  min-width: 100%;
  border: 1px solid #c7ef00 !important;
  box-shadow: 0 0 5px 0px #c7ef0080 !important;
  background-color: rgba(255, 255, 255, 0.2) !important;
  color: white !important;
  text-decoration: none !important;
}

.PasswordRecover_notActiveButton__3_LFf {
  color: white !important;
  margin: 5px;
  padding: 10px !important;
  min-width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.PasswordRecover_notActiveButton__3_LFf:focus {
  box-shadow: 0 0 5px 0px #c7ef0020 !important; 
}

.PasswordRecover_notActiveButton__3_LFf:hover {
  text-decoration: none !important;
  background-color: rgba(255, 255, 255, 0.2);
}

.PasswordRecover_secondaryLink__2aaNv {
  cursor: pointer;
  color: white !important;
  text-decoration: underline !important;
  -webkit-text-decoration-color: #c7ef00 !important;
          text-decoration-color: #c7ef00 !important;
  font-weight: bold !important;
}

.PasswordRecover_secondaryLink__2aaNv:hover, .PasswordRecover_secondaryLink__2aaNv:active, .PasswordRecover_secondaryLink__2aaNv.PasswordRecover_active__IQA18 {
  color: #c7ef00 !important;
  text-decoration: underline;
  -webkit-text-decoration-color: #c7ef00 !important;
          text-decoration-color: #c7ef00 !important;
}
/* @import url('https://fonts.googleapis.com/css?family=Nunito&display=swap') */

/* html, body, #root {
  display: block;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background: #000;
  color: #ffffff;
  margin: 0;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
}

div, h1, h2, h3, h4, h5, h6, p, span, input, button, li {
  font-family: 'Titillium Web', sans-serif;
  font-size: 24px;
  color: #ffffff;
}

a, .link {
  font-family: 'Titillium Web', sans-serif;
  font-size: 24px;
  font-weight: 400;
  color: rgba(255,255,255,0.6);
  text-decoration: none;
  transition: all 0.14s ease;
}

a:hover, .link:hover {
  cursor: pointer;
  color: rgba(255,255,255,1);
}

a:active, .link:active {
  color: rgba(255,255,255,0.6);
}

.buttonPrimaryLarge {
  text-align: center;
  font-size: 18px;
  width: 100%;
  max-width: 360px;
  height: auto;
  margin: 20px 0 0 0;
  padding: 24px 24px 28px 24px;
  background: #fd5750;
  color: #fff;
  font-family: 'Titillium Web', sans-serif;
  font-size: 24px;
  font-weight: 600;
  border-radius: 4px;
  border: 0px;
  transition: all 0.14s ease;
  user-select: none;
  outline: none;
  text-transform: lowercase;
}

.buttonPrimaryLarge:hover {
  cursor: pointer;
  transform: scale(1.03);
}

.buttonPrimaryLarge:active {
  cursor: pointer;
  transform: scale(1);
  outline: none;
}


.animateFadeIn {
  animation: fadeIn 0.45s;
}

.animateScaleIn {
  animation: scaleIn 0.35s;
}

.animateFlicker {
  animation: flicker 2.5s infinite;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0.8); 
  }
  to   { 
    opacity: 1;
    transform: scale(1); 
  }
}

@keyframes flicker {
	0% {
		opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
	100% {
		opacity: 0.3;
	}
} */
