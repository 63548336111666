@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;700;800;900&display=swap');

h1, h2 {
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
}

body {
  background-color: #292F36;
  font-family: 'Nunito', Helvetica, Arial, sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

.primaryButton {
  transition: 0.2s;
  cursor: pointer;
  font-weight: bold !important;
  border-radius: 50px !important;
  border: 1px solid #c7ef00 !important;
  background-color: #c7ef00 !important;
  color: #292F36 !important;
  text-decoration: underline !important;
  padding: 20px 35px !important;
}

.primaryButton:hover, .primaryButton:active, .primaryButton.active {
  background-color: #b2d600 !important;
  border: 1px solid #b2d600 !important;
  text-decoration: underline;
  color: #292F36 !important;
}

